import { getServerValidation, getToken } from "./Helper";
import { loginUrl, public_url } from "../Utility/Constant";

import { API_URL } from "./Config";
import axios from "axios";
import { te } from "./ReduxToaster";

export const get = (url, isPrivate = true, responseType = null, customUrl = false, headers) => {
  let apiUrl = API_URL + url;
  if (customUrl) {
    apiUrl = url;
  }
  if (isPrivate && getToken()) {
    const isParam = apiUrl.includes("?");
    if (isParam) apiUrl = `${apiUrl}&&api_token=${getToken()}`;
    else apiUrl = `${apiUrl}?api_token=${getToken()}`;
  }
  const axiosObj = {
    method: "get",
    url: apiUrl,
  };
  let leadData = JSON.parse(localStorage.getItem("leadData"));

  headers = {
    Authorization: `Bearer ${leadData && leadData.leadAccessToken}`,
    "Access-Control-Allow-Origin": "*",
  };
  if (headers) {
    axiosObj.headers = headers;
  }
  if (responseType) axiosObj.responseType = responseType;
  return axios(axiosObj)
    .then(response => handleResponse(response))
    .catch(error => handleError(error));
};

export const getFile = (url, link, responseType = null, isPrivate = true, customUrl = false, headers) => {
  let apiUrl = API_URL + url;
  if (customUrl) {
    apiUrl = url;
  }
  if (isPrivate && getToken()) {
    const isParam = apiUrl.includes("?");
    if (isParam) apiUrl = `${apiUrl}&&api_token=${getToken()}`;
    else apiUrl = `${apiUrl}?api_token=${getToken()}`;
  }
  const axiosObj = {
    method: "get",
    url: apiUrl,
  };
  let leadData = localStorage.getItem("leadData") ? JSON.parse(localStorage.getItem("leadData")) : undefined;
  let token = leadData && leadData.leadAccessToken ? leadData.leadAccessToken : "";
  // let userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : undefined;
  // let token = userData && userData.orderAccessToken ? userData && userData.orderAccessToken : "";
  headers = {
    Authorization: `Bearer ${token}`,
    // "Access-Control-Allow-Origin": "*",
    Accept: "application/octet-stream",
  };
  if (headers) {
    axiosObj.headers = headers;
    console.log("h", headers);
  }
  if (responseType) axiosObj.responseType = responseType;
  return axios(axiosObj)
    .then(res => {
      console.log("viewDocument", res);
      const { data, headers } = res;
      const fileName = link;
      const blob = new Blob([data], { type: "application/octet-stream" });
      let dom = document.createElement("a");
      let url = window.URL.createObjectURL(blob);
      dom.href = url;
      dom.download = decodeURI(fileName);
      dom.style.display = "none";
      document.body.appendChild(dom);
      dom.click();
      dom.parentNode.removeChild(dom);
      window.URL.revokeObjectURL(url);
    })

    .catch(error => {
      console.log("return handleError(error)", error);
      return handleError(error);
    });
};

export const post = (url, bodyObj = {}, isPrivate = true, mediaFile = false, uat = false) => {
  const apiUrl = !uat ? API_URL + url : url;

  if (isPrivate && getToken()) {
    if (bodyObj instanceof FormData) bodyObj.append("api_token", getToken());
    else bodyObj.api_token = getToken();
  }
  if (mediaFile == true) {
    let formData = new FormData();
    Object.keys(bodyObj).map(key => {
      formData.append(key, bodyObj[key]);
    });
    bodyObj = formData;
  }
  let header = {};
  let leadData = localStorage.getItem("leadData") ? JSON.parse(localStorage.getItem("leadData")) : undefined;
  let token = leadData && leadData.leadAccessToken ? leadData.leadAccessToken : "";

  console.log("leadData", leadData);
  if (!url.includes("authenticate")) {
    header = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
  }
  return axios
    .post(apiUrl, bodyObj, {
      headers: header,
    })
    .then(response => handleResponse(response))
    .catch(error => {
      console.log("error interceptor", error);
      return handleError(error);
    });
};

export const postM = (url, bodyObj = {}, isPrivate = true, mediaFile = false, uat = false, multiBody = {}) => {
  const apiUrl = !uat ? API_URL + url : url;

  if (isPrivate && getToken()) {
    if (bodyObj instanceof FormData) bodyObj.append("api_token", getToken());
    else bodyObj.api_token = getToken();
  }
  if (mediaFile == true) {
    let formData = new FormData();
    // Object.keys(bodyObj).map(key => {
    //   formData.append(key, bodyObj[key]);
    // });
    console.log(multiBody);
    console.log(multiBody.file);
    formData.append("leadId", bodyObj);
    formData.append("file", multiBody.file);
    bodyObj = formData;
  }
  let header = {};
  let leadData = localStorage.getItem("leadData") ? JSON.parse(localStorage.getItem("leadData")) : undefined;
  let token = leadData && leadData.leadAccessToken ? leadData.leadAccessToken : "";

  console.log("leadData", leadData);
  if (!url.includes("authenticate")) {
    header = {
      Authorization: `Bearer ${token}`,
      // "Access-Control-Allow-Origin": "*",
    };
  }
  return axios
    .post(apiUrl, bodyObj, {
      headers: header,
    })
    .then(response => handleResponse(response))
    .catch(error => {
      console.log("error interceptor", error);
      return handleError(error);
    });
};

const handleResponse = response => {
  return {
    error: false,
    data: response.data,
  };
};
const handleError = error => {
  const { response } = error;
  let errorMsg = "Sorry, something went wrong. Please try again.";
  if(typeof response.data === "string" && response.data.includes("Unauthorized Access")){
    localStorage.clear();
    window.location.href = loginUrl;
  }
  if (response && response.status === 422) {
    // handle server validation
    if (response.data && response.data.errors) errorMsg = getServerValidation(response.data.errors) || errorMsg;
    else if (response.data.message) errorMsg = response.data.message;
  } else if (response && response.status === 401) {
    // Unauthorized
    let element = document.getElementById("unauthorized-box");
    if (element) {
      element.style.display = "block";
      errorMsg = null;
    }
    localStorage.clear();
    window.location.href = `${loginUrl}`;
  }
  console.log("API ERROR ::: ", JSON.stringify(response));
  return {
    error: true,
    message: response ? response.data.message : null,
    status: response ? response.status : null,
  };
};

export const awspost = (url, bodyObj = {}, isPrivate = true, mediaFile = false, uat = false) => {
  const apiUrl = !uat ? API_URL + url : url;

  if (isPrivate && getToken()) {
    if (bodyObj instanceof FormData) bodyObj.append("api_token", getToken());
    else bodyObj.api_token = getToken();
  }
  if (mediaFile == true) {
    let formData = new FormData();
    Object.keys(bodyObj).map(key => {
      formData.append(key, bodyObj[key]);
    });
    bodyObj = formData;
  }
  return axios
    .post(apiUrl, bodyObj, {
      headers: {
        "x-api-key": "lksaflsajf",
      },
    })
    .then(response => handleResponse(response))
    .catch(error => handleError(error));
};