import React, { Component } from "react";
import BreadCrumbs from "../../Component/BreadCrumbs/index";
import { Select, Input, TextArea, File } from "../../Component/Input";
import { cloneDeep } from "lodash";
import moment from "moment";
import { public_url, formatIndianCurrency, replaceComma, checkIfNotDecimal } from "../../Utility/Constant";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import { Loader } from "../../Component/Loader";
import {
  saveOrUpdateLeadDetails,
  saveOrUpdateLeadDocument,
  getFarmServicesDropDownByFranchiseCode,
  findAllProductSelectionDropDown,
  findAllTypeofInteractionDropDown,
  getDataByLeadId,
  getAreaVillagesDropDownByFranchiseCode,
  findAllEducationStatusDropDown,
  findAllRejectionRemarksDropDown,
  getBranchAndStateByEmployeeId,
  rejectLeadByLeadId,
  getAllProductName,
  getBranchAndStateBySpoke,
  findtehsildistrictstate,
  findActiveCampaignMediums,
  findActiveCampaignNames,
} from "../../Utility/Services/Leadmanage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../LMScaselist/style.css";
import ImageUploader from "react-images-upload";
toast.configure();

const productValue = [
  { value: "In this week", label: "In this week" },
  { value: "In this month", label: "In this month" },
  { value: "Next 2-3 months", label: "Next 2-3 months" },
  { value: "Next 4-6 months", label: "Next 4-6 months" },
];

const identityProofDropdown = [
  { value: "Aadhaar card", label: "Aadhaar card" },
  { value: "Voter ID", label: "Voter ID" },
  { value: "PAN Card", label: "PAN Card" },
];

const initForm = {
  area: "",
  inquiryDate: "",
  inquirySource: "",
  customerOccupation: "",
  firstWorkingMember: "",
  secondWorkingmember: "",
  firstMemberOccupation: "",
  secondMemberOccupation: "",
  familyMemberOccuption: "",
  workPlaceVillage: "",
  workPlacePincode: "",
  existingLoan: "",
  firstExistingLoanEmi: "",
  secondExistingLoanEmi: "",
  ccLastCall: "",
  remarks: "",
  branch: "",
  state: "",
  entityType: "Individual",
  existingCustomer: false,
  customerName: "",
  id: "",
  mobileNumber1: "",
  isThisWhatsAppNo1: false,
  pinCode: "",
  productSelection: "",
  farmService: "",
  // proofOfIdentity: "",
  identityProof: "",
  // idNumber: "",
  identityProofId: "",
  identityProofDocument: "",
  imageUpload: false,
  requestedLoanAmount: "",
  productType: "",
  campaignMedium: "NA",
  campaignName: "NA",
  crop: "",
  landArea: "",
  leadFollowUpDate: "",
  gender: "",
  dateOfBirth: "",
  age: "",
  maritalStatus: "",
  educationStatus: "",
  mobileNumber2: "",
  inquiryDate: "",
  isThisWhatsAppNo2: false,
  alternateNo: "",
  addLine1: "",
  addLine2: "",
  cityTownVillage: "",
  pincodeAutofetchState: "",
  district: "",
  endUse: "",
  typeOfInteraction: "",
  label: "",
  productRequiredBy: "",
  errors: {
    mobileNumber1: null,
    pinCode: null,
    loanamt: null,
    identityProofDocument: "",
  },
};

export class Addlead extends Component {
  state = {
    form: cloneDeep(initForm),
    productDropdown: [],
    farmDropdown: [],
    // proofOfIdentityDropdown: [],
    // idNumber: [],
    identityProofId: [],
    identityProofDocument: [],
    campaignNameValues: [],
    campaignMediumValues: [],
    branchstateData: {},
    areaDropdown: [],
    CDProductDropdown: [],
    isThisWhatsAppNo: false,
    selectedSpokeError: null,
    loading: false,
    loadingForCC: false,
    loaderForArea: false,
    show: false,
  };

  componentDidMount() {
    let formData = this.props.match.params;
    if (formData && formData.leadId) {
      this.DataByLeadId();
    } else {
      this.AllProductSelectionDropDown();
      this.FarmServicesDropDownByFranchiseCode();
      this.BranchAndStateByEmployeeId();
      this.AreaVillagesDropDownByFranchiseCode();
      this.allCDProducts();
    }
  }

  AllCampaignMediumDropDown = product => {
    findActiveCampaignMediums(product).then(res => {
      console.log("res", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        console.log("error in fetching the data");
      } else {
        let campaignMediumValues = res.data.data;
        if (!campaignMediumValues) campaignMediumValues = [];
        campaignMediumValues.push({ campaignMediumDescription: "NA", id: 0, campaignMedium: "NA", productType: "All" });
        this.setState({
          campaignMediumValues,
        });
      }
    });
  };

  AllCampaignNamesDropdown = mediumName => {
    findActiveCampaignNames(mediumName).then(res => {
      console.log("res", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        console.log("error in fetching the data");
      } else {
        let campaignNameValues = res.data.data;
        if (!campaignNameValues) campaignNameValues = [];
        //campaignNameValues.push({ campaignNameDescription: 'NA', id: 0, campaignName: 'NA', campaignMedium: 'NA' })
        this.setState({
          campaignNameValues,
        });
      }
    });
  };

  AllProductSelectionDropDown = () => {
    let { form } = this.state;
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let employeeId = leadData && leadData.leadEmployeeId;
    let obj = {
      employeeId: employeeId,
      searchString: form && form.area,
    };

    findAllProductSelectionDropDown(obj).then(res => {
      console.log("res", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          productDropdown: res.data && res.data.data,
        });
      }
    });
  };

  allCDProducts = () => {
    getAllProductName().then(res => {
      console.log("res", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          CDProductDropdown: res.data && res.data.data,
        });
      }
    });
  };

  FarmServicesDropDownByFranchiseCode = () => {
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let obj = {
      franchiseCode: leadData && leadData.leadEmployeeId,
    };

    getFarmServicesDropDownByFranchiseCode(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          farmDropdown: res.data && res.data.data,
        });
      }
    });
  };

  BranchAndStateByEmployeeId = () => {
    let { form } = this.state;
    let { branch, state } = form;
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let obj = {
      employeeId: leadData && leadData.leadEmployeeId,
    };
    getBranchAndStateByEmployeeId(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        form.branch = res.data && res.data.data && res.data.data.branchName;
        form.state = res.data && res.data.data && res.data.data.stateName;
        this.setState({
          branchstateData: res.data && res.data.data,
          form,
        });
      }
    });
  };

  GetStateAndBranchFromArea = () => {
    let { form } = this.state;
    let obj = {
      villageName: form && form.area,
    };
    getBranchAndStateBySpoke(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        form.branch = res.data && res.data.data && res.data.data.branchName;
        form.state = res.data && res.data.data && res.data.data.stateName;
        this.setState({
          form,
        });
      }
    });
  };

  AreaVillagesDropDownByFranchiseCode = () => {
    let { loaderForArea } = this.state;
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let obj = {
      franchiseCode: leadData && leadData.leadEmployeeId,
    };
    this.setState({ loaderForArea: true });
    getAreaVillagesDropDownByFranchiseCode(obj).then(res => {
      if (res.error) {
        this.setState({ loaderForArea: false });
        return;
      }
      if (res.data.error) {
        this.setState({ loaderForArea: false });
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });

        this.setState({ loaderForArea: false });
        let newVillage = res && res.data && res.data.data;
        let PopAllValueFromDropdown = newVillage && newVillage.filter(e => e.villageName !== "All(1000111)");

        this.setState({
          areaDropdown: PopAllValueFromDropdown,
        });
      }
    });
  };

  handleCheckBoxChange = () => {
    let { form } = this.state;
    let { isThisWhatsAppNo } = this.state;

    this.setState({
      isThisWhatsAppNo: !isThisWhatsAppNo,
    });
  };

  //spoke method
  onInputChange = (name, value, error) => {
    let { form } = this.state;
    form[name] = value;
    console.log("fre", form[name]);
    this.setState({
      form,
    });
  };

  onBranchChange = (name, value, error) => {
    let { form } = this.state;
    form[name] = value;
    let str = form && form.area;
    if (form && form.area) {
      let match = str.match(/\(\d*?\)/g);
      form.pinCode = match && match?.[0].replace(/[\(\)']+/g, "");
      console.log("match", match && match?.[0]);
    }
    if ((form && form.area == null) || form?.area === "Others") {
      form.pinCode = "";
      form.cityTownVillage = "";
      form.district = "";
      this.setState({
        form,
      });
    }
    console.log("fre", form[name]);
    this.setState({
      form,
    });
    this.fetchtehsildistrictstatefromvillage();
    this.AllProductSelectionDropDown();
    /*if (form && form.area != "Others" && RoleData != "FRANCHISE") {
      this.GetStateAndBranchFromArea();
    }*/
    if (form) {
      // && form.area == "Others") {
      this.BranchAndStateByEmployeeId();
    }
  };

  fetchtehsildistrictstatefromvillage = () => {
    let { form } = this.state;
    let objBody = {
      villageName: form.area,
    };
    findtehsildistrictstate(objBody).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 2000,
        // });
        return;
      } else {
        console.log("res.dataaaa", res?.data?.data[0]);
        form.cityTownVillage = res?.data?.data?.[0]?.tehsil;
        form.district = res?.data?.data?.[0].district;
        this.setState({
          form,
        });
      }
    });
  };

  onProductChange = (name, value, error) => {
    let { form, campaignMediumValues, campaignNameValues } = this.state;
    form[name] = value;
    campaignNameValues = [];
    campaignMediumValues = [];
    form.campaignMedium = "NA";
    form.campaignName = "NA";
    console.log("fre", form[name]);
    this.setState({
      form,
      campaignNameValues,
      campaignMediumValues,
    });
    this.AllCampaignMediumDropDown(value);
    this.clearAll();
  };

  onCampaignMediumChange = (name, value, error) => {
    let { form, campaignNameValues } = this.state;

    form.campaignMedium = value;

    this.setState({
      form,
    });

    if (value !== "NA") {
      this.AllCampaignNamesDropdown(value);
    } else {
      campaignNameValues = [];
      this.setState({ campaignNameValues });
    }
  };

  onCampaignNameChange = (name, value, error) => {
    let { form } = this.state;
    form.campaignName = value;

    this.setState({
      form,
    });
  };

  onInputValidate = (name, error = undefined) => {
    let { form, selectedSpokeError } = this.state;
    if (error !== undefined || error !== null || error !== "") {
      selectedSpokeError = error;
    }
    this.setState({
      selectedSpokeError: selectedSpokeError,
    });
    // console.log("error", error)
  };

  onDrop = (file, base64, name) => {
    const { form } = this.state;
    const { errors } = form;
    errors.idProof = true;
    if (file.length <= 0) {
      this.setState({ idProof: file[0], form });
    }
    console.log("errors", errors);
    console.log("file", file);
    console.log("base64", base64);
    console.log("name", name);
    errors.idProof = null;
    this.setState({ idProof: file[0], form });
    this.setState({ imageUpload: true });

    // console.log(value);

    form[name] = file;
    this.setState({
      form,
    });

    if (name == "identityProofDocument" && file && file[0] && file[0].name) {
      this.setState({ uploadImageName1: file[0].name });
      console.log(file);
    }
  };

  onFormChange = (name, value, error = undefined) => {
    let { form } = this.state;
    form[name] = value;
    // if (error !== undefined) {
    //   let { errors } = form;
    //   errors[name] = error;
    // }
    this.setState({
      form,
    });

    if (name == "mobileNumber1" && value != "") {
      let indianMobilenumber = /^[6789]\d{9}$/;
      if ((value && value.length < 10) || !value.match(indianMobilenumber) || value[0] <= "5") {
        form.errors.mobileNumber1 = true;
        form.errors.mobileNumber1 = "Please enter valid mobile number";
        this.setState({ form });
      } else {
        form.errors.mobileNumber1 = false;
        form.errors.mobileNumber1 = "";
        this.setState({ form });
      }
    }

    if (name == "pinCode" && value !== "") {
      if (value && value.length < 6) {
        form.errors.pinCode = true;
        form.errors.pinCode = "Please enter valid Pincode";
        this.setState({ form });
      } else {
        form.errors.pinCode = false;
        form.errors.pinCode = "";
        this.setState({ form });
      }
    }

    if (name == "requestedLoanAmount" && value !== "") {
      if (value == 0) {
        form.errors.loanamt = true;
        form.errors.loanamt = "Loan amount should be non zero";
        this.setState({ form });
      } else {
        form.errors.loanamt = false;
        form.errors.loanamt = "";
        this.setState({ form });
      }
    }
  };

  saveorupdate = () => {
    let { form, isThisWhatsAppNo, loading } = this.state;
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let RoleData = localStorage.getItem("Role"); // Get the role of the logged in user from local storage
    let FlagForLead =
      RoleData == "FRANCHISE" || (RoleData == "Call Centre" && form?.productSelection == "Farm Implement Leasing")
        ? true
        : false;
    let obj = cloneDeep(form);
    obj.isThisWhatsAppNo1 = isThisWhatsAppNo;
    obj.employeeId = leadData && leadData.leadEmployeeId;
    obj.isLeadCreatedByFranchisee = FlagForLead;
    obj.isOthersFlag = RoleData == "Call Centre" ? true : false;
    this.setState({ loading: true });
    for (const item in obj) {
      if (typeof obj[item] === "string") {
        obj[item] = obj[item].trim();
      }
    }
    saveOrUpdateLeadDetails(obj).then(res => {
      console.log(res);

      if (res.data.data.id !== "" || res.data.data.id !== null || res.data.data.id !== undefined) {
        console.log("id", res.data.data.id);
        console.log("obj", obj);
        console.log("form", form);
        let objBody = res.data.data.leadId;
        let multiBody = {
          file: form.identityProofDocument[0],
        };
        if (
          res.data.data &&
          // res.status == "Lead created" &&
          (res.data.data.productSelection === "PR1" || res.data.data.productSelection === "PR2")
        ) {
          saveOrUpdateLeadDocument(objBody, multiBody).then(innerRes => {
            console.log(innerRes);
            this.props.history.push(`${public_url.approve_lead}/${res && res.data.data.leadId}`);
          });
        }
      }

      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        if (res.data.data && res.data.data.status === "Lead Rejected") {
          this.props.history.push(`${public_url.Leadmodule}`);
        }
        this.setState({ loading: false });
      } else {
        toast.success(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 4000,
        });
        this.props.history.push(`${public_url.Leadmodule}`);
        this.setState({ loading: false });
      }
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  // sendTocc = () => {
  //   let { form, isThisWhatsAppNo, loadingForCC } = this.state;
  //   let isOthersFlag = false;
  //   let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
  //   let RoleData = JSON.parse(localStorage.getItem("Role")); // Get the role of the logged in user
  //   let FlagForLead = RoleData == "FRANCHISE" ? true : false;
  //   let obj = cloneDeep(form);
  //   obj.isOthersFlag = form?.area == "Others" ? true : false;
  //   obj.isThisWhatsAppNo1 = isThisWhatsAppNo;
  //   obj.employeeId = leadData && leadData.leadEmployeeId;
  //   obj.isLeadCreatedByFranchisee = FlagForLead;

  //   let disablesave;
  //   if (
  //     (form && form.errors && form.errors.mobileNumber1 == "Please enter valid mobile number") ||
  //     form.errors.pinCode == "Please enter valid Pincode" ||
  //     form?.errors?.loanamt == "Loan amount should be non zero"
  //   ) {
  //     disablesave = true;
  //   } else {
  //     disablesave = false;
  //   }
  //   if (
  //     form.customerName == "" ||
  //     form.pinCode == "" ||
  //     form.productSelection == "" ||
  //     (form.productSelection == "Housing Loans" ||
  //     form.productSelection == "Farm Loans" ||
  //     form.productSelection == "Business Loans" ||
  //     form.productSelection == "Personal Loans" ||
  //     form.productSelection == "Gold Loans" ||
  //     form.productSelection == "Consumer Durables"
  //       ? form.productRequiredBy == "" || form.productRequiredBy == null || form.productRequiredBy == undefined
  //         ? true
  //         : false
  //       : false) ||
  //     ((form.farmService == "" || form.farmService == null || form.farmService == undefined) &&
  //       // (form.proofOfIdentity == "" || form.proofOfIdentity == null || form.proofOfIdentity == undefined) &&
  //       // (form.idNumber == "" || form.idNumber == null || form.idNumber == undefined) &&
  //       (form.requestedLoanAmount == "" || form.requestedLoanAmount == null || form.requestedLoanAmount == undefined) &&
  //       (form.productType == "" || form.productType == null || form.productType == undefined)) ||
  //     form.productSelection == null ||
  //     (form.productSelection == "Farm Implement Leasing"
  //       ? form.crop == "" || form.landArea == "" || form.farmService == null
  //         ? true
  //         : false
  //       : false) ||
  //     // (form.productSelection == "PR1" || "PR1" ? (form.proofOfIdentity == null ? true : false) : false) ||
  //     // (form.productSelection == "PR1" || "PR1" ? (form.idNumber == null ? true : false) : false) ||
  //     form.leadFollowUpDate == "" ||
  //     form.area == "" ||
  //     form.area == null ||
  //     form.mobileNumber1 == "" ||
  //     disablesave
  //   ) {
  //     toast.error("Please fill Mandatory fields", {
  //       type: toast.TYPE.ERROR,
  //       autoClose: 4000,
  //     });
  //   } else {
  //     this.setState({ loadingForCC: true });
  //     saveOrUpdateLeadDetails(obj).then(res => {
  //       if (res.error) {
  //         return;
  //       }
  //       if (res.data.error) {
  //         toast.error(res.data.message, {
  //           type: toast.TYPE.ERROR,
  //           autoClose: 4000,
  //         });
  //         this.setState({ loadingForCC: false });
  //       } else {
  //         toast.success(res.data.message, {
  //           type: toast.TYPE.SUCCESS,
  //           autoClose: 4000,
  //         });
  //         this.setState({ loadingForCC: false });
  //         this.props.history.push(`${public_url.Leadmodule}`);
  //       }
  //     });
  //   }
  //   console.log("form", form);
  // };

  clearAll = () => {
    let { form } = this.state;
    form.farmService = "";
    // form.proofOfIdentity = "";
    form.identityProof = "";
    // form.idNumber = "";
    form.identityProofId = "";

    form.landArea = "";
    form.crop = "";
    form.requestedLoanAmount = "";
    form.productType = "";
    form.productRequiredBy = "";
    this.setState({ form });
  };

  DataByLeadId = () => {
    let { form } = this.state;
    let { match } = this.props;
    console.log(this.props);
    let obj = {
      leadId: match.params.leadId,
    };
    getDataByLeadId(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        function addLeadingZeros(num, totalLength) {
          return String(num).padStart(totalLength, "0");
        }
        let id = addLeadingZeros(res.data.data.id, 6);
        form = { ...form, ...res?.data?.data };
        this.AllCampaignMediumDropDown(form.productSelection);
        form.campaignMedium && this.AllCampaignNamesDropdown(form.campaignMedium);
        this.setState({
          OthersData: res?.data?.data,
          form,
          status: res?.data?.data?.status,
          isThisWhatsAppNo: res.data && res.data.data.isThisWhatsAppNo1,
          // isLeadCreatedByFranchisee: res?.data?.data?.isLeadCreatedByFranchisee,
          leadIdforCC: res?.data?.data?.leadId,
          updateIdforCC: res?.data?.data?.id,
          createdUserId: res?.data?.data?.createdUserId,
          // leadCreatedDate: res?.data?.data?.leadCreatedDate,
          leadGenerator: res?.data?.data?.leadGenerator,
          isLeadCreatedByFranchisee: res?.data?.data?.isLeadCreatedByFranchisee,
          villageMasterId: res?.data?.data?.villageMasterId,
          isOthersFlag: res?.data?.data?.isOthersFlag,
          leadFollowUpDate: res?.data?.data?.leadFollowUpDate,
        });
        this.AllProductSelectionDropDown();
        this.FarmServicesDropDownByFranchiseCode();
        this.BranchAndStateByEmployeeId();
        this.AreaVillagesDropDownByFranchiseCode();
        this.allCDProducts();
      }
      // this.setState({
      //if (RoleData != "FRANCHISE") {
      //  this.GetStateAndBranchFromArea();
      //}
    });
  };

  render() {
    let {
      form,
      campaignNameValues,
      campaignMediumValues,
      productCampaignDropdown,
      productDropdown,
      areaDropdown,
      isThisWhatsAppNo,
      farmDropdown,
      imageUpload,
      uploadImageName1,
      show,
      // proofOfIdentityDropdown,
      loading,
      loadingForCC,
      CDProductDropdown,
      loaderForArea,
    } = this.state;

    let {
      area,
      branch,
      state,
      entityType,
      customerName,
      mobileNumber1,
      pinCode,
      productSelection,
      campaignMedium,
      campaignName,
      farmService,
      id,
      // proofOfIdentity,
      identityProof,
      // idNumber,
      crop,
      // inquiryDate,
      // inquirySource,
      // customerOccupation,
      // firstWorkingMember,
      // secondWorkingmember,
      // firstMemberOccupation,
      // familyMemberOccuption,
      // workPlaceVillage,
      // workPlacePincode,
      // existingLoan,
      // emiForExistingLoan,
      // finalCallingDateByCcAgents,
      remarks,
      landArea,
      leadFollowUpDate,
      isThisWhatsAppNo1,
      selectedSpokeError,
      requestedLoanAmount,
      productType,
      productRequiredBy,
    } = form;
    let disablesave;
    if (
      (form && form.errors && form.errors.mobileNumber1 == "Please enter valid mobile number") ||
      form.errors.pinCode == "Please enter valid Pincode" ||
      form?.errors?.loanamt == "Loan amount should be non zero"
    ) {
      disablesave = true;
    } else {
      disablesave = false;
    }
    console.log("form", form);

    return (
      <React.Fragment>
        <BreadCrumbs
          name1={"Lead List"}
          name2={"Add Lead"}
          url1={`${public_url.Leadmodule}`}
          url2={`${public_url.add_lead}`}
        />
        <section className="px-2 px-md-5 pt-4 pb-5 dashboard_div bg_l-secondary">
          <div className="">
            <div className="d-flex justify-content-start align-items-center">
              <section className="py-4 position-relative bg_l-secondary w-100">
                <div className="pb-5 bg-white">
                  <div className="container">
                    <div className="row disbursment">
                      <div className="col-md-6 col-sm-6 col-lg-1 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">Spoke {<i className="text-danger">*</i>}</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-3 mt-3 mr-auto">
                        <div class="select">
                          {/* new select */}
                          {loaderForArea ? (
                            <React.Fragment>
                              <i class="fa fa-spinner fa-spin ml-5 gTextPrimary fw-500"></i>
                              <span className="ml-2 fs-18 gTextPrimary fw-500">Please wait...</span>
                            </React.Fragment>
                          ) : (
                            <Select
                              className="w-100 fs-12 create-lead-form-select"
                              options={areaDropdown}
                              value={form.area}
                              title="area"
                              name="area"
                              onChangeFunc={(name, value, error) => {
                                this.onBranchChange(name, value, error);
                              }}
                              onBlur={() => {
                                if (form.area == undefined || form.area == null || form.area == "") {
                                  let error = "Please select user";
                                  this.onInputValidate("area", error);
                                } else {
                                  let error = "";
                                  this.onInputValidate("area", error);
                                }
                              }}
                              isClearable={true}
                              error={selectedSpokeError}
                              isReq={true}
                              valueKey="villageCode"
                              labelKey="villageName"
                              placeholder="Select area"
                            />
                          )}
                          {/* new select end */}
                          {/* <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={areaDropdown}
                            value={area}
                            title="area"
                            name="area"
                            onChangeFunc={(name, value, error) => {
                              this.onFormChange(name, value, error);
                            }}
                            //   onBlur={() => { }}
                            isReq={true}
                            // disabled={true}
                            valueKey="villageCode"
                            labelKey="villageName"
                            placeholder="Select Spoke"
                          /> */}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-1 d-flex mt-3 align-items-center mt-1">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">Branch</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-3  mt-3">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Branch"
                          name="branch"
                          value={branch}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          // maxLength={16}
                          disabled={true}
                          isReq={true}
                          // reqType="alphaNumeric"
                          title="branch"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-1 d-flex align-items-center mt-1">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">State</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-3 mt-3 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="State"
                          name="state"
                          value={state}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          // maxLength={16}
                          disabled={true}
                          isReq={true}
                          // reqType="alphaNumeric"
                          title="state"
                        />
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <hr className="bg_lightblue border-0 h-1px mt-0" />
                    </div>
                    <div className="row disbursment mt-2">
                      <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">Entity Type</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Entity Type"
                          name="entityType"
                          value={entityType}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          // maxLength={16}
                          // disabled={}
                          isReq={true}
                          disabled={true}
                          // reqType="alphaNumeric"
                          title="entityType"
                        />
                      </div>
                      <div className=" col-lg-6"></div>
                      <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">
                          Customer Name {<i className="text-danger">*</i>}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-5 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Customer Name"
                          name="customerName"
                          value={form && form.customerName}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={100}
                          // disabled={}
                          isReq={true}
                          reqType="onlyAlphbate"
                          // reqType="alphaNumeric"
                          title="customerName"
                        />
                      </div>
                      <div className=" col-lg-5"></div>
                      <div className="col-md-6 col-sm-6 col-lg-2  d-flex align-items-center ">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">
                          Mobile No. 1 {<i className="text-danger">*</i>}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto mb-0">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Mobile No. 1"
                          name="mobileNumber1"
                          value={mobileNumber1}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={10}
                          error={form && form.errors && form.errors.mobileNumber1}
                          // disabled={}
                          isReq={true}
                          reqType="mobile10"
                          title="mobileNumber1"
                        />
                        {/* <div style={{ color: "red", fontSize: "12px" }}>
                          {form && form.errors && form.errors.mobileNumber1}
                        </div> */}
                      </div>
                      <div className="col-md-6 col-lg-2 col-sm-6 mt-2 align-self-center ">
                        <span className="main gTextPrimary ml-2 checkboxText">Is this WhatsApp No?</span>
                      </div>
                      <div className="col-md-6 col-lg-1 col-sm-6 chkbox">
                        <label className="main styleGreen gTextPrimary my-lg-5">
                          <input
                            type="checkbox"
                            name="isThisWhatsAppNo"
                            value={isThisWhatsAppNo}
                            checked={isThisWhatsAppNo}
                            onChange={this.handleCheckBoxChange}
                          />
                          <span className="geekmark"></span>
                        </label>
                      </div>
                      <div className=" col-lg-3"></div>
                      <div className="col-md-6 col-sm-6 col-lg-2  mb-4  d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">
                          Pin Code {<i className="text-danger">*</i>}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Pin Code"
                          name="pinCode"
                          value={pinCode}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          error={form && form.errors && form.errors.pinCode}
                          maxLength={6}
                          disabled={
                            form?.area === "Others" ||
                            form?.area === "" ||
                            form?.area === null ||
                            form?.area === undefined
                              ? false
                              : true
                          }
                          isReq={true}
                          reqType="number"
                          title="pinCode"
                        />
                      </div>
                      <div className=" col-lg-6"></div>
                      <div className="col-md-6 col-sm-6 col-lg-2 mt-3  mb-2 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">
                          Product Selection {<i className="text-danger">*</i>}
                        </label>
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-4 mt-3 mr-auto">
                        <div class="select">
                          {/* new product start */}

                          <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={productDropdown}
                            value={productSelection}
                            title="productSelection"
                            name="productSelection"
                            onChangeFunc={(name, value, error) => {
                              this.onProductChange(name, value, error);
                            }}
                            isClearable={true}
                            //  error={selectedSpokeError}
                            isReq={true}
                            valueKey="productdescription"
                            labelKey="productdescription"
                            placeholder="Select Product"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2 mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary ml-2 fw-500">
                          {productSelection == "Housing Loans" ||
                          productSelection == "Farm Loans" ||
                          productSelection == "Business Loans" ||
                          productSelection == "Personal Loans" ||
                          productSelection == "Gold Loans" ||
                          productSelection == "Gold @Home" ? (
                            <>
                              Requested loan amount <i className="text-danger">*</i>
                            </>
                          ) : productSelection == "Farm Implement Leasing" ? (
                            <>
                              Farm Service <i className="text-danger">*</i>
                            </>
                          ) : productSelection == "PR1" || productSelection == "PR2" ? (
                            <>
                              Proof of identity <i className="text-danger">*</i>
                            </>
                          ) : productSelection == "Consumer Durables" ? (
                            <>
                              Product Type <i className="text-danger">*</i>{" "}
                            </>
                          ) : null}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-3 mr-auto">
                        <div class="select">
                          {/* new frm start */}
                          {productSelection == "Housing Loans" ||
                          productSelection == "Farm Loans" ||
                          productSelection == "Business Loans" ||
                          productSelection == "Personal Loans" ||
                          productSelection == "Gold Loans" ||
                          productSelection == "Gold @Home" ? (
                            <Input
                              className="form-control text-center fs-16"
                              placeholder="Enter Requested Loan Amount"
                              name="requestedLoanAmount"
                              value={requestedLoanAmount && formatIndianCurrency(requestedLoanAmount).toString()}
                              onChangeFunc={(name, value, error) => {
                                value = replaceComma(value);
                                if (checkIfNotDecimal(value)) {
                                  this.onFormChange(name, value, error);
                                }
                              }}
                              error={form && form.errors && form.errors.loanamt}
                              // disabled={}
                              maxLength="9"
                              isReq={true}
                              reqType="number"
                              title="requestedLoanAmount"
                            />
                          ) : productSelection == "Farm Implement Leasing" ? (
                            <Select
                              className="w-100 fs-12 create-lead-form-select"
                              options={farmDropdown}
                              value={farmService}
                              title="farmService"
                              name="farmService"
                              onChangeFunc={(name, value, error) => {
                                this.onInputChange(name, value, error);
                              }}
                              isClearable={true}
                              //  error={selectedSpokeError}
                              isReq={true}
                              valueKey="serviceName"
                              labelKey="serviceName"
                              placeholder="Select Farm Service"
                            />
                          ) : productSelection == "PR1" ? (
                            <Select
                              className="w-100 fs-12 create-lead-form-select"
                              options={identityProofDropdown}
                              value={identityProof}
                              title="identityProof"
                              name="identityProof"
                              onChangeFunc={(name, value, error) => {
                                this.onInputChange(name, value, error);
                              }}
                              isClearable={true}
                              //  error={selectedSpokeError}
                              isReq={true}
                              // valueKey="serviceName"
                              // labelKey="serviceName"
                              placeholder="Select Proof of identity "
                            />
                          ) : productSelection == "PR2" ? (
                            <Select
                              className="w-100 fs-12 create-lead-form-select"
                              options={identityProofDropdown}
                              value={identityProof}
                              title="identityProof"
                              name="identityProof"
                              onChangeFunc={(name, value, error) => {
                                this.onInputChange(name, value, error);
                              }}
                              isClearable={true}
                              //  error={selectedSpokeError}
                              isReq={true}
                              // valueKey="serviceName"
                              // labelKey="serviceName"
                              placeholder="Select Proof of identity "
                            />
                          ) : // : productSelection == "PR2" ? (

                          // )
                          // {imageUpload ? <div>*{uploadImageName1}</div> : null}
                          productSelection == "Consumer Durables" ? (
                            <Select
                              className="w-100 fs-12 create-lead-form-select"
                              options={CDProductDropdown}
                              value={productType}
                              title="productType"
                              name="productType"
                              onChangeFunc={(name, value, error) => {
                                this.onInputChange(name, value, error);
                              }}
                              isClearable={true}
                              //  error={selectedSpokeError}
                              isReq={true}
                              valueKey="productName"
                              labelKey="productName"
                              placeholder="Select Product Type"
                            />
                          ) : null}
                          {/* new farm end */}
                        </div>
                      </div>

                      {/*  */}
                      {productSelection == "Housing Loans" ||
                      productSelection == "Farm Loans" ||
                      productSelection == "Business Loans" ||
                      productSelection == "Personal Loans" ||
                      productSelection == "Gold Loans" ? null : productSelection == "Farm Implement Leasing" ? (
                        <>
                          <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                            <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">
                              Crop {<i className="text-danger">*</i>}
                            </label>
                          </div>
                          <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                            <Input
                              className="form-control text-center fs-16"
                              placeholder="Crop"
                              name="crop"
                              value={crop}
                              onChangeFunc={(name, value, error) => {
                                //   value = replaceComma(value);
                                this.onFormChange(name, value, error);
                              }}
                              maxLength={50}
                              // disabled={}
                              isReq={true}
                              reqType="onlyAlphbate"
                              title="crop"
                            />
                          </div>
                          <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                            <label class="fs-14 mb-0 ml-2 gTextPrimary fw-500">
                              Land Area (In Acres) {<i className="text-danger">*</i>}
                            </label>
                          </div>
                          <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                            <Input
                              className="form-control text-center fs-16"
                              placeholder="Land Area (In Acres)"
                              name="landArea"
                              value={
                                landArea &&
                                landArea
                                  .toString()
                                  .split(".")
                                  .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                                  .join(".")
                              }
                              onChangeFunc={(name, value, error) => {
                                if (value == "" || (Math.ceil(value) <= 999 && parseInt(value) >= 0)) {
                                  value = value
                                    .toString()
                                    .split(".")
                                    .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                                    .join(".");
                                  this.onFormChange(name, value, error);
                                }
                                // this.onFormChange(name, value, error);
                              }}
                              // maxLength={16}
                              // disabled={}
                              isReq={true}
                              reqType="number"
                              title="landArea"
                            />
                          </div>
                        </>
                      ) : null}
                      <div className="col-md-6 col-sm-6 col-lg-2 mt-3  mb-2 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">
                          {productSelection == "PR1" || productSelection == "PR2" ? (
                            <>
                              Proof of identity number <i className="text-danger">*</i>
                            </>
                          ) : null}
                        </label>
                      </div>
                      {/* <div className="d-flex align-items-center"> */}
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 d-flex align-items-center insurance">
                        {/* <div class="select"> */}
                        {/* <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto"></div> */}
                        {/* <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto"> */}
                        {productSelection == "PR1" || productSelection == "PR2" ? (
                          <Input
                            className="form-control text-center fs-16 xyz"
                            name="identityProofId"
                            value={form.identityProofId}
                            onChangeFunc={(name, value, error) => {
                              this.onFormChange(name, value, error);
                            }}
                            maxLength={16}
                            disabled={form.isBlackList}
                            isReq={true}
                            title="identityProofId"
                            reqType="POANumber"
                            type="text"
                            placeholder="Proof of identity number"
                          />
                        ) : null}
                        {/* </div> */}
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-2 mt-3  mb-2 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">
                          {productSelection == "PR1" || productSelection == "PR2" ? (
                            <>
                              Proof of identity document <i className="text-danger">*</i>
                            </>
                          ) : null}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-3 mr-auto">
                        <div class="select">
                          {productSelection == "PR1" || productSelection == "PR2" ? (
                            <ImageUploader
                              className="form-control  fs-16"
                              name="identityProofDocument"
                              withIcon={true}
                              withPreview={false}
                              withLabel={true}
                              accept=".pdf,.jpg,.png,.jpeg"
                              buttonText="Identity Document Upload"
                              label="Max file size: 5mb, accepted: PDF only"
                              onChange={(file, base64) => {
                                this.onDrop(file, base64, "identityProofDocument");
                              }}
                              imgExtension={[
                                ".jpg",
                                // ".gif",
                                ".png",
                                ".jpeg",
                                ".pdf",
                                // ".docx",
                                // ".pptx",
                                // ".xlsx",
                              ]}
                              maxFileSize={5242880}
                              singleImage
                            />
                          ) : null}
                          {imageUpload ? <div>*{uploadImageName1}</div> : null}
                        </div>
                      </div>

                      {(productSelection !== null || productSelection !== "") && campaignMediumValues.length !== 0 && (
                        <>
                          <div className="col-md-6 col-sm-6 col-lg-2 mt-3  mb-2 d-flex align-items-center">
                            <label class="fs-14 mb-0 gTextPrimary fw-500">
                              Campaign Medium {<i className="text-danger">*</i>}
                            </label>
                          </div>
                          <div className="col-md-6 col-sm-6 col-lg-4 mt-3 mr-auto">
                            <div class="select">
                              {/* new product start */}
                              <Select
                                className="w-100 fs-12 create-lead-form-select"
                                options={campaignMediumValues}
                                value={campaignMedium}
                                title="campaignMediumSelection"
                                name="campaignMediumSelection"
                                onChangeFunc={(name, value, error) => {
                                  this.onCampaignMediumChange(name, value, error);
                                }}
                                isClearable={true}
                                valueKey="campaignMedium"
                                labelKey="campaignMedium"
                                placeholder="Select Campaign Medium"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {(productSelection !== null || productSelection !== "") && campaignNameValues.length !== 0 && (
                        <>
                          <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                            <label class="fs-14 mb-0 gTextPrimary fw-500">
                              Campaign Name {<i className="text-danger">*</i>}
                            </label>
                          </div>
                          <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                            <div class="select">
                              {/* new product start */}
                              <Select
                                className="w-100 fs-12 create-lead-form-select"
                                options={campaignNameValues}
                                value={campaignName}
                                title="campaignNameSelection"
                                name="campaignNameSelection"
                                onChangeFunc={(name, value, error) => {
                                  this.onCampaignNameChange(name, value, error);
                                }}
                                isClearable={true}
                                valueKey="campaignName"
                                labelKey="campaignName"
                                placeholder="Select Campaign Name"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {/*(productSelection === null) && (
                        <>
                          <div className=" col-lg-6"></div>
                        </>
                      )*/}

                      <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">
                          Lead Follow-up Date {<i className="text-danger">*</i>}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Lead Follow-up Date"
                          name="leadFollowUpDate"
                          value={moment(leadFollowUpDate).format("YYYY-MM-DD")}
                          onChangeFunc={(name, value, error) => {
                            this.onFormChange(name, value, error);
                          }}
                          type="date"
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          // maxLength={16}
                          // disabled={}
                          isReq={true}
                          // reqType="number"
                          title="leadFollowUpDate"
                        />
                      </div>

                      {/*  */}
                      {productSelection == "Farm Implement Leasing" ? null : (
                        <>
                          <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                            <label class="fs-14 mb-0 gTextPrimary fw-500">
                              Product Required By {<i className="text-danger">*</i>}
                            </label>
                          </div>
                          <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                            <div class="select">
                              {/* new product start */}

                              <Select
                                className="w-100 fs-12 create-lead-form-select"
                                options={productValue}
                                value={productRequiredBy}
                                title="productRequiredBy"
                                name="productRequiredBy"
                                onChangeFunc={(name, value, error) => {
                                  this.onFormChange(name, value, error);
                                }}
                                isClearable={true}
                                disabled={productSelection == "" || productSelection == null}
                                //  error={selectedSpokeError}
                                isReq={true}
                                // valueKey="productdescription"
                                // labelKey="productdescription"
                                placeholder="Select Product required by"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Remarks</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Remarks"
                          name="remarks"
                          value={remarks}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="alphaNumeric"
                          title="remarks"
                        />
                      </div>

                      {/* <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">
                        Inquiry Date{" "}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Inquiry Date"
                          name="inquiryDate"
                          value={inquiryDate}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="inquiryDate"
                        />
                      </div> */}

                      {/* <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">
                        Inquiry Source{" "}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Inquiry Source"
                          name="inquirySource"
                          value={inquirySource}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="inquirySource"
                        />
                      </div> */}

                      {/* <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">
                        Customer Occuption{" "}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Customer's Occupation"
                          name="customerOccupation"
                          value={customerOccupation}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="customerOccupation"
                        />
                      </div> */}

                      {/* <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">
                        working Family Member{" "}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Working Family Member"
                          name="firstWorkingMember"
                          value={firstWorkingMember}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="firstWorkingMember"
                        />
                      </div> */}

                      {/* <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">
                        family Member Occuption{" "}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Family Member Occupation"
                          name="familyMemberOccuption"
                          value={familyMemberOccuption}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="familyMemberOccuption"
                        />
                      </div> */}

                      {/* <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">
                        work Place Village{" "}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Work Place Village"
                          name="workPlaceVillage"
                          value={workPlaceVillage}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="workPlaceVillage"
                        />
                      </div> */}

                      {/* <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">
                        work Place Pincode{" "}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Work Place Pincode"
                          name="workPlacePincode"
                          value={workPlacePincode}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="workPlacePincode"
                        />
                      </div> */}

                      {/* <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">
                        Existing Loan{" "}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Existing loan"
                          name="existingLoan"
                          value={existingLoan}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="existingLoan"
                        />
                      </div> */}

                      {/* <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">
                        EMI For Existing Loan{" "}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="EMI for existing loan"
                          name="emiForExistingLoan"
                          value={emiForExistingLoan}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="emiForExistingLoan"
                        />
                      </div> */}

                      {/* <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">
                        Final Calling Date By CC Agents{" "}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Final Calling date by CC Agents"
                          name="finalCallingDateByCcAgents"
                          value={finalCallingDateByCcAgents}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="finalCallingDateByCcAgents"
                        />
                      </div> */}
                    </div>

                    {/* save */}
                    <div className="row justify-content-end col-md-12 col-sm-12 mt-2 col-lg-12">
                      <div className="row">
                        {/* {RoleData == "Call Centre" ? null : (
                          <button
                            disabled={
                              (form.area == "Others" ? false : true) ||
                              loadingForCC ||
                              customerName == "" ||
                              pinCode == "" ||
                              productSelection == "" ||
                              (form.farmService == "" &&
                                form.requestedLoanAmount == "" &&
                                form.productType == "") ||
                              (form.farmService == null &&
                                form.requestedLoanAmount == null &&
                                form.productType == null) ||
                              productSelection == null ||
                              (form.productSelection == "Farm Implement Leasing"
                                ? form.crop == "" ||
                                  form.landArea == "" ||
                                  form.farmService == null
                                  ? true
                                  : false
                                : false) ||
                              leadFollowUpDate == "" ||
                              area == "" ||
                              area == null ||
                              mobileNumber1 == "" ||
                              disablesave ||
                              loading ||
                              (productSelection !== "Farm Implement Leasing" &&
                                productRequiredBy == "") ||
                              (RoleData == "GRO" && form.area == "Others" && form.productSelection == "Gold Loans") ||
                              (RoleData == "BRO" && form.area == "Others" && form.productSelection !== "Gold Loans" && form.productSelection !== "Farm Implement Leasing") ||
                              ((RoleData == "FRO" || RoleData == "FRANCHISE") && form.area == "Others" && form.productSelection == "Farm Implement Leasing")
                            }
                            className={`btn btn-secondary btn-rounded ls-1 cursor-pointer mr-4 fs-16 btn-green`}
                            onClick={this.sendTocc}
                          >
                            Send To BM
                          </button>
                        )} */}

                        {/* <button
                        className={`btn btn-secondary btn-rounded ls-1 cursor-pointer mr-4 fs-16 btn-green`}
                        onClick={this.handleShow}
                      >
                        save lead
                      </button> */}

                        <button
                          //   disabled={}
                          className={`btn btn-secondary btn-rounded ls-1 cursor-pointer mr-4 fs-16 btn-green`}
                          onClick={() => this.props.history.push(`${public_url.Leadmodule}`)}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={
                            customerName == "" ||
                            pinCode == "" ||
                            productSelection == "" ||
                            // (form.farmService == "" && form.requestedLoanAmount == "" && form.productType == "") ||
                            (form.farmService == null &&
                              form.requestedLoanAmount == null &&
                              form.productType == null) ||
                            productSelection == null ||
                            (form.productSelection == "Farm Implement Leasing" &&
                              (form.crop == "" || form.landArea == "" || form.farmService == null)) ||
                            (form.productSelection == "PR1" &&
                              (form.identityProofId == "" ||
                                form.identityProof == "" ||
                                form.identityProofDocument == "")) ||
                            (form.productSelection == "PR1" &&
                              (form.identityProofId == null ||
                                form.identityProof == null ||
                                form.identityProofDocument == null)) ||
                            (form.productSelection == "PR1" &&
                              (form.identityProofId == undefined ||
                                form.identityProof == undefined ||
                                form.identityProofDocument == undefined)) ||
                            leadFollowUpDate == "" ||
                            area == "" ||
                            area == null ||
                            mobileNumber1 == "" ||
                            disablesave ||
                            loading ||
                            (productSelection !== "Farm Implement Leasing" && productRequiredBy == "") ||
                            form.campaignMedium === "" ||
                            form.campaignName === ""
                          }
                          className={`btn btn-secondary btn-rounded ls-1 cursor-pointer  fs-16 btn-green`}
                          onClick={this.saveorupdate}
                        >
                          {loading ? (
                            <React.Fragment>
                              <i class="fa fa-spinner fa-spin"></i>
                              Saving...
                            </React.Fragment>
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* modal */}
            <Modal show={show} onHide={this.handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Decision</Modal.Title>
              </Modal.Header>
              {/* <Modal.Body> 
                     <div className="row">
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">Rejection Reason</label>
                        <lable className="text-danger">*</lable>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 mt-3 mr-auto">
                        <div class="select">
                          <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={rejectDropdown}
                            value={rejectionRemark}
                            title="rejectionRemark"
                            name="rejectionRemark"
                            onChangeFunc={(name, value, error) => {
                              this.onRejectChange(name, value, error);
                            }}
                            isReq={true}
                            valueKey="rejectionRemark"
                            labelKey="rejectionRemark"
                            placeholder="Select Rejection Reason"
                          />
                          {this.state.rejectionRemarkError && <span className="text-danger">Please Select</span>}
                        </div>
                      </div>
                      <div className="col-lg-2"></div>
                      <div className="col-md-6 col-sm-6 col-lg-4 d-flex align-items-center">
                        <label className="fs-14 mb-0  gTextPrimary fw-500">Rejection Remarks</label>
                        <lable className="text-danger">*</lable>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder=" Rejection Remarks"
                          name="otherRemark"
                          value={otherRemark}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onRejectChange(name, value, error);
                          }}
                          maxLength={255}
                          // disabled={}
                          isReq={true}
                          // reqType="alphaNumeric"
                          title="otherRemark"
                        />
                      </div>
                    </div>
                  </Modal.Body> */}
              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.props.history.push(`${public_url.update_lead}/${id}`)}>
                  Yes
                </Button>
                <Button
                  variant="primary"
                  onClick={() => this.props.history.push(`${public_url.add_lead}`)}
                  // onClick={this.handleClose}
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Addlead;
