import React, { Component } from "react";
import BreadCrumbs from "../../Component/BreadCrumbs/index";
import { Select, Input, TextArea, File } from "../../Component/Input";
import { cloneDeep } from "lodash";
import moment from "moment";
import { public_url, formatIndianCurrency, replaceComma, checkIfNotDecimal } from "../../Utility/Constant";
// import { Loader } from "../../Component/Loader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  saveOrUpdateLeadDetails,
  getFarmServicesDropDownByFranchiseCode,
  findAllProductSelectionDropDown,
  findAllTypeofInteractionDropDown,
  getDataByLeadId,
  getAreaVillagesDropDownByFranchiseCode,
  findAllEducationStatusDropDown,
  findAllRejectionRemarksDropDown,
  getBranchAndStateByEmployeeId,
  rejectLeadByLeadId,
  getAllProductName,
  getBranchAndStateBySpoke,
  findtehsildistrictstate,
  findActiveCampaignMediums,
  findActiveCampaignNames,
} from "../../Utility/Services/Leadmanage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAccessId } from "../../Utility/Helper";
toast.configure();

const productValue = [
  { value: "In this week", label: "In this week" },
  { value: "In this month", label: "In this month" },
  { value: "Next 2-3 months", label: "Next 2-3 months" },
  { value: "Next 4-6 months", label: "Next 4-6 months" },
];

const initForm = {
  area: "",
  branch: "",
  remarks: "",
  state: "",
  entityType: "Individual",
  existingCustomer: false,
  customerName: "",
  mobileNumber1: "",
  isThisWhatsAppNo1: false,
  pinCode: "",
  productSelection: "",
  campaignMedium: "NA",
  campaignName: "NA",
  farmService: "",
  requestedLoanAmount: "",
  productType: "",
  crop: "",
  landArea: "",
  leadFollowUpDate: "",
  gender: "",
  dateOfBirth: "",
  age: "",
  maritalStatus: "",
  educationStatus: "",
  mobileNumber2: "",
  inquiryDate: "",
  inquirySource: "",
  customerOccupation: "",
  firstWorkingMember: "",
  secondWorkingmember: "",
  secondMemberOccupation: "",
  firstMemberOccupation: "",
  familyMemberOccuption: "",
  workPlaceVillage: "",
  workPlacePincode: "",
  secondExistingLoans: "",
  firstExistingLoans: "",
  firstExistingLoanEmi: "",
  secondExistingLoanEmi: "",
  ccLastCall: "",
  isThisWhatsAppNo2: false,
  alternateNo: "",
  addLine1: "",
  addLine2: "",
  cityTownVillage: "",
  pincodeAutofetchState: "",
  district: "",
  endUse: "",
  typeOfInteraction: "",
  label: "",
  campaignMedium: "NA",
  campaignName: "NA",
  productRequiredBy: "",
  errors: {
    mobileNumber1: null,
    pinCode: null,
    loanamt: null,
  },
};

export class UpdateLeadForCC extends Component {
  state = {
    form: cloneDeep(initForm),
    productDropdown: [],
    farmDropdown: [],
    branchstateData: {},
    areaDropdown: [],
    CDProductDropdown: [],
    isThisWhatsAppNo: false,
    selectedSpokeError: null,
    loading: false,
    status: "",
    leadIdforCC: "",
    updateIdforCC: null,
    createdUserId: null,
    // leadCreatedDate: "",
    leadGenerator: "",
    isLeadCreatedByFranchisee: false,
    villageMasterId: "",
    isOthersFlag: false,
    OthersData: "",
    leadFollowUpDate: "",
    loaderForArea: false,
    rejectDropdown: [],
    rejectionRemark: "",
    otherRemark: "",
    campaignMediumValues: [],
    campaignNameValues: [],
  };

  componentDidMount() {
    this.DataByLeadId();
    this.AllProductSelectionDropDown();
    this.FarmServicesDropDownByFranchiseCode();
    this.BranchAndStateByEmployeeId();
    this.AreaVillagesDropDownByFranchiseCode();
    this.allCDProducts();
    this.AllRejectionRemarksDropDown();
    this.AllCampaignMediumDropDown("All");
    this.AllCampaignNamesDropdown("NA");
  }

  AllCampaignMediumDropDown = product => {
    findActiveCampaignMediums(product).then(res => {
      console.log("res 000 ", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        console.log("error in fetching the data");
      } else {
        let campaignMediumValues = res.data.data;
        if (!campaignMediumValues) campaignMediumValues = [];
        campaignMediumValues.push({ campaignMediumDescription: "NA", id: 0, campaignMedium: "NA", productType: "All" });
        this.setState({
          campaignMediumValues,
        });
        console.log("campaignMediumValues in method: ", campaignMediumValues);
      }
    });
  };

  AllCampaignNamesDropdown = mediumName => {
    findActiveCampaignNames(mediumName).then(res => {
      console.log("res", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        console.log("error in fetching the data");
        let campaignNameValues = [];
        campaignNameValues.push({ campaignNameDescription: "NA", id: 0, campaignName: "NA", campaignMedium: "NA" });
        this.setState({
          campaignNameValues,
        });
      } else {
        let campaignNameValues = res.data.data;
        if (!campaignNameValues) campaignNameValues = [];
        campaignNameValues.push({ campaignNameDescription: "NA", id: 0, campaignName: "NA", campaignMedium: "NA" });
        this.setState({
          campaignNameValues,
        });
      }
    });
  };

  AllRejectionRemarksDropDown = () => {
    findAllRejectionRemarksDropDown().then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          rejectDropdown: res.data.data,
        });
      }
    });
  };

  onRejectChange = (name, value, error = undefined) => {
    this.state[name] = value;
    this.setState({
      state: this.state,
    });
  };

  AllProductSelectionDropDown = () => {
    let { form } = this.state;
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let employeeId = leadData && leadData.leadEmployeeId;
    let obj = {
      employeeId: employeeId,
      searchString: "Others",
    };
    findAllProductSelectionDropDown(obj).then(res => {
      console.log("res", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          productDropdown: res.data && res.data.data,
        });
      }
    });
  };

  allCDProducts = () => {
    getAllProductName().then(res => {
      console.log("res", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          CDProductDropdown: res.data && res.data.data,
        });
      }
    });
  };

  FarmServicesDropDownByFranchiseCode = () => {
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let obj = {
      franchiseCode: leadData && leadData.leadEmployeeId,
    };

    getFarmServicesDropDownByFranchiseCode(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          farmDropdown: res.data && res.data.data,
        });
      }
    });
  };

  BranchAndStateByEmployeeId = () => {
    let { form } = this.state;
    let { branch, state } = form;
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let obj = {
      employeeId: leadData && leadData.leadEmployeeId,
    };
    getBranchAndStateByEmployeeId(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        form.branch = res.data && res.data.data && res.data.data.branchName;
        form.state = res.data && res.data.data && res.data.data.stateName;
        this.setState({
          branchstateData: res.data && res.data.data,
          form,
        });
      }
    });
  };

  GetStateAndBranchFromArea = () => {
    let { form } = this.state;
    let obj = {
      villageName: form && form.area,
    };
    getBranchAndStateBySpoke(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        form.branch = res.data && res.data.data && res.data.data.branchName;
        form.state = res.data && res.data.data && res.data.data.stateName;
        this.setState({
          form,
        });
      }
    });
  };

  AreaVillagesDropDownByFranchiseCode = () => {
    let { loaderForArea } = this.state;
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let obj = {
      franchiseCode: leadData && leadData.leadEmployeeId,
    };
    this.setState({ loaderForArea: true });
    getAreaVillagesDropDownByFranchiseCode(obj).then(res => {
      if (res.error) {
        this.setState({ loaderForArea: false });
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
        this.setState({ loaderForArea: false });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({ loaderForArea: false });
        let newVillage = res && res.data && res.data.data;
        let PopAllValueFromDropdown = newVillage && newVillage.filter(e => e.villageName !== "All(1000111)");

        this.setState({
          areaDropdown: PopAllValueFromDropdown,
        });
      }
    });
  };

  handleCheckBoxChange = () => {
    let { form } = this.state;
    let { isThisWhatsAppNo } = this.state;

    this.setState({
      isThisWhatsAppNo: !isThisWhatsAppNo,
    });
  };

  handleReject = () => {
    let { rejectionRemark, otherRemark } = this.state;
    let { match } = this.props;
    let obj = {
      leadId: match.params.leadId,
      rejectionRemark: rejectionRemark,
      otherRemark: otherRemark,
    };
    rejectLeadByLeadId(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        toast.success(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 4000,
        });
        this.props.history.push(`${public_url.Leadmodule}`);
      }
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  //spoke method
  onInputChange = (name, value, error) => {
    let { form } = this.state;
    form[name] = value;
    console.log("fre", form[name]);
    this.setState({
      form,
    });
  };

  onBranchChange = (name, value, error) => {
    let { form } = this.state;
    let RoleData = JSON.parse(localStorage.getItem("Role")); // Get the role of the logged in user from local storage
    form[name] = value;
    let str = form && form.area;
    if (form && form.area) {
      let match = str.match(/\(\d*?\)/g);
      form.pinCode = match && match?.[0].replace(/[\(\)']+/g, "");
      console.log("match", match && match?.[0]);
    }
    if (form && form.area == null) {
      form.pinCode = "";
      form.cityTownVillage = "";
      form.district = "";
      this.setState({
        form,
      });
    }
    console.log("fre", form[name]);
    this.setState({
      form,
    });
    this.fetchtehsildistrictstatefromvillage();
    this.AllProductSelectionDropDown();
    //if (form && form.area != "Others" && RoleData != "FRANCHISE") {
    //  this.GetStateAndBranchFromArea();
    //}
    if (form) {
      // && form.area == "Others") {
      this.BranchAndStateByEmployeeId();
    }
  };

  fetchtehsildistrictstatefromvillage = () => {
    let { form } = this.state;
    let objBody = {
      villageName: form.area,
    };
    findtehsildistrictstate(objBody).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 2000,
        // });
        return;
      } else {
        console.log("res.dataaaa", res?.data?.data[0]);
        form.cityTownVillage = res?.data?.data?.[0]?.tehsil;
        form.district = res?.data?.data?.[0].district;
        this.setState({
          form,
        });
      }
    });
  };

  onInputValidate = (name, error = undefined) => {
    let { form, selectedSpokeError } = this.state;
    if (error !== undefined || error !== null || error !== "") {
      selectedSpokeError = error;
    }
    this.setState({
      selectedSpokeError: selectedSpokeError,
    });
    // console.log("error", error)
  };

  onFormChange = (name, value, error = undefined) => {
    let { form } = this.state;
    form[name] = value;
    // if (error !== undefined) {
    //   let { errors } = form;
    //   errors[name] = error;
    // }
    this.setState({
      form,
    });

    if (name == "mobileNumber1" && value != "") {
      let indianMobilenumber = /^[6789]\d{9}$/;
      if ((value && value.length < 10) || !value.match(indianMobilenumber) || value[0] <= "5") {
        form.errors.mobileNumber1 = true;
        form.errors.mobileNumber1 = "Please enter valid mobile number";
        this.setState({ form });
      } else {
        form.errors.mobileNumber1 = false;
        form.errors.mobileNumber1 = "";
        this.setState({ form });
      }
    }

    if (name == "pinCode" && value !== "") {
      if (value && value.length < 6) {
        form.errors.pinCode = true;
        form.errors.pinCode = "Please enter valid Pincode";
        this.setState({ form });
      } else {
        form.errors.pinCode = false;
        form.errors.pinCode = "";
        this.setState({ form });
      }
    }

    if (name == "requestedLoanAmount" && value !== "") {
      if (value == 0) {
        form.errors.loanamt = true;
        form.errors.loanamt = "Loan amount should be non zero";
        this.setState({ form });
      } else {
        form.errors.loanamt = false;
        form.errors.loanamt = "";
        this.setState({ form });
      }
    }
  };

  saveorupdate = () => {
    let {
      form,
      isThisWhatsAppNo,
      loading,
      leadIdforCC,
      updateIdforCC,
      createdUserId,
      // leadCreatedDate,
      leadGenerator,
      isLeadCreatedByFranchisee,
      villageMasterId,
      isOthersFlag,
      OthersData,
      leadFollowUpDate,
      campaignMedium,
      campaignName,
    } = this.state;
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let RoleData = JSON.parse(localStorage.getItem("Role")); // Get the role of the logged in user from local storage
    let FlagForLead =
      RoleData == "FRANCHISE" || (RoleData == "Call Centre" && form?.productSelection == "Farm Implement Leasing")
        ? true
        : false;
    let obj = OthersData;
    obj.area = form?.area;
    obj.isThisWhatsAppNo1 = isThisWhatsAppNo;
    obj.employeeId = leadData && leadData.leadEmployeeId;
    // obj.isLeadCreatedByFranchisee = isLeadCreatedByFranchisee;
    obj.isLeadCreatedByFranchisee = FlagForLead;
    obj.id = updateIdforCC;
    obj.leadId = leadIdforCC;
    obj.createdUserId = createdUserId;
    // obj.leadCreatedDate = leadCreatedDate;
    obj.leadGenerator = leadGenerator;
    obj.villageMasterId = villageMasterId;
    obj.isOthersFlag = isOthersFlag;
    obj.leadFollowUpDate = form.leadFollowUpDate;
    obj.isThisWhatsAppNo1 = form.isThisWhatsAppNo1;
    obj.productSelection = form.productSelection;
    obj.campaignMedium = form.campaignMedium;
    obj.campaignName = form.campaignName;
    obj.requestedLoanAmount = form.requestedLoanAmount;
    obj.productRequiredBy = form.productRequiredBy;
    obj.pinCode = form.pinCode;
    obj.customerName = form.customerName;
    obj.mobileNumber1 = form.mobileNumber1;
    obj.farmService = form.farmService;
    obj.productType = form.productType;
    obj.crop = form.crop;
    obj.landArea = form.landArea;
    obj.branch = form.branch;
    obj.state = form.state;
    obj.cityTownVillage = form.cityTownVillage;
    obj.district = form.district;
    obj.entityType = form.entityType;
    this.setState({ loading: true });
    saveOrUpdateLeadDetails(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
      } else {
        toast.success(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        this.props.history.push(`${public_url.Leadmodule}`);
      }
    });
  };

  onProductChange = (name, value, error) => {
    this.setState({
      // campaignValues: [],
    });
    let { form, campaignMediumValues, campaignNameValues } = this.state;
    form[name] = value;
    campaignMediumValues = [];
    campaignNameValues = [];
    form.campaignMedium = "NA";
    form.campaignName = "NA";
    console.log("fre", form[name]);
    this.setState(
      {
        form,
        campaignMediumValues,
        campaignNameValues,
      },
      this.AllCampaignMediumDropDown(value)
    );
    this.clearAll();
  };
  onCampaignMediumChange = (name, value, error) => {
    let { form, campaignNameValues } = this.state;

    form.campaignMedium = value;

    this.setState({
      form,
    });

    if (value !== "NA") {
      this.AllCampaignNamesDropdown(value);
    } else {
      campaignNameValues = [];
      campaignNameValues.push({ campaignNameDescription: "NA", id: 0, campaignName: "NA", campaignMedium: "NA" });
      this.setState({ campaignNameValues });
    }
  };

  onCampaignNameChange = (name, value, error) => {
    let { form } = this.state;
    form.campaignName = value;
    this.setState({
      form,
    });
  };

  clearAll = () => {
    let { form } = this.state;
    form.farmService = "";
    form.landArea = "";
    form.crop = "";
    form.requestedLoanAmount = "";
    form.productType = "";
    form.productRequiredBy = "";
    this.setState({ form });
  };

  DataByLeadId = () => {
    let { form } = this.state;
    let RoleData = JSON.parse(localStorage.getItem("Role")); // Get the role of the logged in user from local storage
    let { match } = this.props;
    let obj = {
      leadId: match.params.leadId,
    };
    getDataByLeadId(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });

        console.log("data...123", res.data.data);
        form.area = res?.data?.data?.area;
        form.customerName = res?.data?.data?.customerName;
        form.mobileNumber1 = res?.data?.data?.mobileNumber1;
        form.pinCode = res?.data?.data?.pinCode;
        form.productSelection = res?.data?.data?.productSelection;
        form.remarks = res?.data?.data?.remarks;
        form.farmService = res?.data?.data?.farmService;
        form.crop = res?.data?.data?.crop;
        form.landArea = res?.data?.data?.landArea;
        form.leadFollowUpDate = res?.data?.data?.leadFollowUpDate;
        form.requestedLoanAmount = res?.data?.data?.requestedLoanAmount;
        form.productType = res?.data?.data?.productType;
        form.campaignMedium = res?.data?.data?.campaignMedium;
        form.campaignName = res?.data?.data?.campaignName;
        form.productRequiredBy = res?.data?.data?.productRequiredBy;
        form.customerOccupation = res?.data?.data?.customerOccupation;
        form.firstWorkingMember = res?.data?.data?.firstWorkingMember;
        form.firstMemberOccupation = res?.data?.data?.firstMemberOccupation;
        form.secondWorkingmember = res?.data?.data?.secondWorkingmember;
        form.secondMemberOccupation = res?.data?.data?.secondMemberOccupation;
        form.workPlaceVillage = res?.data?.data?.workPlaceVillage;
        form.workPlacePincode = res?.data?.data?.workPlacePincode;
        form.firstExistingLoans = res?.data?.data?.firstExistingLoans;
        form.firstExistingLoanEmi = res?.data?.data?.firstExistingLoanEmi;
        form.secondExistingLoans = res?.data?.data?.secondExistingLoans;
        form.secondExistingLoanEmi = res?.data?.data?.secondExistingLoanEmi;
        this.AllCampaignMediumDropDown(form.productSelection);
        form.campaignMedium && this.AllCampaignNamesDropdown(form.campaignMedium);
        this.setState({
          OthersData: res?.data?.data,
          form,
          status: res?.data?.data?.status,
          isThisWhatsAppNo: res.data && res.data.data.isThisWhatsAppNo1,
          // isLeadCreatedByFranchisee: res?.data?.data?.isLeadCreatedByFranchisee,
          leadIdforCC: res?.data?.data?.leadId,
          updateIdforCC: res?.data?.data?.id,
          createdUserId: res?.data?.data?.createdUserId,
          // leadCreatedDate: res?.data?.data?.leadCreatedDate,
          leadGenerator: res?.data?.data?.leadGenerator,
          isLeadCreatedByFranchisee: res?.data?.data?.isLeadCreatedByFranchisee,
          villageMasterId: res?.data?.data?.villageMasterId,
          isOthersFlag: res?.data?.data?.isOthersFlag,
          leadFollowUpDate: res?.data?.data?.leadFollowUpDate,
        });
      }
      // this.setState({
      //if (RoleData != "FRANCHISE") {
      //  this.GetStateAndBranchFromArea();
      //}
    });
  };

  render() {
    let {
      form,
      productDropdown,
      areaDropdown,
      isThisWhatsAppNo,
      farmDropdown,
      loading,
      status,
      selectedSpokeError,
      requestedLoanAmount,
      productType,
      CDProductDropdown,
      loaderForArea,
      show,
      rejectDropdown,
      rejectionRemark,
      otherRemark,
      campaignMediumValues,
      campaignNameValues,
    } = this.state;

    let { productRequiredBy, remarks, campaignMedium, campaignName } = form;

    if (campaignMedium === null || campaignMedium === "") {
      campaignMedium = "NA";
    }
    if (campaignName === null || campaignName === "") {
      campaignName = "NA";
    }

    let RoleData = JSON.parse(localStorage.getItem("Role")); // Get the role of the logged in user from local storage
    let { match } = this.props;
    let disablesave;
    if (
      (form && form.errors && form?.errors?.mobileNumber1 == "Please enter valid mobile number") ||
      form?.errors?.pinCode == "Please enter valid Pincode" ||
      form?.errors?.loanamt == "Loan amount should be non zero"
    ) {
      disablesave = true;
    } else {
      disablesave = false;
    }

    return (
      <React.Fragment>
        <BreadCrumbs
          name1={"Lead List"}
          name2={"Update Lead"}
          url1={`${public_url.Leadmodule}`}
          url2={`${public_url.update_lead_cc}/${match.params.leadId}`}
        />
        <section className="px-2 px-md-5 pt-4 pb-5 dashboard_div bg_l-secondary">
          <div className="">
            <div className="d-flex justify-content-start align-items-center">
              <section className="py-4 position-relative bg_l-secondary w-100">
                <div className="pb-5 bg-white">
                  <div className="container">
                    <div className="row disbursment">
                      <div className="col-md-6 col-sm-6 col-lg-1 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">Spoke {<i className="text-danger">*</i>}</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-3 mt-4 mr-auto">
                        <div class="select">
                          {/* new select */}
                          {loaderForArea ? (
                            <React.Fragment>
                              <i class="fa fa-spinner fa-spin ml-5 gTextPrimary fw-500"></i>
                              <span className="ml-2 fs-18 gTextPrimary fw-500">Please wait...</span>
                            </React.Fragment>
                          ) : (
                            <Select
                              className="w-100 fs-12 create-lead-form-select"
                              options={areaDropdown}
                              value={form && form.area}
                              title="area"
                              name="area"
                              onChangeFunc={(name, value, error) => {
                                this.onBranchChange(name, value, error);
                              }}
                              isClearable={true}
                              error={selectedSpokeError}
                              isReq={true}
                              valueKey="villageCode"
                              labelKey="villageName"
                              placeholder="Select area"
                              disabled={
                                (RoleData == "Call Centre" && status == "Lead creation in Progress" ? true : false) ||
                                (RoleData != "Call Centre" && status == "Lead sent to CC")
                                  ? true
                                  : false
                              }
                            />
                          )}
                          {/* new select end */}
                          {/* <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={areaDropdown}
                            value={area}
                            title="area"
                            name="area"
                            onChangeFunc={(name, value, error) => {
                              this.onFormChange(name, value, error);
                            }}
                            //   onBlur={() => { }}
                            isReq={true}
                            // disabled={true}
                            valueKey="villageCode"
                            labelKey="villageName"
                            placeholder="Select Spoke"
                          /> */}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-1 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">Branch</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-3  mt-4">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Branch"
                          name="branch"
                          value={form?.branch}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          // maxLength={16}
                          disabled={true}
                          isReq={true}
                          // reqType="alphaNumeric"
                          title="branch"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-1 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">State</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-3 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="State"
                          name="state"
                          value={form?.state}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          // maxLength={16}
                          disabled={true}
                          isReq={true}
                          // reqType="alphaNumeric"
                          title="state"
                        />
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <hr className="bg_lightblue border-0 h-1px mt-0" />
                    </div>
                    <div className="row disbursment mt-2">
                      <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">Entity Type</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Entity Type"
                          name="entityType"
                          value={form?.entityType}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          // maxLength={16}
                          // disabled={}
                          isReq={true}
                          disabled={true}
                          // reqType="alphaNumeric"
                          title="entityType"
                        />
                      </div>
                      <div className=" col-lg-6"></div>
                      <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">
                          Customer Name {<i className="text-danger">*</i>}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-5 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Customer Name"
                          name="customerName"
                          value={form && form.customerName}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={100}
                          // disabled={}
                          isReq={true}
                          reqType="onlyAlphbate"
                          // reqType="alphaNumeric"
                          title="customerName"
                          disabled={
                            (RoleData == "Call Centre" && status == "Lead creation in Progress" ? true : false) ||
                            (RoleData != "Call Centre" && status == "Lead sent to CC")
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className=" col-lg-5"></div>
                      <div className="col-md-6 col-sm-6 col-lg-2 pb-4 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">
                          Mobile No. 1 {<i className="text-danger">*</i>}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto mb-0">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Mobile No. 1"
                          name="mobileNumber1"
                          value={form?.mobileNumber1}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={10}
                          error={form && form.errors && form.errors.mobileNumber1}
                          // disabled={}
                          isReq={true}
                          reqType="mobile10"
                          title="mobileNumber1"
                          disabled={
                            (RoleData == "Call Centre" && status == "Lead creation in Progress" ? true : false) ||
                            (RoleData != "Call Centre" && status == "Lead sent to CC")
                              ? true
                              : false
                          }
                        />
                        {/* <div style={{ color: "red", fontSize: "12px" }}>
                          {form && form.errors && form.errors.mobileNumber1}
                        </div> */}
                      </div>
                      <div className="col-md-6 col-lg-2 col-sm-6 align-self-center">
                        <span className="main gTextPrimary ml-4 checkboxText">Is this WhatsApp No?</span>
                      </div>
                      <div className="col-md-6 col-lg-1 col-sm-6">
                        <label className="main styleGreen gTextPrimary my-lg-5">
                          <input
                            type="checkbox"
                            name="isThisWhatsAppNo"
                            value={form?.isThisWhatsAppNo}
                            checked={isThisWhatsAppNo}
                            onChange={this.handleCheckBoxChange}
                            disabled={
                              (RoleData == "Call Centre" && status == "Lead creation in Progress" ? true : false) ||
                              (RoleData != "Call Centre" && status == "Lead sent to CC")
                                ? true
                                : false
                            }
                          />
                          <span className="geekmark"></span>
                        </label>
                      </div>
                      <div className=" col-lg-3"></div>
                      <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">
                          Pin Code {<i className="text-danger">*</i>}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4  mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Pin Code"
                          name="pinCode"
                          value={form?.pinCode}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          error={form && form.errors && form.errors.pinCode}
                          maxLength={6}
                          isReq={true}
                          reqType="number"
                          title="pinCode"
                          // disabled={
                          //   (RoleData == "Call Centre" &&
                          //   status == "Lead creation in Progress"
                          //     ? true
                          //     : false) ||
                          //   (RoleData != "Call Centre" &&
                          //     status == "Lead sent to CC")
                          //     ? true
                          //     : false
                          // }
                          disabled={true}
                        />
                      </div>
                      <div className=" col-lg-6"></div>
                      <div className="col-md-6 col-sm-6 col-lg-2 mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">
                          Product Selection {<i className="text-danger">*</i>}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-3 mr-auto">
                        <div class="select">
                          {/* new product start */}

                          <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={productDropdown}
                            value={form?.productSelection}
                            title="productSelection"
                            name="productSelection"
                            onChangeFunc={(name, value, error) => {
                              this.onProductChange(name, value, error);
                            }}
                            isClearable={true}
                            //  error={selectedSpokeError}
                            isReq={true}
                            valueKey="productdescription"
                            labelKey="productdescription"
                            placeholder="Select Product"
                            disabled={
                              (RoleData == "Call Centre" && status == "Lead creation in Progress" ? true : false) ||
                              (RoleData != "Call Centre" && status == "Lead sent to CC")
                                ? true
                                : false
                            }
                          />

                          {/* new prod end */}
                          {/* <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={productDropdown}
                            value={productSelection}
                            title="productSelection"
                            name="productSelection"
                            // onChangeFunc={(name, value, error) => {
                            //   this.onFormChange(name, value, error);
                            // }}
                            //   onBlur={() => { }}
                            isReq={true}
                            valueKey="productSelection"
                            labelKey="productSelection"
                            placeholder="Select Product"
                          /> */}
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2 mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary ml-5 fw-500">
                          {form?.productSelection == "Housing Loans" ||
                          form?.productSelection == "Farm Loans" ||
                          form?.productSelection == "Business Loans" ||
                          form?.productSelection == "Personal Loans" ||
                          form?.productSelection == "Gold Loans" ||
                          form?.productSelection == "Gold @Home" ? (
                            <>
                              Requested loan amount <i className="text-danger">*</i>
                            </>
                          ) : form?.productSelection == "Farm Implement Leasing" ? (
                            <>
                              Farm Service <i className="text-danger">*</i>
                            </>
                          ) : form?.productSelection == "Consumer Durables" ? (
                            <>
                              Product Type <i className="text-danger">*</i>{" "}
                            </>
                          ) : null}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-3 mr-auto">
                        <div class="select">
                          {/* new frm start */}
                          {form?.productSelection == "Housing Loans" ||
                          form?.productSelection == "Farm Loans" ||
                          form?.productSelection == "Business Loans" ||
                          form?.productSelection == "Personal Loans" ||
                          form?.productSelection == "Gold Loans" ||
                          form?.productSelection == "Gold @Home" ? (
                            <Input
                              className="form-control text-center fs-16"
                              placeholder="Enter Requested Loan Amount"
                              name="requestedLoanAmount"
                              value={
                                form?.requestedLoanAmount && formatIndianCurrency(form?.requestedLoanAmount).toString()
                              }
                              onChangeFunc={(name, value, error) => {
                                value = replaceComma(value);
                                if (checkIfNotDecimal(value)) {
                                  this.onFormChange(name, value, error);
                                }
                              }}
                              error={form && form.errors && form.errors.loanamt}
                              maxLength="9"
                              // disabled={}
                              isReq={true}
                              reqType="number"
                              title="requestedLoanAmount"
                              disabled={
                                (RoleData == "Call Centre" && status == "Lead creation in Progress" ? true : false) ||
                                (RoleData != "Call Centre" && status == "Lead sent to CC")
                                  ? true
                                  : false
                              }
                            />
                          ) : form?.productSelection == "Farm Implement Leasing" ? (
                            <Select
                              className="w-100 fs-12 create-lead-form-select"
                              options={farmDropdown}
                              value={form?.farmService}
                              title="farmService"
                              name="farmService"
                              onChangeFunc={(name, value, error) => {
                                this.onInputChange(name, value, error);
                              }}
                              isClearable={true}
                              //  error={selectedSpokeError}
                              isReq={true}
                              valueKey="serviceName"
                              labelKey="serviceName"
                              placeholder="Select Farm Service"
                              disabled={
                                (RoleData == "Call Centre" && status == "Lead creation in Progress" ? true : false) ||
                                (RoleData != "Call Centre" && status == "Lead sent to CC")
                                  ? true
                                  : false
                              }
                            />
                          ) : form?.productSelection == "Consumer Durables" ? (
                            <Select
                              className="w-100 fs-12 create-lead-form-select"
                              options={CDProductDropdown}
                              value={form?.productType}
                              title="productType"
                              name="productType"
                              onChangeFunc={(name, value, error) => {
                                this.onInputChange(name, value, error);
                              }}
                              isClearable={true}
                              //  error={selectedSpokeError}
                              isReq={true}
                              valueKey="productName"
                              labelKey="productName"
                              placeholder="Select Product Type"
                              disabled={
                                (RoleData == "Call Centre" && status == "Lead creation in Progress" ? true : false) ||
                                (RoleData != "Call Centre" && status == "Lead sent to CC")
                                  ? true
                                  : false
                              }
                            />
                          ) : null}
                          {/* new farm end */}
                          {/* <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={farmDropdown}
                            value={farmService}
                            title="farmService"
                            name="farmService"
                            onChangeFunc={(name, value, error) => {
                              this.onFormChange(name, value, error);
                            }}
                            //   onBlur={() => { }}
                            isReq={true}
                            valueKey="serviceName"
                            labelKey="serviceName"
                            placeholder="Select farmService"
                          /> */}
                        </div>
                      </div>
                      {/*  */}
                      {form?.productSelection == "Housing Loans" ||
                      form?.productSelection == "Farm Loans" ||
                      form?.productSelection == "Business Loans" ||
                      form?.productSelection == "Personal Loans" ||
                      form?.productSelection == "Gold Loans" ? null : form?.productSelection ==
                        "Farm Implement Leasing" ? (
                        <>
                          <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                            <label class="fs-14 mb-0 gTextPrimary fw-500">
                              Crop {<i className="text-danger">*</i>}
                            </label>
                          </div>
                          <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                            <Input
                              className="form-control text-center fs-16"
                              placeholder="Crop"
                              name="crop"
                              value={form?.crop}
                              onChangeFunc={(name, value, error) => {
                                //   value = replaceComma(value);
                                this.onFormChange(name, value, error);
                              }}
                              maxLength={50}
                              // disabled={}
                              isReq={true}
                              reqType="onlyAlphbate"
                              title="crop"
                              disabled={
                                (RoleData == "Call Centre" && status == "Lead creation in Progress" ? true : false) ||
                                (RoleData != "Call Centre" && status == "Lead sent to CC")
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                            <label class="fs-14 mb-0 ml-5 gTextPrimary fw-500">
                              Land Area (In Acres) {<i className="text-danger">*</i>}
                            </label>
                          </div>
                          <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                            <Input
                              className="form-control text-center fs-16"
                              placeholder="Land Area (In Acres)"
                              name="landArea"
                              value={
                                form?.landArea &&
                                form?.landArea
                                  .toString()
                                  .split(".")
                                  .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                                  .join(".")
                              }
                              onChangeFunc={(name, value, error) => {
                                if (value == "" || (Math.ceil(value) <= 999 && parseInt(value) >= 0)) {
                                  value = value
                                    .toString()
                                    .split(".")
                                    .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                                    .join(".");
                                  this.onFormChange(name, value, error);
                                }
                                // this.onFormChange(name, value, error);
                              }}
                              // maxLength={16}
                              // disabled={}
                              isReq={true}
                              reqType="number"
                              title="landArea"
                              disabled={
                                (RoleData == "Call Centre" && status == "Lead creation in Progress" ? true : false) ||
                                (RoleData != "Call Centre" && status == "Lead sent to CC")
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </>
                      ) : null}

                      <div className="col-md-6 col-sm-6 col-lg-2 mt-3  mb-2 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">
                          Campaign Medium {<i className="text-danger">*</i>}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-3 mr-auto">
                        <div class="select">
                          {/* new product start */}
                          <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={campaignMediumValues}
                            value={campaignMedium}
                            title="camapignMedium"
                            name="camapignMedium"
                            onChangeFunc={(name, value, error) => {
                              this.onCampaignMediumChange(name, value, error);
                            }}
                            isClearable={true}
                            valueKey="campaignMedium"
                            labelKey="campaignMedium"
                            placeholder="Select Campaign Medium"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">
                          Campaign Name {<i className="text-danger">*</i>}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <div class="select">
                          {/* new product start */}
                          <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={campaignNameValues}
                            value={campaignName}
                            title="campaignName"
                            name="campaignName"
                            onChangeFunc={(name, value, error) => {
                              this.onCampaignNameChange(name, value, error);
                            }}
                            isClearable={true}
                            valueKey="campaignName"
                            labelKey="campaignName"
                            placeholder="Select Campaign Name"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">
                          Lead Follow-up Date{<i className="text-danger">*</i>}
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Lead Follow-up Date"
                          name="leadFollowUpDate"
                          value={moment(form?.leadFollowUpDate).format("YYYY-MM-DD")}
                          onChangeFunc={(name, value, error) => {
                            this.onFormChange(name, value, error);
                          }}
                          type="date"
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          // maxLength={16}
                          // disabled={}
                          isReq={true}
                          // reqType="number"
                          title="leadFollowUpDate"
                          disabled={
                            (RoleData == "Call Centre" && status == "Lead creation in Progress" ? true : false) ||
                            (RoleData != "Call Centre" && status == "Lead sent to CC")
                              ? true
                              : false
                          }
                        />
                      </div>
                      {/*  */}
                      {form?.productSelection == "Farm Implement Leasing" ? null : (
                        <>
                          <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                            <label class="fs-14 mb-0 gTextPrimary fw-500">
                              Product Required By {<i className="text-danger">*</i>}
                            </label>
                          </div>
                          <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                            <div class="select">
                              {/* new product start */}

                              <Select
                                className="w-100 fs-12 create-lead-form-select"
                                options={productValue}
                                value={productRequiredBy}
                                title="productRequiredBy"
                                name="productRequiredBy"
                                onChangeFunc={(name, value, error) => {
                                  this.onFormChange(name, value, error);
                                }}
                                isClearable={true}
                                disabled={
                                  (RoleData == "Call Centre" && status == "Lead creation in Progress" ? true : false) ||
                                  (RoleData != "Call Centre" && status == "Lead sent to CC")
                                    ? true
                                    : false
                                }
                                //  error={selectedSpokeError}
                                isReq={true}
                                // valueKey="productdescription"
                                // labelKey="productdescription"
                                placeholder="Select Product required by"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Remarks</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Remarks"
                          name="remarks"
                          value={form?.remarks}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="remarks"
                        />
                      </div>

                      <div className="col-12 mt-4">
                        <hr className="bg_lightblue border-0 h-1px mt-0" />
                      </div>
                      <div className="col-12 mt-4">
                        <h3 class="fs-30 mb-0 gTextPrimary fw-500 ml-2">Additional Fields</h3>
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Inquiry Date</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="inquiryDate"
                          name="inquiryDate"
                          // value={moment(inquiryDate).format("YYYY-MM-DD")}
                          // onChangeFunc={(name, value, error) => {
                          //   this.onFormChange(name, value, error);
                          // }}
                          // type="date"
                          // min={moment(new Date()).format("YYYY-MM-DD")}
                          // max={moment(new Date()).format("YYYY-MM-DD")}
                          // maxLength={16}
                          disabled={true}
                          isReq={true}
                          // reqType="number"
                          title="inquiryDate"
                        />
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Inquiry Source</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Inquiry Source"
                          name="inquirySource"
                          value={form.inquirySource}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          disabled={true}
                          reqType="onlyAlphbate"
                          title="inquirySource"
                        />
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Customer's Occupation</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Customer's Occupation"
                          name="customerOccupation"
                          value={form.customerOccupation}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="customerOccupation"
                        />
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2"> Final Calling date by CC Agents</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Final Calling date by CC Agents"
                          name="ccLastCall"
                          // value={moment(ccLastCall).format("YYYY-MM-DD")}
                          // onChangeFunc={(name, value, error) => {
                          //   this.onFormChange(name, value, error);
                          // }}
                          // type="date"
                          // min={moment(new Date()).format("YYYY-MM-DD")}
                          // max={moment(new Date()).format("YYYY-MM-DD")}
                          // maxLength={16}
                          disabled={true}
                          isReq={true}
                          // reqType="number"
                          title="ccLastCall"
                        />
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2"> First Working Member</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder=" First Working Member"
                          name="firstWorkingMember"
                          value={form.firstWorkingMember}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="firstWorkingMember"
                        />
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2"> First Working Member Occuption</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder=" first Working Member Occuption"
                          name="firstMemberOccupation"
                          value={form.firstMemberOccupation}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}
                          reqType="onlyAlphbate"
                          title="firstMemberOccupation"
                        />
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2"> Second Working Member</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder=" second Working Member"
                          name="secondWorkingmember"
                          value={form.secondWorkingmember}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="secondWorkingmember"
                        />
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2"> Second Member Occupation</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder=" second Member Occupation"
                          name="secondMemberOccupation"
                          value={form.secondMemberOccupation}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}

                          reqType="onlyAlphbate"
                          title="secondMemberOccupation"
                        />
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Work Place Village</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Work Place Village"
                          name="workPlaceVillage"
                          value={form.workPlaceVillage}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // disabled={}
                          reqType="onlyAlphbate"
                          title="workPlaceVillage"
                        />
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2"> Work Place Pincode</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Work Place Pincode"
                          name="workPlacePincode"
                          value={form.workPlacePincode}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={6}
                          // // error={msgpincode}
                          isReq={true}
                          reqType="number"
                          title="workPlacePincode"
                        />
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">First Existing Loans</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="First Existing Loans"
                          name="firstExistingLoans"
                          value={form.firstExistingLoans}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // // disabled={}
                          reqType="onlyAlphbate"
                          title="firstExistingLoans"
                        />
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">First Existing Loan EMI</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="first Existing Loan Emi"
                          name="firstExistingLoanEmi "
                          value={form.firstExistingLoanEmi}
                          onChangeFunc={(name, value, error) => {
                            value = replaceComma(value);
                            if (checkIfNotDecimal(value)) {
                              this.onFormChange(name, value, error);
                            }
                          }}
                          // // error={firstExistingLoanEmi}
                          maxLength="9"
                          isReq={true}
                          reqType="number"
                          title="firstExistingLoanEmi "
                        />
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Second Existing Loans</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="second Existing Loans"
                          name="secondExistingLoans"
                          value={form.secondExistingLoans}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          maxLength={500}
                          // // disabled={}
                          reqType="onlyAlphbate"
                          title="secondExistingLoans"
                        />
                      </div>

                      <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Second Existing Loan EMI</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="second Existing Loan EMI"
                          name="secondExistingLoanEmi "
                          value={form.secondExistingLoanEmi}
                          onChangeFunc={(name, value, error) => {
                            value = replaceComma(value);
                            if (checkIfNotDecimal(value)) {
                              this.onFormChange(name, value, error);
                            }
                          }}
                          // error={firstExistingLoanEmi}
                          maxLength="9"
                          isReq={true}
                          reqType="number"
                          title="secondExistingLoanEmi "
                        />
                      </div>

                      {/* end */}
                    </div>

                    {/* save */}
                    <div className="row justify-content-end col-md-12 col-sm-12 mt-2 col-lg-12">
                      <div className="row">
                        <button
                          //   disabled={}
                          className={`btn btn-secondary btn-rounded ls-1 cursor-pointer mr-4 fs-16 btn-green`}
                          onClick={() => this.props.history.push(`${public_url.Leadmodule}`)}
                        >
                          Cancel
                        </button>
                        {console.log(
                          getAccessId() === 1,
                          form.customerName == "",
                          form.pinCode == "",
                          form.productSelection == "",
                          form.farmService == "" && form.requestedLoanAmount == "" && form.productType == "",
                          form.farmService == null && form.requestedLoanAmount == null && form.productType == null,
                          form.productSelection == null,
                          form.productSelection == "Farm Implement Leasing"
                            ? form.crop == "" ||
                              form.crop == null ||
                              form.landArea == null ||
                              form.landArea == "" ||
                              form.farmService == null ||
                              form.farmService == ""
                              ? true
                              : false
                            : false,
                          form.leadFollowUpDate == "",
                          form.area == "Others",
                          form.area == null,
                          form.mobileNumber1 == "",
                          disablesave,
                          loading,
                          RoleData == "Call Centre" && status == "Lead creation in Progress" ? true : false,
                          RoleData != "Call Centre" && status == "Lead sent to CC" ? true : false,
                          form?.productSelection !== "Farm Implement Leasing" && productRequiredBy == ""
                        )}
                        <button
                          disabled={
                            getAccessId() === 1 ||
                            form.customerName == "" ||
                            form.pinCode == "" ||
                            form.productSelection == "" ||
                            (form.farmService == "" && form.requestedLoanAmount == "" && form.productType == "") ||
                            (form.farmService == null &&
                              form.requestedLoanAmount == null &&
                              form.productType == null) ||
                            form.productSelection == null ||
                            (form.productSelection == "Farm Implement Leasing"
                              ? form.crop == "" ||
                                form.crop == null ||
                                form.landArea == null ||
                                form.landArea == "" ||
                                form.farmService == null ||
                                form.farmService == ""
                                ? true
                                : false
                              : false) ||
                            form.leadFollowUpDate == "" ||
                            form.area == "Others" ||
                            form.area == null ||
                            form.mobileNumber1 == "" ||
                            disablesave ||
                            loading ||
                            (RoleData == "Call Centre" && status == "Lead creation in Progress" ? true : false) ||
                            (RoleData != "Call Centre" && status == "Lead sent to CC")
                              ? true
                              : false ||
                                (form?.productSelection !== "Farm Implement Leasing" && productRequiredBy == "")
                          }
                          className={`btn btn-secondary btn-rounded ls-1 cursor-pointer mr-4 fs-16 btn-green`}
                          onClick={this.saveorupdate}
                        >
                          {loading ? (
                            <React.Fragment>
                              <i class="fa fa-spinner fa-spin"></i>
                              Saving...
                            </React.Fragment>
                          ) : (
                            "Save"
                          )}
                        </button>
                        <button
                          disabled={getAccessId() === 1}
                          className={`btn btn-secondary btn-rounded ls-1 cursor-pointer  fs-16 btn-green`}
                          onClick={this.handleShow}
                        >
                          Reject Lead
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* modal */}
                  <Modal show={show} onHide={this.handleClose} animation={false}>
                    <Modal.Header closeButton>
                      <Modal.Title>Reject Lead</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-lg-4 mt-3 d-flex align-items-center">
                          <label class="fs-14 mb-0 gTextPrimary fw-500">Rejection Reason</label>
                          <lable className="text-danger">*</lable>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 mt-3 mr-auto">
                          <div class="select">
                            <Select
                              className="w-100 fs-12 create-lead-form-select"
                              options={rejectDropdown}
                              value={rejectionRemark}
                              title="rejectionRemark"
                              name="rejectionRemark"
                              onChangeFunc={(name, value, error) => {
                                this.onRejectChange(name, value, error);
                              }}
                              //   onBlur={() => { }}
                              isReq={true}
                              valueKey="rejectionRemark"
                              labelKey="rejectionRemark"
                              placeholder="Select Rejection Reason"
                            />
                          </div>
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-md-6 col-sm-6 col-lg-4 d-flex align-items-center">
                          <label class="fs-14 mb-0  gTextPrimary fw-500">Rejection Remarks</label>
                          <lable className="text-danger">*</lable>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                          <Input
                            className="form-control text-center fs-16"
                            placeholder="Rejection Remarks"
                            name="otherRemark"
                            value={otherRemark}
                            onChangeFunc={(name, value, error) => {
                              //   value = replaceComma(value);
                              this.onRejectChange(name, value, error);
                            }}
                            // maxLength={16}
                            // disabled={}
                            isReq={true}
                            // reqType="alphaNumeric"
                            title="otherRemark"
                          />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.handleClose}>
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        onClick={this.handleReject}
                        disabled={!this.state.otherRemark || !this.state.rejectionRemark}
                      >
                        Reject Lead
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </section>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default UpdateLeadForCC;
