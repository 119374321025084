import { post, get, postM, getFile } from "../httpInterceptor";
import { API_URL } from "../Config";

// API for save or update lead details

export const saveOrUpdateLeadDetails = (objBody = {}) => {
  return post(`/leaddetails/saveOrUpdateLeadDetails`, objBody, false).then(res => {
    console.log("saveOrUpdateLeadDetails", res);
    return res;
  });
};

// API for save or approve lead details

export const saveOrApproveLeadDetails = (objBody = {}) => {
  return post(`/insurance/approve`, objBody, false).then(res => {
    console.log("saveOrUpdateLeadDetails", res);
    return res;
  });
};
// API for save or update lead document

export const saveOrUpdateLeadDocument = (objBody = {}, multiBody = {}) => {
  return postM(`/insurance/document`, objBody, false, true, false, multiBody).then(res => {
    console.log("saveOrUpdateLeadDocument", res);
    return res;
  });
};

// API for get proof of identity document
export const getIdentityDocument = identityDocument => {
  console.log(identityDocument);
  return getFile(`/insurance/document?fileName=${identityDocument}`, identityDocument, "blob").then(res => {
    console.log("findallunitsdropdown", res);
    return res;
  });
};
// API for get farm services by franchise code

export const getFarmServicesDropDownByFranchiseCode = (objBody = {}) => {
  return post(`/leaddetails/getFarmServicesDropDownByFranchiseCode`, objBody, false).then(res => {
    console.log("getFarmServicesDropDownByFranchiseCode", res);
    return res;
  });
};

// /leaddetails/findAllProductSelectionDropDown
// export const findAllProductSelectionDropDown = (objBody = {}) => {
//   return get(`/leaddetails/findAllProductSelectionDropDown`).then(
//     (res) => {
//       console.log("findAllProductSelectionDropDown", res);
//       return res;
//     }
//   );
// };

// API for get all product selection values

export const findAllProductSelectionDropDown = (objBody = {}) => {
  return post(`/leaddetails/findAllProductSelectionDropDownByEmployeeId`, objBody, false).then(res => {
    console.log("findAllProductSelectionDropDownByEmployeeId", res);
    return res;
  });
};

// API for get all type of intraction dropdown values

export const findAllTypeofInteractionDropDown = () => {
  return get(`/leaddetails/findAllTypeofInteractionDropDown`).then(res => {
    console.log("findAllTypeofInteractionDropDown", res);
    return res;
  });
};

// API for get data by lead ID

export const getDataByLeadId = (objBody = {}) => {
  return post(`/leaddetails/getDataByLeadId`, objBody, false).then(res => {
    console.log("getDataByLeadId", res);
    return res;
  });
};

// API for get Area Villages Dropdown values by FranchiseCode

export const getAreaVillagesDropDownByFranchiseCode = (objBody = {}) => {
  return post(`/leaddetails/getAreaVillagesDropDownByFranchiseCode`, objBody, false).then(res => {
    console.log("getAreaVillagesDropDownByFranchiseCode", res);
    return res;
  });
};

// API for get Area Villages Dropdown values by FranchiseCode on second screen

export const getAreaVillagesDropDownByFranchiseCodeForSecondScreen = (objBody = {}) => {
  return post(`/leaddetails/getAreaVillagesDropDownByFranchiseCodeForSecondScreen`, objBody, false).then(res => {
    console.log("getAreaVillagesDropDownByFranchiseCodeForSecondScreen", res);
    return res;
  });
};

// API for get all education Dropdown values

export const findAllEducationStatusDropDown = () => {
  return get(`/leaddetails/findAllEducationStatusDropDown`).then(res => {
    console.log("findAllEducationStatusDropDown", res);
    return res;
  });
};

// API for get all rejection Dropdown values

export const findAllRejectionRemarksDropDown = () => {
  return get(`/leaddetails/findAllRejectionRemarksDropDown`).then(res => {
    console.log("findAllRejectionRemarksDropDown", res);
    return res;
  });
};

// API for get branch and state by employee ID

export const getBranchAndStateByEmployeeId = (objBody = {}) => {
  return post(`/leaddetails/getBranchAndStateByEmployeeId`, objBody, false).then(res => {
    console.log("getBranchAndStateByEmployeeId", res);
    return res;
  });
};

// API for reject lead by ID

export const rejectLeadByLeadId = (objBody = {}) => {
  return post(`/leaddetails/rejectLeadByLeadId`, objBody, false).then(res => {
    console.log("rejectLeadByLeadId", res);
    return res;
  });
};

// API for get address by pincode

export const findaddressbypincode = (objBody = {}) => {
  return post(`/sarvagram-admin/goldappraiser/findaddressbypincode`, objBody, false).then(res => {
    console.log("findaddressbypincode", res);
    return res;
  });
};

// API for get age by date of birth

export const getAgeByDateOfBirth = (objBody = {}) => {
  return post(`/leaddetails/getAgeByDateOfBirth`, objBody, false).then(res => {
    console.log("getAgeByDateOfBirth", res);
    return res;
  });
};

// API for get Farm listing

export const getFarmLeasingProductListing = (objBody = {}) => {
  return post(`/leaddetails/getFarmLeasingProductListing`, objBody, false).then(res => {
    console.log("getFarmLeasingProductListing", res);
    return res;
  });
};

// API for get farm catalogue

export const getFarmImplementCatalogue = (objBody = {}) => {
  return post(`/leaddetails/getFarmImplementCatalogue`, objBody, false).then(res => {
    console.log("getFarmImplementCatalogue", res);
    return res;
  });
};

// API for get unit dropdown values

export const findallunitsdropdown = () => {
  return get(`/order/findallunitsdropdown`).then(res => {
    console.log("findallunitsdropdown", res);
    return res;
  });
};

// API for get product Campaign dropdown values

export const findAllProductCampaignDropdown = product => {
  return get(`/leaddetails/findAllProductCampaignList/${product}`).then(res => {
    console.log("findallproductcampaigndropdown", res);
    return res;
  });
};

// API for get active campaign medium dropdown values

export const findActiveCampaignMediums = product => {
  return get(`/campaign/medium?product=${product}`).then(res => {
    console.log("findActiveCampaignMediums", res);
    return res;
  });
};

//API for get campaign name dropdown values

export const findActiveCampaignNames = mediumName => {
  return get(`/campaign/name?mediumName=${mediumName}`).then(res => {
    console.log("findActiveCampaignNames", res);
    return res;
  });
};

// API for get KYC document dropdown values

export const selectKycDocumentDropdown = () => {
  return get(`/order/selectKycDocumentDropdown`).then(res => {
    console.log("selectKycDocumentDropdown", res);
    return res;
  });
};

// API for upload KYC document

export const uploadKycDocument = (objBody = {}) => {
  return post("/order/uploadKycDocument", objBody, false).then(res => {
    console.log("uploadKycDocument", res);
    return res;
  });
};

// API for delete KYC document by ID

export const deleteKycDocumentById = (objBody = {}) => {
  return post("/order/deleteKycDocumentById", objBody, false).then(res => {
    console.log("deleteKycDocumentById", res);
    return res;
  });
};

// API for get rate from master

export const getratefromservicemaster = (objBody = {}) => {
  return post(`/sarvagram-order/farmorder/getratefromservicemaster`, objBody, false).then(res => {
    console.log("getratefromservicemaster", res);
    return res;
  });
};

// API for save or update the order details

export const saveOrUpdateOrderDetails = (objBody = {}) => {
  return post(`/order/saveOrUpdateOrderDetails`, objBody, false).then(res => {
    console.log("saveOrUpdateOrderDetails", res);
    return res;
  });
};

//lms cd product name api
export const getAllProductName = () => {
  return get(`/cdproductsapis/getAllProductName`).then(res => {
    console.log("getAllProductName", res);
    return res;
  });
};

//cd listing api
export const getCdProductListing = (objBody = {}) => {
  return post(`/cdproductsapis/getCDProductsList`, objBody, false).then(res => {
    console.log("getCdProductListing", res);
    return res;
  });
};

//cd catalouge
export const getCDProductCatalogue = (objBody = {}) => {
  return post(`/cdproductsapis/getCDProductDetailsForCatalogue`, objBody, false).then(res => {
    console.log("getCDProductCatalogue", res);
    return res;
  });
};

//save product
export const saveCdProductDetails = (objBody = {}) => {
  return post(`/cd/saveProduct`, objBody, false).then(res => {
    console.log("saveCdProductDetails", res);
    return res;
  });
};

// API for get the data by SKU code

export const findByskucode = (objBody = {}) => {
  return post(`/sarvagram-admin/cancellation/findByskucode`, objBody, false).then(res => {
    console.log("findByskucode", res);
    return res;
  });
};

//reject dropdown api
export const findAllRejectionRemarksDropDownforCDProducts = () => {
  return get(`/leaddetails/findAllRejectionRemarksDropDownforCDProducts`).then(res => {
    console.log("findAllRejectionRemarksDropDownforCDProducts", res);
    return res;
  });
};

// API for get End use by product category

export const findEndUseByProductCategory = productSelection => {
  return get(`/lms/findEndUseByProductCategory?productCategory=${productSelection}`).then(res => {
    console.log("findEndUseByProductCategory", res);
    return res;
  });
};

// API for get branch and state by spoke

export const getBranchAndStateBySpoke = (objBody = {}) => {
  return post(`/leaddetails/getBranchAndStateBySpoke`, objBody, false).then(res => {
    console.log("getBranchAndStateBySpoke", res);
    return res;
  });
};

// API for get tehsil, district and state by village

export const findtehsildistrictstate = (objBody = {}) => {
  return post(`/sarvagram-admin/villagemaster/findtehsildistrictstate`, objBody, false).then(res => {
    console.log("findtehsildistrictstate", res);
    return res;
  });
};
