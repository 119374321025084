import { get, post, postFile } from "../httpInterceptor";

import { COS_API } from "../Config";
import axios from "axios";

// API for upload lead excel file

export const uploadBulkLeadExcel = (empId, objBody = {}) => {
  return post(`/bulkupload/uploadExcel?empId=${empId}`, objBody, false).then(res => {
    console.log("uploadBulkLeadExcel", res);
    return res;
  });
};

export const downloadFileFromUtils = uploadURL => {
  axios
    .get(`${COS_API}utils/v1.0/document/download?path=${uploadURL}`, {
      headers: {
        Accept: "application/octet-stream",
      },
      responseType: "arraybuffer",
    })
    .then(res => {
      const { data } = res;
      const fileName = uploadURL.split("/").pop();
      const blob = new Blob([data], { type: "application/octet-stream" });
      let dom = document.createElement("a");
      let url = window.URL.createObjectURL(blob);
      dom.href = url;
      dom.download = decodeURI(fileName);
      dom.style.display = "none";
      document.body.appendChild(dom);
      dom.click();
      dom.parentNode.removeChild(dom);
      window.URL.revokeObjectURL(url);
    });
};

export const bulkUploadForCos = obj => {
  let leadData = localStorage.getItem("leadData") ? JSON.parse(localStorage.getItem("leadData")) : undefined;
  let token = leadData && leadData.leadAccessToken ? leadData.leadAccessToken : "";

  return axios.post(`${COS_API}cos/v1.0/bulk/uploadFile`, obj, {
    headers: {
      Authorization: `Bearer ${token}`,
      // Authorization: `Bearer ${localStorage.getItem("cosToken")}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const getCosToken = url => {
  return get("/lms/cos/token").then(res => {
    console.log(res);
  });
};

export const fetchingListOfExceptionByEmployeeId = (objBody = {}) => {
  return post(`/bulkupload/fetchingListOfExceptionByEmployeeId`, objBody, false).then(res => {
    console.log("fetchingListOfExceptionByEmployeeId", res);
    return res;
  });
};
