import React, { Component } from "react";
import BreadCrumbs from "../../Component/BreadCrumbs/index";
import { Select, Input, TextArea, File } from "../../Component/Input";
import { getAccessId } from "../../Utility/Helper";
import { cloneDeep } from "lodash";
import moment from "moment";
import { public_url, formatIndianCurrency, replaceComma, checkIfNotDecimal } from "../../Utility/Constant";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  saveOrUpdateLeadDetails,
  getFarmServicesDropDownByFranchiseCode,
  findAllProductSelectionDropDown,
  findAllTypeofInteractionDropDown,
  getDataByLeadId,
  getAreaVillagesDropDownByFranchiseCodeForSecondScreen,
  findAllEducationStatusDropDown,
  findAllRejectionRemarksDropDown,
  getBranchAndStateByEmployeeId,
  rejectLeadByLeadId,
  findaddressbypincode,
  getAgeByDateOfBirth,
  getAllProductName,
  findEndUseByProductCategory,
  getBranchAndStateBySpoke,
  findtehsildistrictstate,
  findActiveCampaignMediums,
  findActiveCampaignNames,
} from "../../Utility/Services/Leadmanage";
import { ToastContainer, toast } from "react-toastify";
import "../LMScaselist/style.css";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const productValue = [
  { value: "In this week", label: "In this week" },
  { value: "In this month", label: "In this month" },
  { value: "Next 2-3 months", label: "Next 2-3 months" },
  { value: "Next 4-6 months", label: "Next 4-6 months" },
];

const initForm = {
  area: "",
  branch: "",
  state: "",
  remarks: "",
  entityType: "Individual",
  existingCustomer: false,
  customerName: "",
  mobileNumber1: "",
  isThisWhatsAppNo1: false,
  pinCode: "",
  productSelection: "",
  campaignMedium: "NA",
  campaignName: "NA",
  farmService: "",
  requestedLoanAmount: "",
  productType: "",
  crop: "",
  landArea: "",
  leadFollowUpDate: "",
  gender: "",
  dateOfBirth: "",
  leadage: "",
  maritalStatus: "",
  educationStatus: "",
  mobileNumber2: "",
  inquiryDate: "",
  inquirySource: "",
  customerOccupation: "",
  firstWorkingMember: "",
  secondWorkingmember: "",
  secondMemberOccupation: "",
  firstMemberOccupation: "",
  familyMemberOccuption: "",
  workPlaceVillage: "",
  workPlacePincode: "",
  firstExistingLoans: "",
  secondExistingLoans: "",
  firstExistingLoanEmi: "",
  secondExistingLoanEmi: "",
  ccLastCall: "",
  isThisWhatsAppNo2: false,
  alternateNo: "",
  addLine1: "",
  addLine2: "",
  cityTownVillage: "",
  pincodeAutofetchState: "",
  district: "",
  endUse: "",
  typeOfInteraction: "",
  productRequiredBy: "",
  campaignMedium: "NA",
  campaignName: "NA",
};

const genderDropdown = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Unspecified", value: "Unspecified" },
];

const maritalStatusDropdown = [
  { label: "MARRIED", value: "MARRIED" },
  { label: "DIVORCED", value: "DIVORCED" },
  { label: "UNMARRIED", value: "UNMARRIED" },
  { label: "SEPARATED", value: "SEPARATED" },
  { label: "WIDOWER", value: "WIDOWER" },
];

export class Updatelead extends Component {
  state = {
    form: cloneDeep(initForm),
    productDropdown: [],
    farmDropdown: [],
    branchstateData: {},
    areaDropdown: [],
    educationDropdown: [],
    rejectDropdown: [],
    CDProductDropdown: [],
    isThisWhatsAppNo_1: false,
    isThisWhatsAppNo_2: false,
    show: false,
    rejectionRemark: "",
    status: "",
    otherRemark: "",
    pincodeData: [],
    interactionDropdown: [],
    leadage: "",
    msgmobileNo1: "",
    msgmobileNo2: "",
    msgalternateNo: "",
    msgpincode: "",
    loanamt: "",
    isLeadCreatedByFranchisee: false,
    productval: [],
    loading: false,
    loaderForArea: false,
    DataForTehsilAndDistrict: "",
    campaignMediumValues: [],
    campaignNameValues: [],
  };

  componentDidMount() {
    let { form } = this.state;
    let RoleData = JSON.parse(localStorage.getItem("Role")); // Get the role of the logged in user from local storage
    this.DataByLeadId();
    this.AllProductSelectionDropDown();
    this.FarmServicesDropDownByFranchiseCode();
    //if (RoleData == "FRANCHISE") {
    this.BranchAndStateByEmployeeId();
    //}
    this.AreaVillagesDropDownByFranchiseCode();
    this.AllEducationStatusDropDown();
    this.addressbypincode();
    this.fetchtehsildistrictstatefromvillage(form.area);
    this.AllRejectionRemarksDropDown();
    this.AllTypeofInteractionDropDown();
    this.allCDProducts();
    this.findByProductCategory();
    // this.calculateAge();
    this.AllCampaignMediumDropDown("All");
    this.AllCampaignNamesDropdown("NA");
  }

  AllCampaignMediumDropDown = product => {
    findActiveCampaignMediums(product).then(res => {
      console.log("res 000 ", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        console.log("error in fetching the data");
      } else {
        let campaignMediumValues = res.data.data;
        if (!campaignMediumValues) campaignMediumValues = [];
        campaignMediumValues.push({ campaignMediumDescription: "NA", id: 0, campaignMedium: "NA", productType: "All" });
        this.setState({
          campaignMediumValues,
        });
        console.log("campaignMediumValues in method: ", campaignMediumValues);
      }
    });
  };

  AllCampaignNamesDropdown = mediumName => {
    findActiveCampaignNames(mediumName).then(res => {
      console.log("res", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        console.log("error in fetching the data");
        let campaignNameValues = [];
        campaignNameValues.push({ campaignNameDescription: "NA", id: 0, campaignName: "NA", campaignMedium: "NA" });
        this.setState({
          campaignNameValues,
        });
      } else {
        let campaignNameValues = res.data.data;
        if (!campaignNameValues) campaignNameValues = [];
        campaignNameValues.push({ campaignNameDescription: "NA", id: 0, campaignName: "NA", campaignMedium: "NA" });
        this.setState({
          campaignNameValues,
        });
      }
    });
  };

  findByProductCategory = () => {
    let { form } = this.state;
    let { productSelection } = form;
    findEndUseByProductCategory(productSelection).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        this.setState({
          productval: res.data && res.data.data,
        });
      }
    });
  };

  FarmServicesDropDownByFranchiseCode = () => {
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let obj = {
      franchiseCode: leadData && leadData.leadEmployeeId,
    };

    getFarmServicesDropDownByFranchiseCode(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          farmDropdown: res.data && res.data.data,
        });
        //this.DataByLeadId();
      }
    });
  };

  allCDProducts = () => {
    getAllProductName().then(res => {
      console.log("res", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          CDProductDropdown: res.data && res.data.data,
        });
      }
    });
  };

  DataByLeadId = () => {
    let { form, farmDropdown } = this.state;
    let RoleData = JSON.parse(localStorage.getItem("Role")); // Get the role of the logged in user from local storage
    let { match } = this.props;
    console.log("props",this.props)
    let obj = {
      leadId: match.params.leadId,
    };
    getDataByLeadId(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        form = res?.data?.data;
        if (farmDropdown && farmDropdown?.map(e => e.serviceName === res?.data?.data?.farmService)) {
          form.farmService = res?.data?.data?.farmService;
        } else {
          form.farmService = null;
        }
        this.AllCampaignMediumDropDown(form.productSelection);
        form.campaignMedium && this.AllCampaignNamesDropdown(form.campaignMedium);
        this.setState(
          {
            form,
            status: res.data.data.status,
            isThisWhatsAppNo_1: res.data && res.data.data.isThisWhatsAppNo1,
            isThisWhatsAppNo_2: res.data && res.data.data.isThisWhatsAppNo2,
            isLeadCreatedByFranchisee: res?.data?.data?.isLeadCreatedByFranchisee,
          },
          this.addressbypincode(res.data && res.data.data && res.data.data.pinCode),
          this.fetchtehsildistrictstatefromvillage(res.data && res.data.data && res.data.data.area),
          this.findByProductCategory()
        );
        this.AgeByDateOfBirth();
        //if (RoleData != "FRANCHISE") {
        //  this.GetStateAndBranchFromArea();
        //}
      }
    });
  };

  AllTypeofInteractionDropDown = () => {
    findAllTypeofInteractionDropDown().then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          interactionDropdown: res.data.data,
        });
      }
    });
  };

  AllRejectionRemarksDropDown = () => {
    findAllRejectionRemarksDropDown().then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          rejectDropdown: res.data.data,
        });
      }
    });
  };

  addressbypincode = value => {
    let obj = {
      pincode: value,
    };
    findaddressbypincode(obj).then(res => {
      console.log("mydata", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
        this.setState({
          pincodeData: res.data.data,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          pincodeData: res.data.data,
        });
      }
    });
  };

  AllEducationStatusDropDown = () => {
    findAllEducationStatusDropDown().then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });

        console.log("data...", res);
        this.setState({
          educationDropdown: res.data.data,
        });
      }
    });
  };

  AllProductSelectionDropDown = () => {
    let { form } = this.state;
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let employeeId = leadData && leadData.leadEmployeeId;
    let obj = {
      employeeId: employeeId,
      searchString: form && form.area,
    };
    findAllProductSelectionDropDown(obj).then(res => {
      console.log("res", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          productDropdown: res.data && res.data.data,
        });
      }
    });
  };

  BranchAndStateByEmployeeId = () => {
    let { form } = this.state;
    let { branch, state } = form;
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let obj = {
      employeeId: leadData && leadData.leadEmployeeId,
    };
    getBranchAndStateByEmployeeId(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        // form.branch = res.data && res.data.data && res.data.data.branchName;
        // form.state = res.data && res.data.data && res.data.data.stateName;
        this.setState({
          branchstateData: res.data && res.data.data,
        });
      }
    });
  };

  GetStateAndBranchFromArea = () => {
    let { form } = this.state;
    let obj = {
      villageName: form && form.area,
    };
    getBranchAndStateBySpoke(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        form.branch = res.data && res.data.data && res.data.data.branchName;
        form.state = res.data && res.data.data && res.data.data.stateName;
        this.setState({
          form,
        });
      }
    });
  };

  AreaVillagesDropDownByFranchiseCode = () => {
    let { loaderForArea } = this.state;
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let obj = {
      franchiseCode: leadData && leadData.leadEmployeeId,
    };
    this.setState({ loaderForArea: true });
    getAreaVillagesDropDownByFranchiseCodeForSecondScreen(obj).then(res => {
      if (res.error) {
        this.setState({ loaderForArea: false });
        return;
      }
      if (res.data.error) {
        this.setState({ loaderForArea: false });
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({ loaderForArea: false });
        let newVillage = res && res.data && res.data.data;
        let PopAllValueFromDropdown = newVillage && newVillage.filter(e => e.villageName !== "All(1000111)");
        this.setState({
          areaDropdown: PopAllValueFromDropdown,
        });
      }
    });
  };

  handleCheckBoxChange = (name, value) => {
    let { form } = this.state;
    let { isThisWhatsAppNo_2 } = this.state;

    this.setState({
      isThisWhatsAppNo_2: !isThisWhatsAppNo_2,
    });
  };

  handleCheckBox1Change = () => {
    let { form } = this.state;
    let { isThisWhatsAppNo_1 } = this.state;

    this.setState({
      isThisWhatsAppNo_1: !isThisWhatsAppNo_1,
    });
  };

  onInputChange = (name, value, error) => {
    let { form } = this.state;
    form[name] = value;
    console.log("fre", form[name]);
    this.setState({
      form,
    });
  };

  onBranchChange = (name, value, error) => {
    let { form } = this.state;
    let RoleData = JSON.parse(localStorage.getItem("Role")); // Get the role of the logged in user from local storage
    form[name] = value;
    let str = form && form.area;
    if (form && form.area) {
      let match = str.match(/\(\d*?\)/g);
      form.pinCode = match && match?.[0].replace(/[\(\)']+/g, "");
      console.log("match", match && match?.[0]);
    }
    if (form && form.area == null) {
      form.pinCode = "";
      form.cityTownVillage = "";
      form.district = "";
      this.setState({
        form,
      });
    }
    console.log("fre", form[name]);
    this.setState(
      {
        form,
      },
      this.fetchtehsildistrictstatefromvillage(value)
    );
    this.addressbypincode(form.pinCode);
    this.AllProductSelectionDropDown();
    //if (form && form.area != "Others" && RoleData != "FRANCHISE") {
    //  this.GetStateAndBranchFromArea();
    //}
    if (form) {
      // && form.area == "Others") {
      this.BranchAndStateByEmployeeId();
    }
  };

  fetchtehsildistrictstatefromvillage = () => {
    let { form, DataForTehsilAndDistrict } = this.state;
    let objBody = {
      villageName: form.area,
    };
    findtehsildistrictstate(objBody).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 2000,
        // });
        return;
      } else {
        console.log("res.dataaaa", res?.data?.data[0]);
        form.cityTownVillage = res?.data?.data?.[0]?.tehsil;
        form.district = res?.data?.data?.[0].district;
        DataForTehsilAndDistrict = res?.data?.data[0];
        this.setState({
          form,
          DataForTehsilAndDistrict,
        });
        console.log("DataForTehsilAndDistrict", DataForTehsilAndDistrict);
      }
    });
  };

  // onInputValidate = (name, error = undefined) => {
  //   let { form, selectedSpokeError } = this.state;
  //   if (error !== undefined || error !== null || error !== "") {
  //     selectedSpokeError = error;
  //   }
  //   this.setState({
  //     selectedSpokeError: selectedSpokeError,
  //   });
  //   // console.log("error", error)
  // };

  onFormChange = (name, value, error = undefined) => {
    let { form, msgmobileNo1, msgmobileNo2, msgalternateNo, msgpincode } = this.state;
    console.log("value", name, value);

    form[name] = value;
    // if (error !== undefined) {
    //   let { errors } = form;
    //   errors[name] = error;
    // }
    this.setState({
      form,
    });

    if (name == "requestedLoanAmount" && value !== "") {
      if (value == 0) {
        this.state.loanamt = "Loan amount should be non zero";
        this.setState({ state: this.state });
      } else {
        this.state.loanamt = "";
        this.setState({ state: this.state });
      }
    }

    if (name == "mobileNumber1" && value != "") {
      let indianMobilenumber = /^[6789]\d{9}$/;
      if ((value && value.length < 10) || !value.match(indianMobilenumber) || value[0] <= "5") {
        this.state.msgmobileNo1 = "Please enter valid mobile number";
        this.setState({
          state: this.state,
        });
      } else {
        // errors.mobileNumber1 = false;
        this.state.msgmobileNo1 = "";
        this.setState({
          state: this.state,
        });
      }
    }
    // mobileNumber2 msgmobileNo2
    if (name == "mobileNumber2" && value != "") {
      let indianMobilenumber = /^[6789]\d{9}$/;
      if ((value && value.length < 10) || !value.match(indianMobilenumber) || value[0] <= "5") {
        this.state.msgmobileNo2 = "Please enter valid mobile number";
        this.setState({
          state: this.state,
        });
      } else {
        // errors.mobileNumber1 = false;
        this.state.msgmobileNo2 = "";
        this.setState({
          state: this.state,
        });
      }
    } else {
      this.state.msgmobileNo2 = "";
      this.setState({
        state: this.state,
      });
    }

    // alternateNo msgalternateNo
    if (name == "alternateNo" && value != "") {
      let indianMobilenumber = /^[6789]\d{9}$/;
      if ((value && value.length < 10) || !value.match(indianMobilenumber) || value[0] <= "5") {
        this.state.msgalternateNo = "Please enter valid alternate number";
        this.setState({
          state: this.state,
        });
      } else {
        // errors.mobileNumber1 = false;
        this.state.msgalternateNo = "";
        this.setState({
          state: this.state,
        });
      }
    } else {
      this.state.msgalternateNo = "";
      this.setState({
        state: this.state,
      });
    }
  };

  ondateChange = (name, value, error = undefined) => {
    let { form } = this.state;
    form[name] = value;
    // if (error !== undefined) {
    //   let { errors } = form;
    //   errors[name] = error;
    // }
    this.setState(
      {
        form,
      },
      this.AgeByDateOfBirth()
    );
  };
  onCancel = () => {
    this.props.history.push(`${public_url.Leadmodule}`);
  };
  onPincodeChange = (name, value, error = undefined) => {
    let { form } = this.state;
    form[name] = value;
    // if (error !== undefined) {
    //   let { errors } = form;
    //   errors[name] = error;
    // }
    this.setState(
      {
        form,
      },
      this.addressbypincode(value)
    );
    // msgpincode
    if (name == "pinCode" && value !== "") {
      if (value && value.length < 6) {
        // form.errors.pinCode = true;
        this.state.msgpincode = "Please enter valid Pincode";
        this.setState({ state: this.state });
      } else {
        // form.errors.pinCode = false;
        this.state.msgpincode = "";
        this.setState({ state: this.state });
      }
    }
  };

  // calculateAge = () => {
  //   let { form } = this.state;
  //   let { dateOfBirth } = form;
  //   // birthday is a date
  //   var ageDifMs = Date.now() - dateOfBirth.getTime();
  //   console.log("ageDifMs", ageDifMs);
  //   var ageDate = new Date(ageDifMs); // miliseconds from epoch
  //   return Math.abs(ageDate.getUTCFullYear() - 1970);
  // };

  AgeByDateOfBirth = () => {
    let { dateOfBirth, leadage } = this.state.form;

    let obj = {
      dateOfBirth: dateOfBirth,
    };

    getAgeByDateOfBirth(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        //toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          leadage: res.data && res.data.data && res.data.data.age,
        });
      }
    });
  };

  onRejectChange = (name, value, error = undefined) => {
    this.state[name] = value;
    this.setState({
      state: this.state,
    });
  };

  saveorupdate = () => {
    let { match } = this.props;
    let { form, isThisWhatsAppNo_1, pincodeData, isLeadCreatedByFranchisee, leadage, branchstateData, loading } =
      this.state;
    let { productSelection, farmService, errors, productType } = form;
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    // let RoleData = JSON.parse(localStorage.getItem("Role"));
    // let FlagForLead = RoleData == "FRANCHISE" ? true : false;
    let obj = cloneDeep(form);
    obj.isThisWhatsAppNo1 = isThisWhatsAppNo_1;
    obj.employeeId = leadData && leadData.leadEmployeeId;
    obj.pincodeAutofetchState = pincodeData && pincodeData[0].state_name;
    obj.isLeadCreatedByFranchisee = isLeadCreatedByFranchisee;
    obj.age = leadage;
    // obj.branch = branchstateData?.branchName;
    // obj.state = branchstateData?.stateName;
    obj.branch = form?.branch;
    obj.state = form?.state;
    //obj.isLeadCreatedByFranchisee = FlagForLead;
    this.setState({ loading: true });
    saveOrUpdateLeadDetails(obj).then(res => {
      if (res.error) {
        this.setState({ loading: false });
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
      } else {
        toast.success(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        if (
          form?.productSelection == "Housing Loans" ||
          form?.productSelection == "Farm Loans" ||
          form?.productSelection == "Business Loans" ||
          form?.productSelection == "Personal Loans" ||
          form?.productSelection == "Gold Loans" ||
          form?.productSelection == "Consumer Durables"
        ) {
          this.props.history.push(`${public_url.Leadmodule}`);
        } else if (form?.productSelection == "Consumer Durables") {
          this.props.history.push(`${public_url.Cdproduct}/${match.params.leadId}/${productSelection}/${productType}`);
        } else {
          this.props.history.push(`${public_url.farm_list}/${match.params.leadId}/${productSelection}/${farmService}`);
        }
      }
    });
  };

  handleReject = () => {
    let { rejectionRemark, otherRemark } = this.state;

    let { match } = this.props;
    let obj = {
      leadId: match.params.leadId,
      rejectionRemark: rejectionRemark,
      otherRemark: otherRemark,
    };
    rejectLeadByLeadId(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        toast.success(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 4000,
        });
        this.props.history.push(`${public_url.Leadmodule}`);
      }
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  onProductChange = (name, value, error) => {
    // this.setState({
    //   campaignValues,
    // });
    let { form, campaignMediumValues, campaignNameValues } = this.state;
    form[name] = value;
    campaignMediumValues = [];
    campaignNameValues = [];
    form.campaignMedium = "NA";
    form.campaignName = "NA";
    console.log("fre", form[name]);
    this.setState(
      {
        form,
        campaignMediumValues,
        campaignNameValues,
      },
      this.findByProductCategory(),
      this.AllCampaignMediumDropDown(value)
    );
    this.clearAll();
  };

  onCampaignMediumChange = (name, value, error) => {
    let { form, campaignNameValues } = this.state;

    form.campaignMedium = value;

    this.setState({
      form,
    });

    if (value !== "NA") {
      this.AllCampaignNamesDropdown(value);
    } else {
      campaignNameValues = [];
      campaignNameValues.push({ campaignNameDescription: "NA", id: 0, campaignName: "NA", campaignMedium: "NA" });
      this.setState({ campaignNameValues });
    }
  };

  onCampaignNameChange = (name, value, error) => {
    let { form } = this.state;
    form.campaignName = value;
    this.setState({
      form,
    });
  };

  clearAll = () => {
    let { form } = this.state;
    form.farmService = "";
    form.landArea = "";
    form.crop = "";
    form.requestedLoanAmount = "";
    form.productType = "";
    form.productRequiredBy = "";
    this.setState({ form });
  };

  render() {
    let { match } = this.props;
    let {
      form,
      areaDropdown,
      isThisWhatsAppNo_1,
      isThisWhatsAppNo_2,
      educationDropdown,
      productDropdown,
      farmDropdown,
      show,
      rejectDropdown,
      rejectionRemark,
      otherRemark,
      pincodeData,
      interactionDropdown,
      leadage,
      msgmobileNo1,
      msgalternateNo,
      msgmobileNo2,
      msgpincode,
      status,
      loanamt,
      campaignValues,
      CDProductDropdown,
      branchstateData,
      productval,
      loading,
      loaderForArea,
      DataForTehsilAndDistrict,
      campaignMediumValues,
      campaignNameValues,
    } = this.state;
    let {
      area,
      remarks,
      campaignMedium,
      campaignName,
      branch,
      state,
      entityType,
      customerName,
      mobileNumber1,
      pinCode,
      productSelection,
      farmService,
      crop,
      landArea,
      leadFollowUpDate,
      gender,
      dateOfBirth,
      age,
      maritalStatus,
      mobileNumber2,
      inquiryDate,
      ccLastCall,
      inquirySource,
      customerOccupation,
      firstWorkingMember,
      secondWorkingmember,
      secondMemberOccupation,
      firstMemberOccupation,
      familyMemberOccuption,
      workPlaceVillage,
      workPlacePincode,
      firstExistingLoans,
      secondExistingLoans,
      secondExistingLoanEmi,
      firstExistingLoanEmi,
      finalCallingDateByCcAgents,
      alternateNo,
      addLine1,
      addLine2,
      cityTownVillage,
      pincodeAutofetchState,
      district,
      educationStatus,
      typeOfInteraction,
      requestedLoanAmount,
      productType,
      endUse,
      productRequiredBy,
    } = form;
    if (campaignMedium === null || campaignMedium === "") {
      campaignMedium = "NA";
    }
    if (campaignName === null || campaignName === "") {
      campaignName = "NA";
    }
    let Role = JSON.parse(localStorage.getItem("Role"));
    console.log("pincodeData", pincodeData);
    console.log("msgmobileNo1", msgmobileNo1);
    console.log("campaignName render: ", campaignName);
    let state_name;
    if (pincodeData && pincodeData.length > 0) {
      console.log("pincodeData", pincodeData);
      state_name = pincodeData && pincodeData[0].state_name;
    }
    console.log("state_name", state_name);

    if (DataForTehsilAndDistrict) {
      form.cityTownVillage = DataForTehsilAndDistrict?.tehsil;
      form.district = DataForTehsilAndDistrict?.district;
    }

    if (branchstateData && Role == "FRANCHISE") {
      form.branch = branchstateData.branchName;
      form.state = branchstateData.stateName;
    }

    if (form && form.area == null) {
      form.pinCode = "";
      form.cityTownVillage = "";
      form.district = "";
    }

    let disSave;

    if (
      gender == "" ||
      gender == null ||
      addLine1 == "" ||
      pinCode == "" ||
      cityTownVillage == "" ||
      area == null ||
      mobileNumber1 == ""
    ) {
      disSave = true;
    } else {
      disSave = false;
    }

    console.log("disSave", disSave);

    let isEmpty;
    if (
      msgmobileNo1 == "Please enter valid mobile number" ||
      msgmobileNo2 == "Please enter valid mobile number" ||
      msgpincode == "Please enter valid Pincode" ||
      msgalternateNo == "Please enter valid alternate number" ||
      loanamt == "Loan amount should be non zero"
    ) {
      isEmpty = true;
    } else {
      isEmpty = false;
    }

    return (
      <React.Fragment>
        <BreadCrumbs
          name1={"Lead List"}
          name2={"Update Lead"}
          url1={`${public_url.Leadmodule}`}
          url2={`${public_url.update_lead}/${match.params.leadId}`}
        />
        <section className="px-2 px-md-5 pt-4 pb-5 dashboard_div bg_l-secondary">
          <div className="d-flex justify-content-start align-items-center">
            <section className="py-4 position-relative bg_l-secondary w-100">
              <div className="pb-5 bg-white">
                <div className="container">
                  <div className="row disbursment mb-5">
                    <div className="col-md-6 col-sm-6 col-lg-1 d-flex mt-3 align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">Spoke {<i className="text-danger">*</i>}</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-3 mt-4 mr-auto">
                      <div class="select">
                        {loaderForArea ? (
                          <React.Fragment>
                            <i class="fa fa-spinner fa-spin ml-5 gTextPrimary fw-500"></i>
                            <span className="ml-2 fs-18 gTextPrimary fw-500">Please wait...</span>
                          </React.Fragment>
                        ) : (
                          <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={areaDropdown}
                            value={form.area}
                            title="area"
                            name="area"
                            onChangeFunc={(name, value, error) => {
                              this.onBranchChange(name, value, error);
                            }}
                            isClearable={true}
                            //  error={selectedSpokeError}
                            isReq={true}
                            valueKey="villageCode"
                            labelKey="villageName"
                            placeholder="Select area"
                            disabled={
                              status === "Lead Rejected" ||
                              status === "Sent to OMS - FARM" ||
                              status === "Sent to Lending system" ||
                              status === "Credit Review" ||
                              status === "Credit Sanctioned" ||
                              status === "Credit Rejected" ||
                              status === "Single payment advisory generated" ||
                              status === "Bulk payment advisory generated" ||
                              status === "Cancelled" ||
                              status === "Disbursed" ||
                              status === "Demo & installation done" ||
                              status === "Order fulfill" ||
                              Role === "Call Centre"
                                ? true
                                : false
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-1 d-flex mt-3 align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">Branch</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-3  mt-4">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Branch"
                        name="branch"
                        value={form?.branch}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        // maxLength={16}
                        disabled={true}
                        isReq={true}
                        // reqType="alphaNumeric"
                        title="branch"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-1 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">State</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-3 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="State"
                        name="state"
                        value={form?.state}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        // maxLength={16}
                        disabled={true}
                        isReq={true}
                        // reqType="alphaNumeric"
                        title="state"
                      />
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <hr className="bg_lightblue border-0 h-1px mt-0" />
                  </div>
                  {/*  */}
                  <div className="row disbursment mt-2">
                    <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">Entity Type</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Entity Type"
                        name="entityType"
                        value={entityType}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        // maxLength={16}
                        disabled={true}
                        isReq={true}
                        // reqType="alphaNumeric"
                        title="entityType"
                      />
                    </div>
                    <div className=" col-lg-6"></div>
                    <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">
                        Customer Name {<i className="text-danger">*</i>}
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-5 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Customer Name"
                        name="customerName"
                        value={customerName}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={100}
                        disabled={true}
                        isReq={true}
                        reqType="alphaNumeric"
                        title="customerName"
                      />
                    </div>
                    <div className=" col-lg-5"></div>
                    <div className="col-md-6 col-sm-6 col-lg-2  d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">Gender {<i className="text-danger">*</i>}</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <div class="select">
                        <Select
                          className="w-100 fs-12 create-lead-form-select"
                          options={genderDropdown}
                          value={form?.gender}
                          title="gender"
                          name="gender"
                          onChangeFunc={(name, value, error) => {
                            this.onInputChange(name, value, error);
                          }}
                          isClearable={true}
                          //  error={selectedSpokeError}
                          isReq={true}
                          // valueKey="gender"
                          // labelKey="gender"

                          placeholder="Select gender"
                          disabled={
                            status === "Lead Rejected" ||
                            status === "Sent to OMS - FARM" ||
                            status === "Sent to Lending system" ||
                            status === "Credit Review" ||
                            status === "Credit Sanctioned" ||
                            status === "Credit Rejected" ||
                            status === "Single payment advisory generated" ||
                            status === "Bulk payment advisory generated" ||
                            status === "Cancelled" ||
                            status === "Disbursed" ||
                            status === "Demo & installation done" ||
                            status === "Order fulfill" ||
                            Role === "Call Centre"
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-2 d-flex mt-3 align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary ml-1 fw-500">Date Of Birth</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="dateOfBirth"
                        name="dateOfBirth"
                        value={moment(dateOfBirth).format("YYYY-MM-DD")}
                        onChangeFunc={(name, value, error) => {
                          this.ondateChange(name, value, error);
                        }}
                        type="date"
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        // maxLength={16}
                        // disabled={}
                        isReq={true}
                        // reqType="number"
                        title="dateOfBirth"
                        disabled={
                          status === "Lead Rejected" ||
                          status === "Sent to OMS - FARM" ||
                          status === "Sent to Lending system" ||
                          status === "Credit Review" ||
                          status === "Credit Sanctioned" ||
                          status === "Credit Rejected" ||
                          status === "Single payment advisory generated" ||
                          status === "Bulk payment advisory generated" ||
                          status === "Cancelled" ||
                          status === "Disbursed" ||
                          status === "Demo & installation done" ||
                          status === "Order fulfill" ||
                          Role === "Call Centre"
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">Age</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Age"
                        name="age"
                        value={leadage ? leadage : form.age}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        // maxLength={16}
                        disabled={true}
                        isReq={true}
                        // reqType="alphaNumeric"
                        title="age"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-2  d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary ml-1 fw-500">Marital Status</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mb-2 mr-auto">
                      <div class="select">
                        <Select
                          className="w-100 fs-12 create-lead-form-select"
                          options={maritalStatusDropdown}
                          value={maritalStatus}
                          title="maritalStatus"
                          name="maritalStatus"
                          onChangeFunc={(name, value, error) => {
                            this.onInputChange(name, value, error);
                          }}
                          isClearable={true}
                          //  error={selectedSpokeError}
                          isReq={true}
                          // valueKey="gender"
                          // labelKey="gender"

                          placeholder="Select Marital Status"
                          disabled={
                            status === "Lead Rejected" ||
                            status === "Sent to OMS - FARM" ||
                            status === "Sent to Lending system" ||
                            status === "Credit Review" ||
                            status === "Credit Sanctioned" ||
                            status === "Credit Rejected" ||
                            status === "Single payment advisory generated" ||
                            status === "Bulk payment advisory generated" ||
                            status === "Cancelled" ||
                            status === "Disbursed" ||
                            status === "Demo & installation done" ||
                            status === "Order fulfill" ||
                            Role === "Call Centre"
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-2 mt-1 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">
                        Mobile No. 1 {<i className="text-danger">*</i>}
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Mobile No. 1"
                        name="mobileNumber1"
                        value={mobileNumber1}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={10}
                        // disabled={}
                        error={msgmobileNo1}
                        isReq={true}
                        reqType="mobile10"
                        title="mobileNumber1"
                        disabled={
                          status === "Lead Rejected" ||
                          status === "Sent to OMS - FARM" ||
                          status === "Sent to Lending system" ||
                          status === "Credit Review" ||
                          status === "Credit Sanctioned" ||
                          status === "Credit Rejected" ||
                          status === "Single payment advisory generated" ||
                          status === "Bulk payment advisory generated" ||
                          status === "Cancelled" ||
                          status === "Disbursed" ||
                          status === "Demo & installation done" ||
                          status === "Order fulfill" ||
                          Role === "Call Centre"
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="col-md-6 col-lg-2 col-sm-6 mt-2  align-self-center">
                      <span className="main gTextPrimary ml-1 checkboxText">Is this WhatsApp No? </span>
                    </div>
                    <div className="col-md-6 col-lg-1 col-sm-6">
                      <label className="main styleGreen gTextPrimary my-lg-5">
                        <input
                          type="checkbox"
                          name="isThisWhatsAppNo_1"
                          value={isThisWhatsAppNo_1}
                          checked={isThisWhatsAppNo_1}
                          onChange={this.handleCheckBox1Change}
                          disabled={
                            status === "Lead Rejected" ||
                            status === "Sent to OMS - FARM" ||
                            status === "Sent to Lending system" ||
                            status === "Credit Review" ||
                            status === "Credit Sanctioned" ||
                            status === "Credit Rejected" ||
                            status === "Single payment advisory generated" ||
                            status === "Bulk payment advisory generated" ||
                            status === "Cancelled" ||
                            status === "Disbursed" ||
                            status === "Demo & installation done" ||
                            status === "Order fulfill" ||
                            Role === "Call Centre"
                              ? true
                              : false
                          }
                        />
                        <span className="geekmark"></span>
                      </label>
                    </div>
                    <div className=" col-lg-3"></div>
                    {/* <div className="col-6"></div> */}
                    <div className="col-md-6 col-sm-6 col-lg-2  d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">Mobile No. 2</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4  mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Mobile No. 2"
                        name="mobileNumber2"
                        value={mobileNumber2}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={10}
                        error={msgmobileNo2}
                        // disabled={}
                        isReq={true}
                        reqType="mobile10"
                        title="mobileNumber2"
                        disabled={
                          status === "Lead Rejected" ||
                          status === "Sent to OMS - FARM" ||
                          status === "Sent to Lending system" ||
                          status === "Credit Review" ||
                          status === "Credit Sanctioned" ||
                          status === "Credit Rejected" ||
                          status === "Single payment advisory generated" ||
                          status === "Bulk payment advisory generated" ||
                          status === "Cancelled" ||
                          status === "Disbursed" ||
                          status === "Demo & installation done" ||
                          status === "Order fulfill" ||
                          Role === "Call Centre"
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="col-md-6 col-lg-2 col-sm-6 mt-2 align-self-center">
                      <span className="main gTextPrimary ml-1 checkboxText">Is this WhatsApp No?</span>
                    </div>
                    <div className="col-md-6 col-lg-1 col-sm-6">
                      <label className="main styleGreen gTextPrimary my-lg-5">
                        <input
                          type="checkbox"
                          name="isThisWhatsAppNo_2"
                          value={isThisWhatsAppNo_2}
                          checked={isThisWhatsAppNo_2}
                          onChange={this.handleCheckBoxChange}
                          disabled={
                            status === "Lead Rejected" ||
                            status === "Sent to OMS - FARM" ||
                            status === "Sent to Lending system" ||
                            status === "Credit Review" ||
                            status === "Credit Sanctioned" ||
                            status === "Credit Rejected" ||
                            status === "Single payment advisory generated" ||
                            status === "Bulk payment advisory generated" ||
                            status === "Cancelled" ||
                            status === "Disbursed" ||
                            status === "Demo & installation done" ||
                            status === "Order fulfill" ||
                            Role === "Call Centre"
                              ? true
                              : false
                          }
                        />
                        <span className="geekmark"></span>
                      </label>
                    </div>
                    <div className=" col-lg-3"></div>
                    {/* <div className="col-6"></div> */}

                    <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">Alternative Number</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-2 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="alternateNo"
                        name="alternateNo"
                        value={alternateNo}
                        onChangeFunc={(name, value, error) => {
                          this.onFormChange(name, value, error);
                        }}
                        error={msgalternateNo}
                        maxLength={10}
                        disabled={
                          status === "Lead Rejected" ||
                          status === "Sent to OMS - FARM" ||
                          status === "Sent to Lending system" ||
                          status === "Credit Review" ||
                          status === "Credit Sanctioned" ||
                          status === "Credit Rejected" ||
                          status === "Single payment advisory generated" ||
                          status === "Bulk payment advisory generated" ||
                          status === "Cancelled" ||
                          status === "Disbursed" ||
                          status === "Demo & installation done" ||
                          status === "Order fulfill" ||
                          Role === "Call Centre"
                            ? true
                            : false
                        }
                        isReq={true}
                        reqType="number"
                        title="alternateNo"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 ml-1 gTextPrimary fw-500">
                        Address Line 1 {<i className="text-danger">*</i>}
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="addLine1"
                        name="addLine1"
                        value={addLine1}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={150}
                        disabled={
                          status === "Lead Rejected" ||
                          status === "Sent to OMS - FARM" ||
                          status === "Sent to Lending system" ||
                          status === "Credit Review" ||
                          status === "Credit Sanctioned" ||
                          status === "Credit Rejected" ||
                          status === "Single payment advisory generated" ||
                          status === "Bulk payment advisory generated" ||
                          status === "Cancelled" ||
                          status === "Disbursed" ||
                          status === "Demo & installation done" ||
                          status === "Order fulfill" ||
                          Role === "Call Centre"
                            ? true
                            : false
                        }
                        isReq={true}
                        reqType="alphaNumeric"
                        title="addLine1"
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">Address line 2</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="addLine2"
                        name="addLine2"
                        value={addLine2}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={150}
                        disabled={
                          status === "Lead Rejected" ||
                          status === "Sent to OMS - FARM" ||
                          status === "Sent to Lending system" ||
                          status === "Credit Review" ||
                          status === "Credit Sanctioned" ||
                          status === "Credit Rejected" ||
                          status === "Single payment advisory generated" ||
                          status === "Bulk payment advisory generated" ||
                          status === "Cancelled" ||
                          status === "Disbursed" ||
                          status === "Demo & installation done" ||
                          status === "Order fulfill" ||
                          Role === "Call Centre"
                            ? true
                            : false
                        }
                        isReq={true}
                        reqType="alphaNumeric"
                        title="addLine2"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 ml-1 gTextPrimary fw-500">
                        Pin Code {<i className="text-danger">*</i>}
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="pinCode"
                        name="pinCode"
                        value={pinCode}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onPincodeChange(name, value, error);
                        }}
                        maxLength={6}
                        disabled={true}
                        error={msgpincode}
                        isReq={true}
                        reqType="number"
                        title="pinCode"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">Tehsil {<i className="text-danger">*</i>}</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Tehsil"
                        name="cityTownVillage"
                        value={form?.cityTownVillage}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={100}
                        disabled={true}
                        isReq={true}
                        reqType="onlyAlphbate"
                        title="Tehsil"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 ml-1 gTextPrimary fw-500">State</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="State"
                        name="pincodeAutofetchState"
                        //value={form?.state}
                        value={state_name}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        // maxLength={16}
                        disabled={true}
                        isReq={true}
                        // reqType="alphaNumeric"
                        title="pincodeAutofetchState"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">District {<i className="text-danger">*</i>}</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="district"
                        name="district"
                        value={form?.district}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        disabled={true}
                        isReq={true}
                        // reqType="alphaNumeric"
                        title="district"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-2  d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">Education Status</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <div class="select">
                        <Select
                          className="w-100 fs-12 create-lead-form-select"
                          options={educationDropdown}
                          value={educationStatus}
                          title="educationStatus"
                          name="educationStatus"
                          onChangeFunc={(name, value, error) => {
                            this.onInputChange(name, value, error);
                          }}
                          isClearable={true}
                          //  error={selectedSpokeError}
                          isReq={true}
                          valueKey="educationStatus"
                          labelKey="educationStatus"
                          placeholder="Select Education Status"
                          disabled={
                            status === "Lead Rejected" ||
                            status === "Sent to OMS - FARM" ||
                            status === "Sent to Lending system" ||
                            status === "Credit Review" ||
                            status === "Credit Sanctioned" ||
                            status === "Credit Rejected" ||
                            status === "Single payment advisory generated" ||
                            status === "Bulk payment advisory generated" ||
                            status === "Cancelled" ||
                            status === "Disbursed" ||
                            status === "Demo & installation done" ||
                            status === "Order fulfill" ||
                            Role === "Call Centre"
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2 mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">
                        Product Selection {<i className="text-danger">*</i>}
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-3 mr-auto">
                      <div class="select">
                        <Select
                          className="w-100 fs-12 create-lead-form-select"
                          options={productDropdown}
                          value={productSelection}
                          title="productSelection"
                          name="productSelection"
                          onChangeFunc={(name, value, error) => {
                            this.onProductChange(name, value, error);
                          }}
                          isClearable={true}
                          //  error={selectedSpokeError}
                          isReq={true}
                          valueKey="productdescription"
                          labelKey="productdescription"
                          placeholder="Select Product"
                          disabled={
                            status === "Lead Rejected" ||
                            status === "Sent to OMS - FARM" ||
                            status === "Sent to Lending system" ||
                            status === "Credit Review" ||
                            status === "Credit Sanctioned" ||
                            status === "Credit Rejected" ||
                            status === "Single payment advisory generated" ||
                            status === "Bulk payment advisory generated" ||
                            status === "Cancelled" ||
                            status === "Disbursed" ||
                            status === "Demo & installation done" ||
                            status === "Order fulfill" ||
                            Role === "Call Centre"
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2 mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary ml-0 fw-500">
                        {productSelection == "Housing Loans" ||
                        productSelection == "Farm Loans" ||
                        productSelection == "Business Loans" ||
                        productSelection == "Personal Loans" ||
                        productSelection == "Gold Loans" ||
                        productSelection == "Gold @Home" ? (
                          <>
                            Requested loan amount <i className="text-danger">*</i>
                          </>
                        ) : productSelection == "Farm Implement Leasing" ? (
                          <>
                            Farm Service <i className="text-danger">*</i>
                          </>
                        ) : productSelection == "Consumer Durables" ? (
                          <>
                            Product Type <i className="text-danger">*</i>{" "}
                          </>
                        ) : null}
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-3 mr-auto">
                      <div class="select">
                        {productSelection == "Housing Loans" ||
                        productSelection == "Farm Loans" ||
                        productSelection == "Business Loans" ||
                        productSelection == "Personal Loans" ||
                        productSelection == "Gold Loans" ||
                        productSelection == "Gold @Home" ? (
                          <Input
                            className="form-control text-center fs-16"
                            placeholder="Enter Requested Loan Amount"
                            name="requestedLoanAmount "
                            value={requestedLoanAmount && formatIndianCurrency(requestedLoanAmount).toString()}
                            onChangeFunc={(name, value, error) => {
                              value = replaceComma(value);
                              if (checkIfNotDecimal(value)) {
                                this.onFormChange(name, value, error);
                              }
                            }}
                            error={loanamt}
                            maxLength="9"
                            disabled={
                              status === "Lead Rejected" ||
                              status === "Sent to OMS - FARM" ||
                              status === "Sent to Lending system" ||
                              status === "Credit Review" ||
                              status === "Credit Sanctioned" ||
                              status === "Credit Rejected" ||
                              status === "Single payment advisory generated" ||
                              status === "Bulk payment advisory generated" ||
                              status === "Cancelled" ||
                              status === "Disbursed" ||
                              status === "Demo & installation done" ||
                              status === "Order fulfill" ||
                              Role === "Call Centre"
                                ? true
                                : false
                            }
                            isReq={true}
                            reqType="number"
                            title="requestedLoanAmount "
                          />
                        ) : productSelection == "Farm Implement Leasing" ? (
                          <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={farmDropdown}
                            value={farmService}
                            title="farmService"
                            name="farmService"
                            onChangeFunc={(name, value, error) => {
                              this.onInputChange(name, value, error);
                            }}
                            isClearable={true}
                            //  error={selectedSpokeError}
                            isReq={true}
                            valueKey="serviceName"
                            labelKey="serviceName"
                            placeholder="Select Farm Service"
                            disabled={
                              status === "Lead Rejected" ||
                              status === "Sent to OMS - FARM" ||
                              status === "Sent to Lending system" ||
                              status === "Credit Review" ||
                              status === "Credit Sanctioned" ||
                              status === "Credit Rejected" ||
                              status === "Single payment advisory generated" ||
                              status === "Bulk payment advisory generated" ||
                              status === "Cancelled" ||
                              status === "Disbursed" ||
                              status === "Demo & installation done" ||
                              status === "Order fulfill" ||
                              Role === "Call Centre"
                                ? true
                                : false
                            }
                          />
                        ) : productSelection == "Consumer Durables" ? (
                          <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={CDProductDropdown}
                            value={productType}
                            title="productType"
                            name="productType"
                            onChangeFunc={(name, value, error) => {
                              this.onInputChange(name, value, error);
                            }}
                            isClearable={true}
                            //  error={selectedSpokeError}
                            isReq={true}
                            valueKey="productName"
                            labelKey="productName"
                            placeholder="Select Product Type"
                            disabled={
                              status === "Lead Rejected" ||
                              status === "Sent to OMS - FARM" ||
                              status === "Sent to Lending system" ||
                              status === "Credit Review" ||
                              status === "Credit Sanctioned" ||
                              status === "Credit Rejected" ||
                              status === "Single payment advisory generated" ||
                              status === "Bulk payment advisory generated" ||
                              status === "Cancelled" ||
                              status === "Disbursed" ||
                              status === "Demo & installation done" ||
                              status === "Order fulfill" ||
                              Role === "Call Centre"
                                ? true
                                : false
                            }
                          />
                        ) : null}
                      </div>
                    </div>
                    {productSelection == "Housing Loans" ||
                    productSelection == "Farm Loans" ||
                    productSelection == "Business Loans" ||
                    productSelection == "Personal Loans" ||
                    productSelection == "Gold Loans" ? null : productSelection == "Farm Implement Leasing" ? (
                      <>
                        <div className="col-md-6 col-sm-6 col-lg-2 mt-3 d-flex align-items-center">
                          <label class="fs-14 mb-0 gTextPrimary fw-500">Crop {<i className="text-danger">*</i>} </label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-4 mt-3 mr-auto">
                          <Input
                            className="form-control text-center fs-16"
                            placeholder="Crop"
                            name="crop"
                            value={crop}
                            onChangeFunc={(name, value, error) => {
                              //   value = replaceComma(value);
                              this.onFormChange(name, value, error);
                            }}
                            // maxLength={16}
                            disabled={
                              status === "Lead Rejected" ||
                              status === "Sent to OMS - FARM" ||
                              status === "Sent to Lending system" ||
                              status === "Credit Review" ||
                              status === "Credit Sanctioned" ||
                              status === "Credit Rejected" ||
                              status === "Single payment advisory generated" ||
                              status === "Bulk payment advisory generated" ||
                              status === "Cancelled" ||
                              status === "Disbursed" ||
                              status === "Demo & installation done" ||
                              status === "Order fulfill" ||
                              Role === "Call Centre"
                                ? true
                                : false
                            }
                            isReq={true}
                            // reqType="alphaNumeric"
                            title="crop"
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                          <label class="fs-14 mb-0 ml-0 gTextPrimary fw-500">
                            Land Area(In Acres) {<i className="text-danger">*</i>}
                          </label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                          <Input
                            className="form-control text-center fs-16"
                            placeholder="Land Area (IN Acres)"
                            name="landArea"
                            value={landArea}
                            onChangeFunc={(name, value, error) => {
                              if (value == "" || (Math.ceil(value) <= 999 && parseInt(value) >= 0)) {
                                value = value
                                  .toString()
                                  .split(".")
                                  .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                                  .join(".");
                                this.onFormChange(name, value, error);
                              }
                              // this.onFormChange(name, value, error);
                            }}
                            // maxLength={16}
                            disabled={
                              status === "Lead Rejected" ||
                              status === "Sent to OMS - FARM" ||
                              status === "Sent to Lending system" ||
                              status === "Credit Review" ||
                              status === "Credit Sanctioned" ||
                              status === "Credit Rejected" ||
                              status === "Single payment advisory generated" ||
                              status === "Bulk payment advisory generated" ||
                              status === "Cancelled" ||
                              status === "Disbursed" ||
                              status === "Demo & installation done" ||
                              status === "Order fulfill" ||
                              Role === "Call Centre"
                                ? true
                                : false
                            }
                            isReq={true}
                            // reqType="alphaNumeric"
                            title="landArea"
                          />
                        </div>
                      </>
                    ) : null}
                    <div className="col-md-6 col-sm-6 col-lg-2 mt-3  mb-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">
                        Campaign Medium {<i className="text-danger">*</i>}
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-3 mr-auto">
                      <div class="select">
                        {/* new product start  */}
                        <Select
                          className="w-100 fs-12 create-lead-form-select"
                          options={campaignMediumValues}
                          value={campaignMedium}
                          title="camapignMedium"
                          name="camapignMedium"
                          onChangeFunc={(name, value, error) => {
                            this.onCampaignMediumChange(name, value, error);
                          }}
                          isClearable={true}
                          valueKey="campaignMedium"
                          labelKey="campaignMedium"
                          placeholder="Select Campaign Medium"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">
                        Campaign Name {<i className="text-danger">*</i>}
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <div class="select">
                        {/* new product start  */}
                        <Select
                          className="w-100 fs-12 create-lead-form-select"
                          options={campaignNameValues}
                          value={campaignName}
                          title="campaignName"
                          name="campaignName"
                          onChangeFunc={(name, value, error) => {
                            this.onCampaignNameChange(name, value, error);
                          }}
                          isClearable={true}
                          valueKey="campaignName"
                          labelKey="campaignName"
                          placeholder="Select Campaign Name"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">Type of Interaction</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <div class="select">
                        <Select
                          className="w-100 fs-12 create-lead-form-select"
                          options={interactionDropdown}
                          value={typeOfInteraction}
                          title="typeOfInteraction"
                          name="typeOfInteraction"
                          onChangeFunc={(name, value, error) => {
                            this.onInputChange(name, value, error);
                          }}
                          isClearable={true}
                          //  error={selectedSpokeError}
                          isReq={true}
                          valueKey="typeOfInteraction"
                          labelKey="typeOfInteraction"
                          placeholder="Select Type Of Interaction"
                          disabled={
                            status === "Lead Rejected" ||
                            status === "Sent to OMS - FARM" ||
                            status === "Sent to Lending system" ||
                            status === "Credit Review" ||
                            status === "Credit Sanctioned" ||
                            status === "Credit Rejected" ||
                            status === "Single payment advisory generated" ||
                            status === "Bulk payment advisory generated" ||
                            status === "Cancelled" ||
                            status === "Disbursed" ||
                            status === "Demo & installation done" ||
                            status === "Order fulfill" ||
                            Role === "Call Centre"
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary ml-0 fw-500">
                        Lead Follow-up Date {<i className="text-danger">*</i>}
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Lead Follow-up Date"
                        name="leadFollowUpDate"
                        value={moment(leadFollowUpDate).format("YYYY-MM-DD")}
                        onChangeFunc={(name, value, error) => {
                          this.onFormChange(name, value, error);
                        }}
                        type="date"
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        // max={moment(new Date()).format("YYYY-MM-DD")}
                        // maxLength={16}
                        disabled={
                          status === "Lead Rejected" ||
                          status === "Sent to OMS - FARM" ||
                          status === "Sent to Lending system" ||
                          status === "Credit Review" ||
                          status === "Credit Sanctioned" ||
                          status === "Credit Rejected" ||
                          status === "Single payment advisory generated" ||
                          status === "Bulk payment advisory generated" ||
                          status === "Cancelled" ||
                          status === "Disbursed" ||
                          status === "Demo & installation done" ||
                          status === "Order fulfill" ||
                          Role === "Call Centre"
                            ? true
                            : false
                        }
                        isReq={true}
                        // reqType="number"
                        title="leadFollowUpDate"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-2 mb-2 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500">End Use</label>
                    </div>
                    {Role == "FRANCHISE" ? (
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-2 mb-3 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="End Use"
                          name="endUse"
                          value={endUse}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          // maxLength={16}
                          disabled={
                            status === "Lead Rejected" ||
                            status === "Sent to OMS - FARM" ||
                            status === "Sent to Lending system" ||
                            status === "Credit Review" ||
                            status === "Credit Sanctioned" ||
                            status === "Credit Rejected" ||
                            status === "Single payment advisory generated" ||
                            status === "Bulk payment advisory generated" ||
                            status === "Cancelled" ||
                            status === "Disbursed" ||
                            status === "Demo & installation done" ||
                            status === "Order fulfill" ||
                            Role === "Call Centre"
                              ? true
                              : false
                          }
                          isReq={true}
                          //reqType="alphaNumeric"
                          title="endUse"
                        />
                      </div>
                    ) : (
                      <>
                        <div className="col-md-6 col-sm-6 col-lg-4 mt-2 mb-3 mr-auto">
                          <div class="select">
                            <Select
                              className="w-100 fs-12 create-lead-form-select"
                              options={productval}
                              value={endUse}
                              title="endUse"
                              name="endUse"
                              onChangeFunc={(name, value, error) => {
                                // this.onInputChange(name, value, error);
                                this.onFormChange(name, value, error);
                              }}
                              // isClearable={true}
                              //  error={selectedSpokeError}
                              isReq={true}
                              valueKey="endUse"
                              labelKey="endUse"
                              placeholder="Select End Use"
                              disabled={
                                status === "Lead Rejected" ||
                                status === "Sent to OMS - FARM" ||
                                status === "Sent to Lending system" ||
                                status === "Credit Review" ||
                                status === "Credit Sanctioned" ||
                                status === "Credit Rejected" ||
                                status === "Single payment advisory generated" ||
                                status === "Bulk payment advisory generated" ||
                                status === "Cancelled" ||
                                status === "Disbursed" ||
                                status === "Demo & installation done" ||
                                status === "Order fulfill" ||
                                Role === "Call Centre"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {/*  */}
                    {productSelection == "Farm Implement Leasing" ? null : (
                      <>
                        <div className="col-md-6 col-sm-6 col-lg-2 d-flex align-items-center">
                          <label class="fs-14 mb-0 gTextPrimary fw-500">
                            Product Required By {<i className="text-danger">*</i>}
                          </label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-4 mt-2 mr-auto">
                          <div class="select">
                            {/* new product start */}

                            <Select
                              className="w-100 fs-12 create-lead-form-select"
                              options={productValue}
                              value={productRequiredBy}
                              title="productRequiredBy"
                              name="productRequiredBy"
                              onChangeFunc={(name, value, error) => {
                                this.onFormChange(name, value, error);
                              }}
                              isClearable={true}
                              disabled={
                                productSelection == "" ||
                                productSelection == null ||
                                (status === "Lead Rejected" ||
                                status === "Sent to OMS - FARM" ||
                                status === "Sent to Lending system" ||
                                status === "Credit Review" ||
                                status === "Credit Sanctioned" ||
                                status === "Credit Rejected" ||
                                status === "Single payment advisory generated" ||
                                status === "Bulk payment advisory generated" ||
                                status === "Cancelled" ||
                                status === "Disbursed" ||
                                status === "Demo & installation done" ||
                                status === "Order fulfill" ||
                                Role === "Call Centre"
                                  ? true
                                  : false)
                              }
                              //  error={selectedSpokeError}
                              isReq={true}
                              // valueKey="productdescription"
                              // labelKey="productdescription"
                              placeholder="Select Product required by"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Remarks</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Remarks"
                        name="remarks"
                        value={form.remarks}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={500}
                        // disabled={}
                        reqType="onlyAlphbate"
                        title="remarks"
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <hr className="bg_lightblue border-0 h-1px mt-0" />
                    </div>
                    <div className="col-12 mt-4">
                      <h3 class="fs-30 mb-0 gTextPrimary fw-500 ml-2">Additional Fields</h3>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Inquiry Date</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="inquiryDate"
                        name="inquiryDate"
                        value={moment(inquiryDate).format("YYYY-MM-DD")}
                        onChangeFunc={(name, value, error) => {
                          this.onFormChange(name, value, error);
                        }}
                        type="date"
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        // max={moment(new Date()).format("YYYY-MM-DD")}
                        // maxLength={16}
                        disabled={true}
                        isReq={true}
                        // reqType="number"
                        title="inquiryDate"
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Inquiry Source</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Inquiry Source"
                        name="inquirySource"
                        value={form.inquirySource}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={500}
                        disabled={true}
                        reqType="onlyAlphbate"
                        title="inquirySource"
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Customer's Occupation</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Customer's Occupation"
                        name="customerOccupation"
                        value={form.customerOccupation}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={500}
                        // disabled={}

                        reqType="onlyAlphbate"
                        title="customerOccupation"
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2"> Final Calling date by CC Agents</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Final Calling date by CC Agents"
                        name="ccLastCall"
                        value={moment(ccLastCall).format("YYYY-MM-DD")}
                        onChangeFunc={(name, value, error) => {
                          this.onFormChange(name, value, error);
                        }}
                        type="date"
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        // max={moment(new Date()).format("YYYY-MM-DD")}
                        // maxLength={16}
                        disabled={true}
                        isReq={true}
                        // reqType="number"
                        title="ccLastCall"
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2"> First Working Member</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder=" First Working Member"
                        name="firstWorkingMember"
                        value={form.firstWorkingMember}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={500}
                        // disabled={}

                        reqType="onlyAlphbate"
                        title="firstWorkingMember"
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2"> First Working Member Occuption</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder=" first Working Member Occuption"
                        name="firstMemberOccupation"
                        value={form.firstMemberOccupation}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={500}
                        // disabled={}

                        reqType="onlyAlphbate"
                        title="firstMemberOccupation"
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2"> Second Working Member</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder=" second Working Member"
                        name="secondWorkingmember"
                        value={form.secondWorkingmember}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={500}
                        // disabled={}

                        reqType="onlyAlphbate"
                        title="secondWorkingmember"
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2"> Second Member Occupation</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder=" second Member Occupation"
                        name="secondMemberOccupation"
                        value={form.secondMemberOccupation}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={500}
                        // disabled={}

                        reqType="onlyAlphbate"
                        title="secondMemberOccupation"
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Work Place Village</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Work Place Village"
                        name="workPlaceVillage"
                        value={form.workPlaceVillage}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={500}
                        // disabled={}

                        reqType="onlyAlphbate"
                        title="workPlaceVillage"
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2"> Work Place Pincode</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Work Place Pincode"
                        name="workPlacePincode"
                        value={workPlacePincode}
                        onChangeFunc={(name, value, error) => {
                          //   value = replaceComma(value);
                          this.onPincodeChange(name, value, error);
                        }}
                        maxLength={6}
                        error={msgpincode}
                        isReq={true}
                        reqType="number"
                        title="workPlacePincode"
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">First Existing Loans</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder=" First Existing Loans"
                        name="firstExistingLoans"
                        value={form.firstExistingLoans}
                        onChangeFunc={(name, value, error) => {
                          value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={500}
                        // disabled={}

                        reqType="onlyAlphbate"
                        title="firstExistingLoans"
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">First Existing Loan EMI</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="First Existing Loan Emi"
                        name="firstExistingLoanEmi "
                        value={firstExistingLoanEmi}
                        onChangeFunc={(name, value, error) => {
                          value = replaceComma(value);
                          if (checkIfNotDecimal(value)) {
                            this.onFormChange(name, value, error);
                          }
                        }}
                        // error={firstExistingLoanEmi}
                        maxLength="9"
                        isReq={true}
                        reqType="number"
                        title="firstExistingLoanEmi "
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Second Existing Loans</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Second Existing Loans"
                        name="secondExistingLoans"
                        value={form.secondExistingLoans}
                        onChangeFunc={(name, value, error) => {
                          value = replaceComma(value);
                          this.onFormChange(name, value, error);
                        }}
                        maxLength={500}
                        // disabled={}
                        reqType="onlyAlphbate"
                        title="secondExistingLoans"
                      />
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-2  mt-3 d-flex align-items-center">
                      <label class="fs-14 mb-0 gTextPrimary fw-500 ml-2">Second Existing Loan EMI</label>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 mt-4 mr-auto">
                      <Input
                        className="form-control text-center fs-16"
                        placeholder="Second Existing Loan EMI"
                        name="secondExistingLoanEmi "
                        value={secondExistingLoanEmi}
                        onChangeFunc={(name, value, error) => {
                          value = replaceComma(value);
                          if (checkIfNotDecimal(value)) {
                            this.onFormChange(name, value, error);
                          }
                        }}
                        // error={firstExistingLoanEmi}
                        maxLength="9"
                        isReq={true}
                        reqType="number"
                        title="secondExistingLoanEmi "
                      />
                    </div>

                    {/* end */}
                  </div>
                  {/* save */}
                  <div className="row justify-content-end col-md-12 col-sm-12 mt-4 col-lg-12">
                    <div className="row">
                      <button
                        className={`btn btn-secondary btn-rounded ls-1 cursor-pointer mr-4 fs-16 btn-green`}
                        onClick={this.onCancel}
                      >
                        Cancel
                      </button>
                      {(status && status == "Lead Rejected") ||
                      (status && status == "Sent to OMS - FARM") ||
                      (status && status == "Sent to Lending system") ||
                      (status && status == "Credit Review") ||
                      (status && status == "Credit Sanctioned") ||
                      (status && status == "Credit Rejected") ||
                      (status && status == "Single payment advisory generated") ||
                      (status && status == "Bulk payment advisory generated") ||
                      (status && status == "Cancelled") ||
                      (status && status == "Disbursed") ||
                      (status && status == "Demo & installation done") ||
                      (status && status == "Order fulfill") ? null : (
                        <>
                          <button
                            disabled={
                              getAccessId() === 1 ||
                              customerName == "" ||
                              productSelection == null ||
                              form.district == "" ||
                              form.district == null ||
                              form.district == undefined ||
                              ((form.farmService == "" || form.farmService == null || form.farmService == undefined) &&
                                (form.requestedLoanAmount == "" ||
                                  form.requestedLoanAmount == null ||
                                  form.requestedLoanAmount == undefined) &&
                                (form.productType == "" ||
                                  form.productType == null ||
                                  form.productType == undefined)) ||
                              (form.productSelection == "Farm Implement Leasing"
                                ? form.crop == "" ||
                                  form.crop == null ||
                                  form.landArea == null ||
                                  form.landArea == "" ||
                                  form.farmService == null ||
                                  form.farmService == ""
                                  ? true
                                  : false
                                : false) ||
                              leadFollowUpDate == "" ||
                              disSave ||
                              isEmpty ||
                              loading ||
                              Role == "FRO" ||
                              Role == "CFM" ||
                              Role === "Call Centre" ||
                              (productSelection !== "Farm Implement Leasing" && productRequiredBy == "")
                            }
                            className={`btn btn-secondary btn-rounded ls-1 cursor-pointer mr-4 fs-16 btn-green`}
                            onClick={this.saveorupdate}
                          >
                            {loading ? (
                              <React.Fragment>
                                <i class="fa fa-spinner fa-spin"></i>
                                Saving...
                              </React.Fragment>
                            ) : (
                              "Save"
                            )}
                          </button>
                          <button
                            disabled={Role == "FRO" || Role == "CFM" || getAccessId() === 1 || Role === "Call Centre"}
                            className={`btn btn-secondary btn-rounded ls-1 cursor-pointer  fs-16 btn-green`}
                            onClick={this.handleShow}
                          >
                            Reject Lead
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {/* modal */}
                <Modal show={show} onHide={this.handleClose} animation={false}>
                  <Modal.Header closeButton>
                    <Modal.Title>Reject Lead</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">Rejection Reason</label>
                        <lable className="text-danger">*</lable>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 mt-3 mr-auto">
                        <div class="select">
                          <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={rejectDropdown}
                            value={rejectionRemark}
                            title="rejectionRemark"
                            name="rejectionRemark"
                            onChangeFunc={(name, value, error) => {
                              this.onRejectChange(name, value, error);
                            }}
                            isReq={true}
                            valueKey="rejectionRemark"
                            labelKey="rejectionRemark"
                            placeholder="Select Rejection Reason"
                          />
                          {this.state.rejectionRemarkError && <span className="text-danger">Please Select</span>}
                        </div>
                      </div>
                      <div className="col-lg-2"></div>
                      <div className="col-md-6 col-sm-6 col-lg-4 d-flex align-items-center">
                        <label className="fs-14 mb-0  gTextPrimary fw-500">Rejection Remarks</label>
                        <lable className="text-danger">*</lable>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder=" Rejection Remarks"
                          name="otherRemark"
                          value={otherRemark}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onRejectChange(name, value, error);
                          }}
                          maxLength={255}
                          // disabled={}
                          isReq={true}
                          // reqType="alphaNumeric"
                          title="otherRemark"
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={this.handleReject}
                      disabled={!this.state.otherRemark || !this.state.rejectionRemark}
                    >
                      Reject Lead
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </section>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Updatelead;
