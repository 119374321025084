//Sync action
export const syncLogin = (obj, type) => {
  let userData = ""
  if (obj && obj.token) {
    userData = parseJwt(obj.token);
    console.log("userData", userData)
  }
  localStorage.setItem("accessToken", obj && obj.token);
  obj.userData = userData
  return { type: "login", payload: obj };
};

export const syncLogout = obj => {

  localStorage.setItem("accessToken", "");
  return { type: "logout", payload: {} };
};

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};
//Async action
