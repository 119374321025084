import React, { useState } from "react";
import { Link } from "react-router-dom";

const NavigationBar = props => {
  const { isdisbursement,setIsdisbursement,setIsincentive } = props;

  const incentiveNav = () => {
    setIsdisbursement(false);
    setIsincentive(true);
  };
  const disbursementNav = () => {
    setIsincentive(false);
    setIsdisbursement(true);
  };

  return (
    <div className="container">
      <div className="row align-items-center">
        {isdisbursement ? (
          <div className="breadcrums1 col-lg-4 col-md-6">
            <ul>
              <li  >Disbursements</li>
            </ul>
            {/* <ul>
              <li onClick={incentiveNav} >Incentive</li>
            </ul> */}
          </div>
        ) 
        : (
          <div className="breadcrums1 col-lg-4 col-md-6">
            <ul>
              <li>Incentive</li>
            </ul>
            <ul>
              <li onClick={disbursementNav}>Disbursements</li>
            </ul>
          </div>
        )
        }
        { <div className="col-lg-4 col-md-3 dashboard-title">Dashboard</div>}

        <div className="col-lg-3 col-md-2 lead-module-button">
          <Link to="/Leadmodule">
            <div>Lead Module</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
