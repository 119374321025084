import React from "react";
import { Link, withRouter } from "react-router-dom";

class BreadCrumbs extends React.Component {
  constructor() {
    super();
  }

  render() {
    let { match, name1, name2, url1, url2, history } = this.props;
    return (
      <React.Fragment>
        <section className="bg_l-secondary pt-4">
          <div className="container  ">
            <div className="d-flex justify-content-start align-items-center">
              <div className="breadcrums d-flex align-items-center flex-wrap">
                <ul>
                  <li className="mr-1 active">
                    <Link to={url1}>{name1}</Link>
                  </li>
                  <li className="mr-1">
                    <Link to={url2}>{name2}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default withRouter(BreadCrumbs);
