import React, { useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import { API_URL } from "../../../Utility/Config";
import moment from "moment";

const DisbursementBCO = props => {
  const [months, setMonths] = useState([]);
  const [dropdowntitle, setDropdowntitle] = useState("");
  const [bCOdisbursements, setBCODisburstments] = useState({});
  const [bCOCollection, setBCOCollection] = useState({});
  var check = moment(new Date(), "YYYY/MM/DD");

  var monthdata = check.format("M");
  var day = check.format("D");
  var year = check.format("YY");
  var dateObj = new Date();
  var currentMonth = dateObj.getUTCMonth() + 1;
  var str = dateObj.toString();
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var d = new Date();
  var monthName = month[d.getMonth()];
  const [monthItem, setMonthItem] = useState(month[monthdata - 1].substring(0, 3) + "-" + year);

  useEffect(() => {
    const leadData = localStorage.getItem("leadData");
    const token = JSON.parse(leadData).leadAccessToken;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    fetch(`${API_URL}/months`, requestOptions)
      .then(res => res.json())
      .then(res => {
        setMonths(res);
        console.log(res);
        setDropdowntitle(res[0].months);
        const employeeId = JSON.parse(leadData).leadEmployeeId;

        const params = new URLSearchParams({
          employeeId,
          month: res[0].months,
        });
        let fullmonth = month.filter(item => {
          return item.includes(monthItem.split("-")[0]);
        });
        fetch(`${API_URL}/sales/disburstments/report?employeeId=${employeeId}&month=${fullmonth[0]}`, requestOptions)
          .then(res => res.json())
          .then(res => {
            setBCODisburstments(res);
          })
          .catch(err => console.log(err));

        fetch(`${API_URL}/collections/report?employeeId=${employeeId}&month=${fullmonth[0]}`, requestOptions)
          .then(res => res.json())
          .then(res => {
            setBCOCollection(res);
          })
          .catch(err => console.log(err));
      });
  }, []);

  useEffect(() => {
    const leadData = localStorage.getItem("leadData");
    const token = JSON.parse(leadData).leadAccessToken;
    const employeeId = JSON.parse(leadData).leadEmployeeId;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    let fullmonth = month.filter(item => {
      return item.includes(monthItem.split("-")[0]);
    });
    fetch(`${API_URL}/sales/disburstments/report?employeeId=${employeeId}&month=${fullmonth[0]}`, requestOptions)
      .then(res => res.json())
      .then(res => {
        setBCODisburstments(res);
      })
      .catch(err => console.log(err));

    fetch(`${API_URL}/collections/report?employeeId=${employeeId}&month=${fullmonth[0]}`, requestOptions)
      .then(res => res.json())
      .then(res => {
        setBCOCollection(res);
      })
      .catch(err => console.log(err));
  }, [monthItem]);

  const formatIndianCurrency = val => {
    console.log("val------", val);
    let isNegative = val != "" && val != undefined && val != null ? val.toString().includes("-") : false;
    if (isNegative) {
      val = val.replace(/-/gi, "");
    }
    let temp = val;
    temp = temp.toString();
    let afterPoint = "";
    if (temp.indexOf(".") > 0) afterPoint = temp.substring(temp.indexOf("."), temp.length);
    if (temp !== "-") {
      temp = isNaN(parseInt(temp)) ? "" : parseInt(temp);
    }
    temp = temp.toString();
    let lastThree = temp.substring(temp.length - 3);
    let otherNumbers = temp.substring(0, temp.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
    if (isNegative) {
      res = "-" + res;
    }
    return res;
  };
  return (
    <div className="container dis-container">
      <div className="row mt-5 mb-4">
        <Dropdown className="col-4 mr-4">
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
            {monthItem}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {months.map(item => (
              <Dropdown.Item
                onClick={() => {
                  setMonthItem(item.month);
                }}
                key={item.id}
              >
                {item.month}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <h3 className="ml-5">Disbursement Details</h3>
      </div>
      <Table
        striped
        rounded
        bordered
        hover
        responsive
        size="sm"
        style={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          overflow: "hidden",
          border: "2px solid #dee2e6",
          "margin-bottom": "40px",
        }}
      >
        <thead>
          <tr className="text-center bg_d-primary text-white">
            <th>Disbursement Amount MTD by Branch</th>
            <th>Disbursement Amount QTD by Branch</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td>
              <span>&#8377;</span>
              {bCOdisbursements.mtdDisbursement
                ? formatIndianCurrency(
                    bCOdisbursements.mtdDisbursement
                      .toString()
                      .split(".")
                      .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                      .join(".")
                  )
                : 0}
            </td>
            <td>
              <span>&#8377;</span>
              {bCOdisbursements.qtdDisbursement
                ? formatIndianCurrency(
                    bCOdisbursements.qtdDisbursement
                      .toString()
                      .split(".")
                      .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                      .join(".")
                  )
                : 0}
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="row h5 justify-content-center mt-5">NACH Details</div>
      <Table
        striped
        rounded
        bordered
        hover
        responsive
        size="sm"
        style={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          overflow: "hidden",
          border: "2px solid #dee2e6",
          "margin-bottom": "40px",
        }}
      >
        <thead>
          <tr className="text-center bg_d-primary text-white">
            <th>NACH Details</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td className="font-weight-bold">Number of NACH in Quater</td>
            <td>{bCOdisbursements.qtdDueNACHCount == null ? "0" : bCOdisbursements.qtdDueNACHCount}</td>
          </tr>
          <tr className="text-center">
            <td className="font-weight-bold">Number of NACH registered in Quater</td>
            <td>{bCOdisbursements.qtdRegisteredNACHCount == null ? "0" : bCOdisbursements.qtdRegisteredNACHCount}</td>
          </tr>
        </tbody>
      </Table>
      <div className="row h5 justify-content-center">Collection Details</div>
      <Table
        striped
        rounded
        bordered
        hover
        responsive
        size="sm"
        style={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          overflow: "hidden",
          border: "2px solid #dee2e6",
          "margin-bottom": "40px",
        }}
      >
        <thead>
          <tr className="text-center bg_d-primary text-white">
            <th>Total Amount of Demand Collected Until QTD</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td>
              <span>&#8377;</span>
              {bCOCollection.qtdTotalCollectedAmount
                ? formatIndianCurrency(
                    bCOCollection.qtdTotalCollectedAmount
                      .toString()
                      .split(".")
                      .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                      .join(".")
                  )
                : 0}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default DisbursementBCO;
