import React, { useState, useEffect, useRef } from "react";
import { cloneDeep } from "lodash";
import { public_url } from "../../Utility/Constant";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Loader } from "../../Component/Loader";
import { NoDataFound } from "../../Component/NoDataFound";
import { fetchingListOfExceptionByEmployeeId } from "../../Utility/Services/Bulkupload";
import { FileUrl } from "../../Utility/Config";

export default function Exception() {
  const [searchloading, setsearchloading] = useState(false);
  const [searchString, setsearchString] = useState("");
  const [getData, setgetData] = useState([]);
  const [count, setcount] = useState("");
  const [excelPath, setexcelPath] = useState("");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    let leadData = JSON.parse(localStorage.getItem("leadData"));
    let obj = {
      employeeId: leadData && leadData.leadEmployeeId,
      searchString: searchString,
    };
    setsearchloading(true);
    fetchingListOfExceptionByEmployeeId(obj).then(res => {
      if (res.error) {
        setsearchloading(false);
        return;
      }
      if (res.data.error) {
        setsearchloading(false);
        setcount(res?.data?.count);
      } else {
        setgetData(res?.data?.data);
        setcount(res?.data?.count);
        setexcelPath(res?.data?.excelPath);
        setsearchloading(false);
      }
    });
  };

  const handleChange = event => {
    setsearchString(event.target.value);
  };

  const Searching = () => {
    getAllData();
  };

  console.log("abc", getData);
  return (
    <div>
      <div className="backToDashboard py-2">
        <div className="d-flex justify-content-between container-fluid">
          <Link to={public_url.Leadmodule}>Home</Link>
        </div>
      </div>
      <Row className="mt-5">
        <Col sm={2}></Col>
        <Col sm={2}>
          <div className="fw-600 ml-3 mb-3" style={{ color: "#1e1f63" }}>
            {" "}
            Total Count: {count == 0 ? "0" : count}
          </div>
        </Col>
        <Col sm={7}>
          <div className="col-sm-8 col-lg-8 col-md-8 col-xl-8 create-lead-form">
            <input
              type="text"
              className="pr-4 form-control form-control-md fs-16 bg-white "
              placeholder="Search by Rec ID/Customer Name/Mobile No/Product Type/Product Name/Requested Lone Amount/Follow-up Date/Lead Generate Date/Lead Generator/Remarks"
              value={searchString}
              onChange={handleChange}
            />
            <span className="cursor-pointer">
              <i class="fa fa-search mt-1" aria-hidden="true" onClick={Searching}></i>
            </span>
          </div>
        </Col>
        <Col sm={1}></Col>
      </Row>
      <section>
        {searchloading ? (
          <Loader />
        ) : (
          <div className="p-5">
            {count == 0 ? (
              <NoDataFound />
            ) : (
              <div
                className="table-responsive disbursment p-4"
                style={{
                  backgroundColor: "#fff",
                }}
              >
                <table class="table table-bordered table-striped table-sm">
                  <thead className="text-primary">
                    <tr className="text-center bg_d-primary text-white" style={{ whiteSpace: "nowrap" }}>
                      <th scope="col">Rec ID</th>
                      <th scope="col">Customer Name</th>
                      <th scope="col">Mobile No</th>
                      <th scope="col">Product Type</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Requested Loan Amount</th>
                      <th scope="col">Follow-up Date</th>
                      {/* <th scope="col">Lead Generate Date</th> */}
                      <th scope="col">Lead Generator</th>
                      <th scope="col">Assigned User</th>
                      <th scope="col">Remarks</th>
                      <th scope="col">Is qualified?</th>
                    </tr>
                  </thead>
                  {getData?.map(res => (
                    <tbody className="text-center">
                      <tr>
                        <td>{res?.id}</td>
                        <td>{res?.customerName}</td>
                        <td>{res?.mobileNumber1}</td>
                        <td>{res?.productSelection}</td>
                        <td>{res?.productSelection == "Consumer Durables" ? res?.productType : res?.farmService}</td>
                        <td>{res?.requestedLoanAmount ? res?.requestedLoanAmount : "NA"}</td>
                        <td>{res?.leadFollowUpDate}</td>
                        <td>{res?.leadCreatedDate}</td>
                        <td>{res?.leadGenerator}</td>
                        <td>{res?.assignedUser}</td>
                        <td style={{ textAlign: "left" }}>{res?.remarks}</td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            )}
          </div>
        )}
      </section>
      {count == 0 || searchloading ? null : (
        <Row className="ml-5 mt-2 mb-5">
          <Col sm={1}></Col>
          <Col sm={2} className="mt-3">
            <Button variant="danger" style={{ borderRadius: "25px" }}>
              <Link to={public_url.Leadmodule}>Back to lead list</Link>
            </Button>
          </Col>
          <Col sm={4} className="mt-3">
            <a href={`${window.location.protocol}//${window.location.hostname}/${excelPath}`} target="_blank">
              <Button variant="success" style={{ borderRadius: "25px" }}>
                Export To Excel
              </Button>
            </a>
          </Col>
          <Col sm={5}></Col>
        </Row>
      )}
    </div>
  );
}
