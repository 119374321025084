import React, { Component } from 'react';
import BreadCrumbs from '../../Component/BreadCrumbs/index';
import { Select, Input, TextArea, File } from '../../Component/Input';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { public_url, formatIndianCurrency } from '../../Utility/Constant';
import {
  getCDProductCatalogue,
  getCdProductListing,
  rejectLeadByLeadId,
  findAllRejectionRemarksDropDownforCDProducts,
  saveCdProductDetails,
  findByskucode,
} from '../../Utility/Services/Leadmanage';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../CustList/style.css';
toast.configure();

const initForm = {
  emi: '',
  productOption: '',
  brandName: '',
  productId: '',
  vendorEmail: '',
  vendorId: '',
  subCategoryId: '',
  vendorName: '',
  categoryName: '',
  productName: '',
  subCategoryName: '',
  brandId: '',
  skuCode: '',
  categoryId: '',
};

// let productAttribute = [

//  ]

export class CatalogueCard extends Component {
  state = {
    form: cloneDeep(initForm),
    cdProductImagesList: [],
    cdProductVideosList: [],
    productAttribute: [],
    show: false,
    Advertisement1: [],
    Advertisement2: [],
    AdvertisementData: [],
    AdvertisementSkuCode1: '',
    AdvertisementSkuCode2: '',
    loadingForProceedLoan: false,
    rejectionRemark: '',
    otherRemark: '',
    rejectDropdown: [],
  };

  componentDidMount() {
    let { match } = this.props;
    this.CDCatalogue(match.params.skuCode);
    this.findAdvertisement(match.params.skuCode);
    this.AllRejectionRemarksDropDownCDProducts();
  }

  //cd product cataloge api
  CDCatalogue = skuCode => {
    let { form, productId, AdvertisementSkuCode1 } = this.state;
    let { match } = this.props;
    let obj = {
      skuCode: skuCode,
    };
    console.log('ob', obj);

    getCDProductCatalogue(obj).then(res => {
      console.log('getCDProductCatalogue', res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          form: res.data && res.data.data && res.data.data.cdProductDetails,

          productAttribute:
            res.data &&
            res.data.data &&
            res.data.data.cdProductDetails &&
            res.data.data.cdProductDetails.productAttribute,

          cdProductImagesList: res.data && res.data.data && res.data.data.cdProductImagesList,

          cdProductVideosList: res.data && res.data.data && res.data.data.cdProductVideosList,
        });
      }
    });
  };

  //save cd product api
  onSaveProduct = () => {
    let { form, productId, productAttribute, loadingForProceedLoan } = this.state;
    const leadData = JSON.parse(localStorage.getItem('leadData'));
    let { match } = this.props;
    console.log('mt', match);
    let formData = {
      employeeid: leadData.leadEmployeeId,
      leadId: match.params.leadId,
      vendorId: form.vendorId,
      brandId: form.brandId,
      catId: form.categoryId,
      subCatId: form.subCategoryId,
      productOption: form.productOption,
      emi: form.emi,
      prodAttrMapRequestJson: productAttribute,
      skucode: form.skuCode,
      productType: match.params.productType,
    };
    this.setState({ loadingForProceedLoan: true });
    saveCdProductDetails(formData).then(res => {
      if (res && res.error) {
        this.setState({ loadingForProceedLoan: false });
        return;
      } else if (res && res.data && res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loadingForProceedLoan: false });
        return;
      } else {
        toast.success(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 4000,
        });
        this.setState({ loadingForProceedLoan: false });
        this.props.history.push(`${public_url.Leadmodule}`);
      }
    });
  };

  //rejection remarks if any, reject product
  AllRejectionRemarksDropDownCDProducts = () => {
    findAllRejectionRemarksDropDownforCDProducts().then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          rejectDropdown: res.data.data,
        });
      }
    });
  };

  onFormChange = (name, value, error = undefined) => {
    this.state[name] = value;
    this.setState({
      state: this.state,
    });
  };

  handleReject = () => {
    let { rejectionRemark, otherRemark } = this.state;
    let { match } = this.props;
    let obj = {
      leadId: match.params.leadId,
      rejectionRemark: rejectionRemark,
      otherRemark: otherRemark,
    };
    rejectLeadByLeadId(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        toast.success(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 4000,
        });
        this.props.history.push(`${public_url.Leadmodule}`);
      }
    });
  };

  handleShow = handleShow => {
    this.setState({ show: !this.state.show });
  };

  redirectToPlaceOrder = () => {
    this.props.history.push(`${this.props.location.pathname}/place-order`);
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  onCancel = () => {
    let { match } = this.props;
    this.props.history.push(
      `${public_url.Cdproduct}/${match.params.leadId}/${match.params.productSelection}/${match.params.productType}`
    );
  };

  findAdvertisement = skuCode => {
    let { form, productId, Advertisement1, Advertisement2, AdvertisementData } = this.state;
    let { match } = this.props;
    console.log('mt', match);
    let obj = {
      skucode: skuCode, //product uinque code
    };
    console.log('ob', obj);

    findByskucode(obj).then(res => {
      console.log('getCDProductCatalogue', res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
        this.setState({ AdvertisementData: [] });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        console.log('addd', res?.data?.data[0]?.skuCodeDisplayAdvList);
        this.setState({
          AdvertisementData: res?.data?.data[0]?.skuCodeDisplayAdvList,
        });

        let add = res?.data?.data[0]?.skuCodeDisplayAdvList?.forEach((e, index) => this.AdvertisementOne(index));
      }
    });
  };

  AdvertisementOne = skuCodeIndex => {
    let { form, productId, AdvertisementData, Advertisement1 } = this.state;
    let { match } = this.props;
    console.log('mt', match);
    let obj = {
      skuCode: AdvertisementData[skuCodeIndex],
    };
    console.log('ob', obj);

    getCDProductCatalogue(obj).then(res => {
      console.log('getCDProductCatalogue', res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 4000,
        // });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          [`Advertisement${skuCodeIndex + 1}`]: res?.data?.data,
        });
      }
    });
  };

  getSkuCode1 = () => {
    let { AdvertisementData, AdvertisementSkuCode1 } = this.state;
    toast.success('Product change successfully', {
      type: toast.TYPE.SUCCESS,
      autoClose: 4000,
    });
    this.CDCatalogue(AdvertisementData[0]);
    this.findAdvertisement(AdvertisementData[0]);
    window.scrollTo(0, 0);
  };

  getSkuCode2 = () => {
    let { AdvertisementData, AdvertisementSkuCode2 } = this.state;
    toast.success('Product change successfully', {
      type: toast.TYPE.SUCCESS,
      autoClose: 4000,
    });
    this.CDCatalogue(AdvertisementData[1]);
    this.findAdvertisement(AdvertisementData[1]);
    window.scrollTo(0, 0);
  };

  render() {
    let {
      form,
      cdProductImagesList,
      cdProductVideosList,
      show,
      rejectDropdown,
      rejectionRemark,
      otherRemark,
      productAttribute,
      Advertisement1,
      Advertisement2,
      AdvertisementData,
      loadingForProceedLoan,
    } = this.state;
    let {
      brandName,
      categoryName,
      subCategoryName,
      productOption,

      emi,
    } = form;
    let { match } = this.props;
    let { productSelection } = match.params;
    let leadData = JSON.parse(localStorage.getItem('leadData'));
    console.log('addata', Advertisement1, Advertisement2, AdvertisementData);
    let RoleData = JSON.parse(localStorage.getItem('Role'));
    return (
      <React.Fragment>
        <BreadCrumbs
          name1={'Lead List'}
          name2={'Catalogue'}
          url1={`${public_url.Leadmodule}`}
          url2={`${this.props.match.url}`}
        />
        <section className="px-2 px-md-5 pt-4 pb-5 dashboard_div bg_l-secondary">
          <div className="d-flex justify-content-start align-items-center">
            <section className="py-4 position-relative bg_l-secondary w-100">
              <div className=" bg-white disbursment">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="row p-5">
                      <div className="col-12 pl-4 pr-4 text-center">
                        <h5
                          className="divider gradient pt-1"
                          style={{
                            backgroundColor: '#f89839',
                            color: 'black',
                            height: 40,
                          }}
                        >
                          CD product Catalogue
                        </h5>
                      </div>

                      {cdProductImagesList &&
                        cdProductImagesList.map(res => (
                          <div className="col-lg-6 col-sm-12 col-md-12 mt-4" key={res.id}>
                            <div class="card bg-white">
                              <a
                                href={`${window.location.protocol}//${window.location.hostname}/${res.url}`}
                                target="_blank"
                              >
                                <img
                                  src={`${window.location.protocol}//${window.location.hostname}/${res.url}`}
                                  class="card-img-top"
                                  alt="new"
                                />
                                {/* <div class="card-body">
                                <p class="card-text">{res.name}</p>
                              </div> */}
                              </a>
                            </div>
                          </div>
                        ))}

                      {cdProductVideosList &&
                        cdProductVideosList.map(res => (
                          <div className="col-12 mt-4">
                            <div class="card bg-white">
                              <h5 class="card-header text-center">Product Video</h5>
                              <div class="card-body">
                                <video width="520" height="200" controls>
                                  <source
                                    src={`${window.location.protocol}//${window.location.hostname}/${res.url}`}
                                    type="video/mp4"
                                  />
                                  <source
                                    src={`${window.location.protocol}//${window.location.hostname}/${res.url}`}
                                    type="video/ogg"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="container disbursment">
                      <div className="row p-5 ml-5 mr-3">
                        <div className="col-md-6 col-sm-6 col-lg-6 d-flex align-items-center">
                          <label class="fs-16 mb-0 gTextPrimary fw-500">Brand</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                          <Input
                            className="form-control text-center fs-14"
                            placeholder="Brand"
                            name="brandName"
                            value={brandName}
                            onChangeFunc={(name, value, error) => {
                              //   value = replaceComma(value);
                              this.onFormChange(name, value, error);
                            }}
                            // maxLength={16}
                            // disabled={}
                            isReq={true}
                            disabled={true}
                            // reqType="alphaNumeric"
                            title="brandName"
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 d-flex align-items-center">
                          <label class="fs-16 mb-0 gTextPrimary fw-500">Category</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                          <Input
                            className="form-control text-center fs-14"
                            placeholder="Category"
                            name="categoryName"
                            value={categoryName}
                            onChangeFunc={(name, value, error) => {
                              //   value = replaceComma(value);
                              this.onFormChange(name, value, error);
                            }}
                            // maxLength={16}
                            // disabled={}
                            isReq={true}
                            disabled={true}
                            // reqType="alphaNumeric"
                            title="categoryName"
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 d-flex align-items-center">
                          <label class="fs-16 mb-0 gTextPrimary fw-500">Sub-category</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                          <Input
                            className="form-control text-center fs-14"
                            placeholder="Sub-category"
                            name="subCategoryName"
                            value={subCategoryName}
                            onChangeFunc={(name, value, error) => {
                              //   value = replaceComma(value);
                              this.onFormChange(name, value, error);
                            }}
                            // maxLength={16}
                            // disabled={}
                            isReq={true}
                            disabled={true}
                            // reqType="alphaNumeric"
                            title="subCategoryName"
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 d-flex align-items-center">
                          <label class="fs-16 mb-0 gTextPrimary fw-500">Product option</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                          <Input
                            className="form-control text-center fs-14"
                            placeholder="Product option"
                            name="productOption"
                            value={productOption}
                            onChangeFunc={(name, value, error) => {
                              //   value = replaceComma(value);
                              this.onFormChange(name, value, error);
                            }}
                            // maxLength={16}
                            // disabled={}
                            isReq={true}
                            disabled={true}
                            // reqType="alphaNumeric"
                            title="productOption"
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 d-flex align-items-center">
                          <label class="fs-16 mb-0 gTextPrimary fw-500">Product attribute</label>
                        </div>

                        <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                          <ul>
                            {
                              (console.log('p', productAttribute),
                              productAttribute?.map((res, i) => (
                                <li key={i}>
                                  <p>
                                    {res.attribute}:
                                    {res.values.map((e, i) => (i !== res.values.length - 1 ? ` ${e} | ` : `${e}`))}
                                  </p>
                                </li>
                              )))
                            }
                          </ul>
                        </div>

                        <div className="col-md-6 col-sm-6 col-lg-6 d-flex align-items-center">
                          <label class="fs-16 mb-0 gTextPrimary fw-500">EMI</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                          <Input
                            className="form-control text-center fs-14"
                            placeholder="EMI"
                            name="emi"
                            value={emi}
                            onChangeFunc={(name, value, error) => {
                              //   value = replaceComma(value);
                              this.onFormChange(name, value, error);
                            }}
                            // maxLength={16}
                            // disabled={}
                            isReq={true}
                            disabled={true}
                            // reqType="alphaNumeric"
                            title="emi"
                          />
                        </div>

                        <div className="row justify-content-end col-md-12 col-sm-12 mt-4 col-lg-12">
                          <div className="row">
                            <button
                              //   disabled={}
                              className={`btn btn-secondary btn-rounded ls-1 cursor-pointer mr-3  fs-16 btn-green `}
                              onClick={this.onCancel}
                            >
                              Back
                            </button>
                            <button
                              disabled={
                                loadingForProceedLoan ||
                                (RoleData == 'Call Centre' && productSelection == 'Consumer Durables')
                              }
                              className={`btn btn-secondary btn-rounded ls-1 cursor-pointer mr-3 fs-16 btn-green`}
                              onClick={this.onSaveProduct}
                            >
                              {loadingForProceedLoan ? (
                                <React.Fragment>
                                  <i class="fa fa-spinner fa-spin"></i>
                                  Loading...
                                </React.Fragment>
                              ) : (
                                'Proceed for loan'
                              )}
                            </button>
                            <button
                              disabled={RoleData == 'Call Centre' && productSelection == 'Consumer Durables'}
                              className={`btn btn-secondary btn-rounded ls-1 cursor-pointer  mr-3 fs-16 btn-green`}
                              onClick={this.handleShow}
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <section>
                  {AdvertisementData?.length == 0 ? null : (
                    <Row>
                      <Col sm={12}>
                        <div className="col-12 pl-5 pr-4 mt-4 text-center">
                          <h5
                            className="divider gradient pt-2"
                            style={{
                              backgroundColor: '#f89839',
                              color: 'black',
                              height: 54,
                            }}
                          >
                            You might also like. Explore these as well.!
                          </h5>
                        </div>
                        <Row>
                          {Advertisement1?.length == 0 ? null : (
                            <Col sm={6}>
                              {[Advertisement1] &&
                                [Advertisement1].map(res => (
                                  <div className="col-lg-6 col-sm-12 col-md-12 mt-4 mb-4 pl-5">
                                    <div class="card-body">
                                      <a
                                        className="card-text"
                                        onClick={this.getSkuCode1}
                                        style={{
                                          cursor: 'pointer',
                                          fontSize: '20px',
                                        }}
                                      >
                                        {res?.cdProductDetails?.productName}
                                      </a>
                                    </div>
                                    <div class="card bg-white">
                                      <img
                                        src={`${window.location.protocol}//${window.location.hostname}/${
                                          res &&
                                          res.cdProductImagesList &&
                                          res.cdProductImagesList[0] &&
                                          res.cdProductImagesList[0].url
                                        }`}
                                        class="card-img-top"
                                        alt="new"
                                      />
                                    </div>
                                  </div>
                                ))}
                            </Col>
                          )}
                          {AdvertisementData?.length == 2 ? (
                            <Col sm={6}>
                              {[Advertisement2] &&
                                [Advertisement2].map(res => (
                                  <div className="col-lg-6 col-sm-12 col-md-12 mt-4 mb-4 pl-5">
                                    <div class="card-body">
                                      <a
                                        className="card-text"
                                        onClick={this.getSkuCode2}
                                        style={{
                                          cursor: 'pointer',
                                          fontSize: '20px',
                                        }}
                                      >
                                        {res?.cdProductDetails?.productName}
                                      </a>
                                    </div>
                                    <div class="card bg-white">
                                      <img
                                        src={`${window.location.protocol}//${window.location.hostname}/${
                                          res &&
                                          res.cdProductImagesList &&
                                          res.cdProductImagesList[0] &&
                                          res.cdProductImagesList[0].url
                                        }`}
                                        class="card-img-top"
                                        alt="new"
                                      />
                                    </div>
                                  </div>
                                ))}
                            </Col>
                          ) : null}
                        </Row>
                      </Col>
                    </Row>
                  )}
                </section>

                {/* reject lead*/}
                <Modal show={show} onHide={this.handleClose} animation={false}>
                  <Modal.Header closeButton>
                    <Modal.Title>Reject Lead</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">Rejection Reason</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 mt-3 mr-auto">
                        <div class="select">
                          <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={rejectDropdown}
                            value={rejectionRemark}
                            title="rejectionRemark"
                            name="rejectionRemark"
                            onChangeFunc={(name, value, error) => {
                              this.onFormChange(name, value, error);
                            }}
                            isClearable={true}
                            //  error={selectedSpokeError}
                            isReq={true}
                            valueKey="rejectionRemark"
                            labelKey="rejectionRemark"
                            placeholder="Rejection Reason"
                          />
                          {/* <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={rejectDropdown}
                            value={rejectionRemark}
                            title="rejectionRemark"
                            name="rejectionRemark"
                            onChangeFunc={(name, value, error) => {
                              this.onInputChange(name, value, error);
                            }}
                            //   onBlur={() => { }}
                            isReq={true}
                            valueKey="rejectionRemark"
                            labelKey="rejectionRemark"
                            placeholder="Select Rejection Remark"
                          /> */}
                        </div>
                      </div>
                      <div className="col-lg-2"></div>
                      <div className="col-md-6 col-sm-6 col-lg-4 d-flex align-items-center">
                        <label class="fs-14 mb-0  gTextPrimary fw-500">Rejection Remarks</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Rejection Remarks"
                          name="otherRemark"
                          maxLength={255}
                          value={otherRemark}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          // disabled={}
                          isReq={true}
                          // reqType="alphaNumeric"
                          title="otherRemark"
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={this.handleReject}
                      disabled={!this.state.otherRemark || !this.state.rejectionRemark}
                    >
                      Reject Lead
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* reject lead*/}
                <Modal show={show} onHide={this.handleClose} animation={false}>
                  <Modal.Header closeButton>
                    <Modal.Title>save Lead</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">Rejection Reason</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 mt-3 mr-auto">
                        <div class="select">
                          <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={rejectDropdown}
                            value={rejectionRemark}
                            title="rejectionRemark"
                            name="rejectionRemark"
                            onChangeFunc={(name, value, error) => {
                              this.onFormChange(name, value, error);
                            }}
                            isClearable={true}
                            //  error={selectedSpokeError}
                            isReq={true}
                            valueKey="rejectionRemark"
                            labelKey="rejectionRemark"
                            placeholder="Rejection Reason"
                          />
                          {/* <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={rejectDropdown}
                            value={rejectionRemark}
                            title="rejectionRemark"
                            name="rejectionRemark"
                            onChangeFunc={(name, value, error) => {
                              this.onInputChange(name, value, error);
                            }}
                            //   onBlur={() => { }}
                            isReq={true}
                            valueKey="rejectionRemark"
                            labelKey="rejectionRemark"
                            placeholder="Select Rejection Remark"
                          /> */}
                        </div>
                      </div>
                      <div className="col-lg-2"></div>
                      <div className="col-md-6 col-sm-6 col-lg-4 d-flex align-items-center">
                        <label class="fs-14 mb-0  gTextPrimary fw-500">Rejection Remarks</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Rejection Remarks"
                          name="otherRemark"
                          maxLength={255}
                          value={otherRemark}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onFormChange(name, value, error);
                          }}
                          // disabled={}
                          isReq={true}
                          // reqType="alphaNumeric"
                          title="otherRemark"
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={this.handleReject}
                      disabled={!this.state.otherRemark || !this.state.rejectionRemark}
                    >
                      Reject Lead
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </section>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default CatalogueCard;
