import "./style.css";

import React, { Component } from "react";
import { formatIndianCurrency, public_url } from "../../Utility/Constant";

import { Input } from "../../Component/Input";
import { Link } from "react-router-dom";
import { Loader } from "../../Component/Loader";
import { NoDataFound } from "../../Component/NoDataFound";
import Pagination from "react-js-pagination";
import { cloneDeep } from "lodash";
import { getAccessId } from "../../Utility/Helper";
import { getList } from "../../Utility/Services/LMSCaselist";

const initForm = {
  status: "wip",
  searchString: "",
};

export class index extends Component {
  constructor() {
    super();
    this.state = {
      listView: 2,
      form: cloneDeep(initForm),
      allList: [],
      loading: false,
      show: false,
      leadList: true,
      count: null,
      perPage: 10,
      activePage: 1,
      status: "wip",
      role: "",
    };
  }

  componentDidMount() {
    const { match, location, history } = this.props;

    // history.listen((newLocation, action) => {
    //   if (action === "PUSH") {
    //     if (
    //       newLocation.pathname !== this.currentPathname ||
    //       newLocation.search !== this.currentSearch
    //     ) {
    //       // Save new location
    //       this.currentPathname = newLocation.pathname;
    //       this.currentSearch = newLocation.search;

    //       // Clone location object and push it to history
    //       history.push({
    //         pathname: newLocation.pathname,
    //         search: newLocation.search,
    //       });
    //     }
    //   } else {
    //     // Send user back if they try to navigate back
    //     history.go(1);
    //   }
    // });
    console.log("check", history.listen);
    this.getallList();
    this.UserByEmployeeId();
  }

  showModal = () => {
    this.setState({
      show: true,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  // gettcse list
  getallList = () => {
    let { form, loading, activePage } = this.state;
    let userData = JSON.parse(localStorage.getItem("leadData"));
    // console.log("user", userData.leadEmployeeId);
    this.setState({
      loading: true,
    });
    form.employeeId = userData.leadEmployeeId;
    let pageNumber = activePage;
    getList(form, pageNumber).then(res => {
      console.log("response", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        this.setState({
          loading: false,
          count: res.data && res.data.count,
        });
        // te(res.data.message);
      } else {
        // ts(res.data.message);
        this.setState({
          allList: res.data && res.data.data,
          loading: false,
          count: res.data && res.data.count,
        });
      }
    });
  };

  Decisioned = () => {
    let { form } = this.state;
    this.setState(
      {
        leadList: false,
        form: { ...form, status: "decisioned" },
        activePage: 1,
      },
      () => this.getallList()
    );
  };

  Wip = () => {
    let { form } = this.state;
    this.setState(
      {
        leadList: true,
        form: { ...form, status: "wip" },
        activePage: 1,
      },
      () => this.getallList()
    );
  };

  search = (name, value, error) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form }, () => {
      if (value == "") {
        this.Searching();
      }
    });
  };

  Searching = e => {
    e && e.preventDefault();
    let { form, loading, activePage } = this.state;
    let userData = JSON.parse(localStorage.getItem("leadData"));
    this.setState({
      loading: true,
    });
    let pageNumber = 1;
    form.employeeId = userData.leadEmployeeId;
    getList(form, pageNumber).then(res => {
      console.log("response", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        this.setState({
          loading: false,
          count: res.data && res.data.count,
        });
        // te(res.data.message);
      } else {
        // ts(res.data.message);
        this.setState({
          allList: res.data && res.data.data,
          loading: false,
          count: res.data && res.data.count,
          activePage: 1,
        });
      }
    });
  };

  Pagination = id => {
    this.setState({ activePage: id }, () => this.getallList());
  };

  UserByEmployeeId = () => {
    const role = localStorage.getItem("Role");
    this.setState({
      role,
    });
  };

  render() {
    let { listView, form, leadList, allList, count, loading, show, role } = this.state;
    let { searchString, status } = form;

    return (
      <React.Fragment>
        <section class="p-3 bg_l-secondary">
          <div className="container">
            <div
              className="col-sm-2 col-lg-2 col-xl-2 col-md-2 fw-600 mt-md-4 mt-lg-4 "
              style={{ color: "#1e1f63", marginLeft: "53%" }}
            >
              {" "}
              Total Count: {count}
            </div>
            <div class="row">
              <div className="breadcrums1 m-4 col-md-2 col-lg-2 col-sm-12">
                {leadList ? (
                  <>
                    <ul>
                      <li
                        // className=""
                        className={`${listView == 2 && "active"}`}
                      >
                        <Link>WIP</Link>
                      </li>
                    </ul>
                    <ul>
                      <li
                        // className=""
                        className={`${listView == 1 && "active"}`}
                        onClick={this.Decisioned}
                      >
                        <Link>DECISIONED</Link>
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <ul>
                      <li
                        // className=""
                        className={`${listView == 1 && "active"}`}
                      >
                        DECISIONED
                      </li>
                    </ul>
                    <ul className="cursor-pointer">
                      <li
                        // className=""
                        className={`${listView == 2 && "active"}`}
                        onClick={this.Wip}
                      >
                        WIP
                      </li>
                    </ul>
                  </>
                )}
              </div>

              <div className="row col-md-9 col-lg-9 col-sm-12 ml-md-2 mr-md-2">
                <div className="col-md-4 col-lg-4 col-sm-4">
                  <form class="form_style-1 mt-4 lead-list-textbox-div" onSubmit={this.Searching}>
                    <div class="form-group row">
                      <div class="col-sm-11 col-lg-11 col-md-11 col-xl-11 create-lead-form">
                        <Input
                          className="pr-4 form-control form-control-md fs-12 bg-white "
                          id="colFormLabelSm"
                          placeholder="Search by Lead ID/Customer Name/Mobile Number/Product Type/Product Name/Requested Loan Amount/Status"
                          name="searchString"
                          value={searchString}
                          title="searchString"
                          isReq={true}
                          onChangeFunc={(name, value, error) => {
                            this.search(name, value, error);
                          }}
                        />
                        <span className="cursor-pointer">
                          <i class="fa fa-search" aria-hidden="true" onClick={this.Searching}></i>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  className="col-sm-3 col-lg-2 col-xl-2 col-md-2 fw-600 mt-4 mb-2 mb-sm-0"
                  style={{ color: "#1e1f63" }}
                >
                  {loading ? null : (
                    <button
                      disabled={getAccessId() === 1}
                      className={"btn btn-rounded ls-1 cursor-pointer fs-12 btn-primary"}
                      onClick={
                        () => this.props.history.push(`${public_url.add_lead}`)
                        // () => this.props.history.push(`${public_url.approve_lead}`)
                      }
                    >
                      Add Lead
                    </button>
                  )}
                </div>
                {(role == "BRO" || role == "RBH" || role == "BCO" || role == "BM") && (
                  <div
                    className="col-sm-3 col-lg-2 col-xl-2 col-md-2 fw-600 mt-4 mb-2 mb-sm-0"
                    style={{ color: "#1e1f63" }}
                  >
                    {loading ? null : (
                      <button
                        disabled={getAccessId() === 1}
                        className={"btn btn-rounded ls-1 cursor-pointer fs-12 btn-primary"}
                        onClick={() => this.props.history.push(`${public_url.dashboard}`)}
                      >
                        Dashboard
                      </button>
                    )}
                  </div>
                )}
                <div
                  className="col-sm-3 col-lg-2 col-xl-2 col-md-2 fw-600 mt-4 mb-2 mb-sm-0"
                  style={{ color: "#1e1f63" }}
                >
                  {loading ? null : role === "FRANCHISE" ||
                    role === "FRO" ||
                    role === "CFM" ||
                    role === "BRO" ||
                    role === "BH" ||
                    role === "BM" ||
                    role === "GRO" ? null : (
                    <button
                      disabled={getAccessId() === 1}
                      className="btn btn-rounded ls-1 cursor-pointer fs-12 btn-primary"
                      onClick={() => this.props.history.push(`${public_url.BulkUpload}`)}
                    >
                      Bulk Upload
                    </button>
                  )}
                </div>
                <div
                  className="col-sm-2 col-lg-2 col-xl-2 col-md-2 fw-600 mt-4 mb-2 mb-sm-0"
                  style={{ color: "#1e1f63" }}
                >
                  {loading ? null : role === "FRANCHISE" ||
                    role === "FRO" ||
                    role === "CFM" ||
                    role === "BRO" ||
                    role === "BH" ||
                    role === "BM" ||
                    role === "GRO" ? null : (
                    <button
                      disabled={getAccessId() === 1}
                      className="btn btn-rounded ls-1 cursor-pointer fs-12 btn-primary"
                      onClick={() => this.props.history.push(`${public_url.Exception}`)}
                    >
                      Exceptions
                    </button>
                  )}
                </div>
                {role === "Call Centre" && (
                  <div
                    className="col-sm-2 col-lg-2 col-xl-2 col-md-2 fw-600 mt-4 mb-2 mb-sm-0"
                    style={{ color: "#1e1f63" }}
                  >
                    {loading ? null : (
                      <button
                        className="btn btn-rounded ls-1 cursor-pointer fs-12 btn-primary"
                        onClick={() => this.props.history.push(`${public_url.chatbotReport}`)}
                      >
                        Chatbot Report
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <>
            {loading ? (
              <Loader />
            ) : (
              <>
                {count == 0 ? (
                  <NoDataFound />
                ) : (
                  <div className="p-3">
                    <div class="table-responsive disbursment" style={{ backgroundColor: "#fff" }}>
                      <table class="table table-bordered table-striped table-sm">
                        <thead className="text-primary">
                          <tr className="text-center bg_d-primary text-white" style={{ whiteSpace: "nowrap" }}>
                            <th scope="col">Lead ID</th>
                            {(role === "FRO" || role === "CFM") && <th scope="col">Franchisee Name</th>}
                            <th scope="col">Customer Name</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Product Type</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Requested Loan Amount</th>
                            <th scope="col">Follow-up Date</th>
                            {/* <th scope="col">Lead Generate Date</th> */}
                            <th scope="col">Lead Generator</th>
                            <th scope="col">Assigned User</th>
                            <th scope="col">Status</th>
                            {/* <th scope="col">Action</th> */}
                            <th scope="col">Is qualified?</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {allList &&
                            allList.map(res => (
                              <tr
                                className="navlink1"
                                onClick={
                                  res && (res.productSelection == "PR1" || (res && res.productSelection == "PR2"))
                                    ? () => this.props.history.push(`${public_url.approve_lead}/${res && res.leadId}`)
                                    : res && (res.status == "Lead sent to BM" || res.status == "Lead sent to CC")
                                    ? () => this.props.history.push(`${public_url.add_lead}/${res && res.leadId}`)
                                    : res && res.status == "Lead creation in Progress" && role == "Call Centre"
                                    ? () => this.props.history.push(`${public_url.update_lead}/${res && res.leadId}`)
                                    : () =>
                                        this.props.history.push(`${public_url.update_lead}/${res && res.leadId}`) ||
                                        (res && res.status == "Lead creation in Progress" && role == "Call Centre")
                                          ? () =>
                                              this.props.history.push(
                                                `${public_url.update_lead_cc}/${res && res.leadId}`
                                              )
                                          : // () =>
                                          //     this.props.history.push(
                                          //       `${public_url.update_lead}/${res && res.leadId}`
                                          //     )) ||
                                          // this.props.history.push(`${public_url.approve_lead}/${res && res.leadId}`)) ||
                                          res && res.status == "Lead creation in Progress" && role == "Call Centre"
                                          ? () =>
                                              this.props.history.push(`${public_url.update_lead}/${res && res.leadId}`)
                                          : (res &&
                                              res.status == "Lead Created" &&
                                              res &&
                                              res.productSelection == "Housing Loans") ||
                                            (res && res.productSelection == "Farm Loans") ||
                                            (res && res.productSelection == "Business Loans") ||
                                            (res && res.productSelection == "Personal Loans")
                                          ? () =>
                                              this.props.history.push(`${public_url.update_lead}/${res && res.leadId}`)
                                          : res && res.productSelection == "Consumer Durables"
                                          ? () =>
                                              this.props.history.push(
                                                `${public_url.Cdproduct}/${res && res.leadId}/${
                                                  res && res.productSelection
                                                }/${res && res.productType}`
                                              )
                                          : (res &&
                                              res.status == "Lead created" &&
                                              // role == "Call Centre" &&
                                              res &&
                                              res.productSelection == "PR1") ||
                                            (res && res.productSelection == "PR2")
                                          ? () =>
                                              this.props.history.push(`${public_url.approve_lead}/${res && res.leadId}`)
                                          : // (() =>
                                            //     this.props.history.push(
                                            //       `${public_url.farm_list}/${res && res.leadId}/${
                                            //         res && res.productSelection
                                            //       }/${res && res.farmService}`
                                            //     ))
                                            //     ||
                                            (res &&
                                              res.status == "Lead Rejected" &&
                                              (() =>
                                                this.props.history.push(
                                                  `${public_url.update_lead}/${res && res.leadId}`
                                                ))) ||
                                            (res &&
                                              res.status == "Sent to Lending system" &&
                                              (() =>
                                                this.props.history.push(
                                                  `${public_url.update_lead}/${res && res.leadId}`
                                                ))) ||
                                            (res &&
                                              res.status == "Sent to OMS - FARM" &&
                                              (() =>
                                                this.props.history.push(
                                                  `${public_url.update_lead}/${res && res.leadId}`
                                                ))) ||
                                            (res &&
                                              res.status == "Single payment advisory generated" &&
                                              (() =>
                                                this.props.history.push(
                                                  `${public_url.update_lead}/${res && res.leadId}`
                                                ))) ||
                                            (res &&
                                              res.status == "Bulk payment advisory generated" &&
                                              (() =>
                                                this.props.history.push(
                                                  `${public_url.update_lead}/${res && res.leadId}`
                                                ))) ||
                                            (res &&
                                              res.status == "Cancelled" &&
                                              (() =>
                                                this.props.history.push(
                                                  `${public_url.update_lead}/${res && res.leadId}`
                                                ))) ||
                                            // (res &&
                                            // (res.status == "Lead sent to BM" || res.status == "Lead sent to CC") &&
                                            // (() =>
                                            //   this.props.history.push(
                                            //     `${public_url.add_lead}/${res && res.leadId}`
                                            //   ))) ||
                                            (res &&
                                              res.status == "Credit Review" &&
                                              (() =>
                                                this.props.history.push(
                                                  `${public_url.update_lead}/${res && res.leadId}`
                                                ))) ||
                                            (res &&
                                              res.status == "Credit Sanctioned" &&
                                              (() =>
                                                this.props.history.push(
                                                  `${public_url.update_lead}/${res && res.leadId}`
                                                ))) ||
                                            (res &&
                                              res.status == "Credit Rejected" &&
                                              (() =>
                                                this.props.history.push(
                                                  `${public_url.update_lead}/${res && res.leadId}`
                                                ))) ||
                                            (res &&
                                              res.status == "Disbursed" &&
                                              (() =>
                                                this.props.history.push(
                                                  `${public_url.update_lead}/${res && res.leadId}`
                                                ))) ||
                                            (res &&
                                              res.status == "Demo & installation done" &&
                                              (() =>
                                                this.props.history.push(
                                                  `${public_url.update_lead}/${res && res.leadId}`
                                                ))) ||
                                            (res &&
                                              res.status == "Order fulfill" &&
                                              (() =>
                                                this.props.history.push(
                                                  `${public_url.update_lead}/${res && res.leadId}`
                                                )))
                                  //  : () =>
                                  // this.props.history.push(`${public_url.update_lead}/${res && res.leadId}`)
                                }
                              >
                                <td>{res.leadId}</td>
                                {(role === "FRO" || role === "CFM") && <td>{res.franchiseeName}</td>}
                                <td>{res.customerName}</td>
                                <td>{res.mobileNumber1}</td>
                                <td>{res.productSelection}</td>
                                <td>{res.farmService ? res.farmService : res.productType ? res.productType : "NA"}</td>
                                <td>
                                  {res.requestedLoanAmount
                                    ? formatIndianCurrency(
                                        res.requestedLoanAmount
                                          .toString()
                                          .split(".")
                                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                                          .join(".")
                                      )
                                    : "NA"}
                                </td>
                                <td>{res.leadFollowUpDate}</td>
                                {/* <td>{res.leadCreatedDate}</td> */}
                                <td>{res.leadGeneratorName}</td>
                                <td>{res.assignedUsers}</td>
                                <td>{res.status}</td>

                                {/* <td className="cursor-pointer">
                                  {res && res.status == "Lead creation in Progress" && (
                                    <Link
                                      to={
                                        role == "Call Centre"
                                          ? `${public_url.update_lead_cc}/${res && res.leadId}`
                                          : `${public_url.update_lead}/${res && res.leadId}`
                                      }
                                    >
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}
                                  {res && res.status == "Lead Created" && (
                                    <Link
                                      to={
                                        (res && res.productSelection == "Housing Loans") ||
                                        (res && res.productSelection == "Farm Loans") ||
                                        (res && res.productSelection == "Business Loans") ||
                                        (res && res.productSelection == "Personal Loans")
                                          ? `${public_url.update_lead}/${res && res.leadId}`
                                          : res && res.productSelection == "Consumer Durables"
                                          ? `${public_url.Cdproduct}/${res && res.leadId}/${
                                              res && res.productSelection
                                            }/${res && res.productType}`
                                          : `${public_url.farm_list}/${res && res.leadId}/${
                                              res && res.productSelection
                                            }/${res && res.farmService}`
                                      }
                                    >
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}
                                  {res && res.status == "Lead Rejected" && (
                                    <Link to={`${public_url.update_lead}/${res && res.leadId}`}>
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}
                                  {res && res.status == "Sent to Lending system" && (
                                    <Link to={`${public_url.update_lead}/${res && res.leadId}`}>
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}
                                  {res && res.status == "Sent to OMS - FARM" && (
                                    <Link to={`${public_url.update_lead}/${res && res.leadId}`}>
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}
                                  {res && res.status == "Single payment advisory generated" && (
                                    <Link to={`${public_url.update_lead}/${res && res.leadId}`}>
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}
                                  {res && res.status == "Bulk payment advisory generated" && (
                                    <Link to={`${public_url.update_lead}/${res && res.leadId}`}>
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}
                                  {res && res.status == "Cancelled" && (
                                    <Link to={`${public_url.update_lead}/${res && res.leadId}`}>
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}
                                  {res && (res.status == "Lead sent to BM" || res.status == "Lead sent to CC") && (
                                    <Link to={`${public_url.update_lead_cc}/${res && res.leadId}`}>
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}

                                  {res && res.status == "Credit Review" && (
                                    <Link to={`${public_url.update_lead}/${res && res.leadId}`}>
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}

                                  {res && res.status == "Credit Sanctioned" && (
                                    <Link to={`${public_url.update_lead}/${res && res.leadId}`}>
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}

                                  {res && res.status == "Credit Rejected" && (
                                    <Link to={`${public_url.update_lead}/${res && res.leadId}`}>
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}
                                  {res && res.status == "Disbursed" && (
                                    <Link to={`${public_url.update_lead}/${res && res.leadId}`}>
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}
                                  {res && res.status == "Demo & installation done" && (
                                    <Link to={`${public_url.update_lead}/${res && res.leadId}`}>
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}
                                  {res && res.status == "Order fulfill" && (
                                    <Link to={`${public_url.update_lead}/${res && res.leadId}`}>
                                      <i class="fas fa-eye" style={{ color: "#1e1f63" }}></i>
                                    </Link>
                                  )}
                                </td> */}
                                <td>{res.isQualified ? res.isQualified : "N"}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.perPage}
                        totalItemsCount={count}
                        pageRangeDisplayed={10}
                        onChange={this.Pagination}
                        innerClass="pagination d-flex justify-content-center align-items-center mt-4"
                        itemClass="mx-2 item"
                        itemClassFirst="itemClassFirst"
                        itemClassPrev="itemClassPrev"
                        itemClassNext="itemClassNext"
                        itemClassLast="itemClassLast"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </section>
        {/* ) : null} */}
      </React.Fragment>
    );
  }
}

export default index;
