import React, { useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import { API_URL } from "../../../Utility/Config";
import moment from "moment";

const DisbursementBM = props => {
  const [months, setMonths] = useState([]);
  const [dropdowntitle, setDropdowntitle] = useState("");
  const [disbursements, setDisburstments] = useState({});
  const [bMCollection, setBMCollection] = useState({});
  const [role, setRole] = useState("");

  var check = moment(new Date(), "YYYY/MM/DD");

  var monthdata = check.format("M");
  var day = check.format("D");
  var year = check.format("YY");
  var dateObj = new Date();
  var currentMonth = dateObj.getUTCMonth() + 1;
  var str = dateObj.toString();
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var d = new Date();
  var monthName = month[d.getMonth()];
  const [monthItem, setMonthItem] = useState(month[monthdata - 1].substring(0, 3) + "-" + year);

  useEffect(() => {
    const leadData = localStorage.getItem("leadData");
    const token = JSON.parse(leadData).leadAccessToken;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    fetch(`${API_URL}/months`, requestOptions)
      .then(res => res.json())
      .then(res => {
        setMonths(res);
        console.log(res);
        setDropdowntitle(res[0].months);
        const employeeId = JSON.parse(leadData).leadEmployeeId;

        const params = new URLSearchParams({
          employeeId,
          month: res[0].months,
        });
        let fullmonth = month.filter(item => {
          return item.includes(monthItem.split("-")[0]);
        });
        fetch(`${API_URL}/sales/disburstments/report?employeeId=${employeeId}&month=${fullmonth[0]}`, requestOptions)
          .then(res => res.json())
          .then(res => {
            setDisburstments(res);
          })
          .catch(err => console.log(err));

        fetch(`${API_URL}/collections/report?employeeId=${employeeId}&month=${fullmonth[0]}`, requestOptions)
          .then(res => res.json())
          .then(res => {
            setBMCollection(res);
          })
          .catch(err => console.log(err));
      });
  }, []);

  useEffect(() => {
    const leadData = localStorage.getItem("leadData");
    const token = JSON.parse(leadData).leadAccessToken;
    const employeeId = JSON.parse(leadData).leadEmployeeId;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    let fullmonth = month.filter(item => {
      return item.includes(monthItem.split("-")[0]);
    });
    fetch(`${API_URL}/sales/disburstments/report?employeeId=${employeeId}&month=${fullmonth[0]}`, requestOptions)
      .then(res => res.json())
      .then(res => {
        setDisburstments(res);
      })
      .catch(err => console.log(err));

    fetch(`${API_URL}/collections/report?employeeId=${employeeId}&month=${fullmonth[0]}`, requestOptions)
      .then(res => res.json())
      .then(res => {
        setBMCollection(res);
      })
      .catch(err => console.log(err));
  }, [monthItem]);

  const formatIndianCurrency = val => {
    console.log("val------", val);
    let isNegative = val != "" && val != undefined && val != null ? val.toString().includes("-") : false;
    if (isNegative) {
      val = val.replace(/-/gi, "");
    }
    let temp = val;
    temp = temp.toString();
    let afterPoint = "";
    if (temp.indexOf(".") > 0) afterPoint = temp.substring(temp.indexOf("."), temp.length);
    if (temp !== "-") {
      temp = isNaN(parseInt(temp)) ? "" : parseInt(temp);
    }
    temp = temp.toString();
    let lastThree = temp.substring(temp.length - 3);
    let otherNumbers = temp.substring(0, temp.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
    if (isNegative) {
      res = "-" + res;
    }
    return res;
  };

  return (
    <div className="container dis-container">
      <div className="row mt-5 mb-4">
        <Dropdown className="col-4 mr-4">
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
            {monthItem}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {months.map(item => (
              <Dropdown.Item
                onClick={() => {
                  setMonthItem(item.month);
                }}
                key={item.id}
              >
                {item.month}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <h3 className="ml-5">Disbursement Details</h3>
        {/* <h6 className="ml-auto">BRO Vintage: </h6> */}
      </div>
      <div className="row h5 justify-content-center mt-5">Secured & Unsecured Details</div>

      <Table
        striped
        rounded
        bordered
        hover
        responsive
        size="sm"
        style={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          overflow: "hidden",
          border: "2px solid #dee2e6",
          "margin-bottom": "40px",
        }}
      >
        <thead>
          <tr className="text-center bg_d-primary text-white">
            <th>Loan Type</th>
            <th>Disbursement Amount</th>
            <th>Disbursement Percentage</th>
            <th>No. of Files</th>
          </tr>
        </thead>
        {disbursements &&
          disbursements.disbursementDetails &&
          disbursements.disbursementDetails.map(bmdisbursement => (
            <tbody>
              <tr className="text-center">
                <td className="font-weight-bold">Secured</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.totalSecuredDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.totalSecuredDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>
                  {bmdisbursement.securedDisbPercentage == null
                    ? "0"
                    : parseFloat(bmdisbursement.securedDisbPercentage).toFixed(2)}
                </td>
                <td>{bmdisbursement.securedNoOfFiles}</td>
              </tr>
              <tr className="text-center">
                <td className="font-weight-bold">Unsecured</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.totalUnsecuredDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.totalUnsecuredDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>
                  {bmdisbursement.unsecuredDisbPercentage == null
                    ? "0"
                    : parseFloat(bmdisbursement.unsecuredDisbPercentage).toFixed(2)}
                </td>
                <td>{bmdisbursement.unsecuredNoOfFiles}</td>
              </tr>
              <tr className="text-center">
                <td className="font-weight-bold">Total</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.totalDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.totalDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>
                  {bmdisbursement.securedDisbPercentage + bmdisbursement.unsecuredDisbPercentage == null
                    ? "0"
                    : parseFloat(bmdisbursement.securedDisbPercentage + bmdisbursement.unsecuredDisbPercentage).toFixed(
                        2
                      )}
                </td>
                <td>{bmdisbursement.securedNoOfFiles + bmdisbursement.unsecuredNoOfFiles}</td>
              </tr>
            </tbody>
          ))}
      </Table>
      <div className="row h5 justify-content-center mt-5">Product Wise Disbursement</div>
      <Table
        striped
        rounded
        bordered
        hover
        responsive
        size="sm"
        style={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          overflow: "hidden",
          border: "2px solid #dee2e6",
          "margin-bottom": "40px",
        }}
      >
        <thead>
          <tr className="text-center bg_d-primary text-white">
            <th>Loan Type</th>
            <th>Secured</th>
            <th>Secured No. of Files</th>
            <th>Unsecured</th>
            <th>Unsecured No. of files</th>
            <th>Disbursement Percentage</th>
            <th>Total</th>
          </tr>
        </thead>
        {disbursements &&
          disbursements.disbursementDetails &&
          disbursements.disbursementDetails.map(bmdisbursement => (
            <tbody>
              <tr className="text-center">
                <td className="font-weight-bold">Personal Loan</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.plSecuredDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.plSecuredDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>{bmdisbursement.plSecurednoOfFiles}</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.plUnsecuredDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.plUnsecuredDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>{bmdisbursement.plUnsecurednoOfFiles}</td>
                <td>
                  {bmdisbursement.plDisbPercentage == null
                    ? "0"
                    : parseFloat(bmdisbursement.plDisbPercentage).toFixed(2)}
                </td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.totalPlDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.totalPlDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
              </tr>
              <tr className="text-center">
                <td className="font-weight-bold">Bussiness Loan</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.blSecuredDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.blSecuredDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>{bmdisbursement.blSecurednoOfFiles}</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.blUnsecuredDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.blUnsecuredDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>{bmdisbursement.blUnsecurednoOfFiles}</td>
                <td>
                  {bmdisbursement.blDisbPercentage == null
                    ? "0"
                    : parseFloat(bmdisbursement.blDisbPercentage).toFixed(2)}
                </td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.totalBlDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.totalBlDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
              </tr>
              <tr className="text-center">
                <td className="font-weight-bold">Farm Loan</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.flSecuredDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.flSecuredDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>{bmdisbursement.flSecurednoOfFiles}</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.flUnsecuredDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.flUnsecuredDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>{bmdisbursement.flUnsecurednoOfFiles}</td>
                <td>
                  {bmdisbursement.flDisbPercentage == null
                    ? "0"
                    : parseFloat(bmdisbursement.flDisbPercentage).toFixed(2)}
                </td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.totalFlDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.totalFlDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
              </tr>
              <tr className="text-center">
                <td className="font-weight-bold">Gold Loan</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.glSecuredDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.glSecuredDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>{bmdisbursement.glSecurednoOfFiles}</td>
                <td>NA</td>
                <td>NA</td>
                <td>
                  {bmdisbursement.glDisbPercentage == null
                    ? "0"
                    : parseFloat(bmdisbursement.glDisbPercentage).toFixed(2)}
                </td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.totalGlDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.totalGlDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
              </tr>
              <tr className="text-center">
                <td className="font-weight-bold">Housing Loan</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.hlSecuredDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.hlSecuredDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>{bmdisbursement.hlSecurednoOfFiles}</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.hlUnsecuredDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.hlUnsecuredDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>{bmdisbursement.hlUnsecurednoOfFiles}</td>
                <td>
                  {bmdisbursement.hlDisbPercentage == null
                    ? "0"
                    : parseFloat(bmdisbursement.hlDisbPercentage).toFixed(2)}
                </td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.totalHlDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.totalHlDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
              </tr>
              <tr className="text-center">
                <td className="font-weight-bold">Consumer Durable</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.cdSecuredDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.cdSecuredDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>{bmdisbursement.cdSecurednoOfFiles}</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.cdUnsecuredDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.cdUnsecuredDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>{bmdisbursement.cdUnsecurednoOfFiles}</td>
                <td>
                  {bmdisbursement.cdDisbPercentage == null
                    ? "0"
                    : parseFloat(bmdisbursement.cdDisbPercentage).toFixed(2)}
                </td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.totalCDDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.totalCDDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
              </tr>
              <tr className="text-center">
                <td className="font-weight-bold">Total</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.totalSecuredDisbursementAmount}
                </td>
                <td>{bmdisbursement.securedNoOfFiles}</td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.totalUnsecuredDisbursementAmount}
                </td>
                <td>{bmdisbursement.unsecuredNoOfFiles}</td>
                <td>
                  {bmdisbursement.plDisbPercentage +
                    bmdisbursement.flDisbPercentage +
                    bmdisbursement.glDisbPercentage +
                    bmdisbursement.blDisbPercentage +
                    bmdisbursement.cdDisbPercentage +
                    bmdisbursement.hlDisbPercentage ==
                  null
                    ? "0"
                    : parseFloat(
                        bmdisbursement.plDisbPercentage +
                          bmdisbursement.flDisbPercentage +
                          bmdisbursement.glDisbPercentage +
                          bmdisbursement.blDisbPercentage +
                          bmdisbursement.cdDisbPercentage +
                          bmdisbursement.hlDisbPercentage
                      ).toFixed(2)}
                </td>
                <td>
                  <span>&#8377;</span>
                  {bmdisbursement.totalDisbursementAmount
                    ? formatIndianCurrency(
                        bmdisbursement.totalDisbursementAmount
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
              </tr>
            </tbody>
          ))}
      </Table>
      <div className="row h5 justify-content-center">Disbursed in Different Duration</div>
      <Table
        striped
        rounded
        bordered
        hover
        responsive
        size="sm"
        style={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          overflow: "hidden",
          border: "2px solid #dee2e6",
          "margin-bottom": "40px",
        }}
      >
        <thead>
          <tr className="text-center bg_d-primary text-white">
            <th>Duration</th>
            <th>Disbursement Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td className="font-weight-bold">
              1st {monthItem} to 10th {monthItem}
            </td>
            <td>
              <span>&#8377;</span>
              {disbursements.disbursementDuration1StTo10Th
                ? formatIndianCurrency(
                    disbursements.disbursementDuration1StTo10Th
                      .toString()
                      .split(".")
                      .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                      .join(".")
                  )
                : 0}
            </td>
          </tr>
          <tr className="text-center">
            <td className="font-weight-bold">
              1st {monthItem} to 20th {monthItem}
            </td>
            <td>
              <span>&#8377;</span>
              {disbursements.disbursementDuration1StTo20Th
                ? formatIndianCurrency(
                    disbursements.disbursementDuration1StTo20Th
                      .toString()
                      .split(".")
                      .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                      .join(".")
                  )
                : 0}
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="row h5 justify-content-center">No Of Active Non-Active BRO's</div>
      <Table
        striped
        rounded
        bordered
        hover
        responsive
        size="sm"
        style={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          overflow: "hidden",
          border: "2px solid #dee2e6",
          "margin-bottom": "40px",
        }}
      >
        <thead>
          <tr className="text-center bg_d-primary text-white">
            <th>Status</th>
            <th>Number of BRO's</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td className="font-weight-bold">Active</td>
            <td>{disbursements.activeBROCount}</td>
          </tr>
          <tr className="text-center">
            <td className="font-weight-bold">Non-Active</td>
            <td>{disbursements.inactiveBROCount}</td>
          </tr>
          <tr className="text-center">
            <td className="font-weight-bold">Total</td>
            <td>{disbursements.activeBROCount + disbursements.inactiveBROCount}</td>
          </tr>
        </tbody>
      </Table>
      <div className="row h5 justify-content-center">Quarter Till Date Disbursement</div>
      <Table
        striped
        rounded
        bordered
        hover
        responsive
        size="sm"
        style={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          overflow: "hidden",
          border: "2px solid #dee2e6",
          "margin-bottom": "40px",
        }}
      >
        <thead>
          <tr className="text-center bg_d-primary text-white">
            <th>Status</th>
            <th>Secured</th>
            <th>Unsecured</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td className="font-weight-bold">Disbursement</td>
            <td>
              <span>&#8377;</span>
              {disbursements.qtdBranchSecuredDisbursementAmount
                ? formatIndianCurrency(
                    disbursements.qtdBranchSecuredDisbursementAmount
                      .toString()
                      .split(".")
                      .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                      .join(".")
                  )
                : 0}
            </td>
            <td>
              <span>&#8377;</span>
              {disbursements.qtdBranchUnsecuredDisbursementAmount
                ? formatIndianCurrency(
                    disbursements.qtdBranchUnsecuredDisbursementAmount
                      .toString()
                      .split(".")
                      .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                      .join(".")
                  )
                : 0}
            </td>
            <td>
              <span>&#8377;</span>
              {disbursements.qtdBranchSecuredDisbursementAmount + disbursements.qtdBranchUnsecuredDisbursementAmount
                ? formatIndianCurrency(
                    (
                      disbursements.qtdBranchSecuredDisbursementAmount +
                      disbursements.qtdBranchUnsecuredDisbursementAmount
                    )
                      .toString()
                      .split(".")
                      .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                      .join(".")
                  )
                : 0}
            </td>
          </tr>
        </tbody>
      </Table>
      {/* <h3 className="ml-5 justify-content-center">Disbursement Details</h3> */}
      <div className="row h5 justify-content-center">Collection Details </div>
      <Table
        striped
        rounded
        bordered
        hover
        responsive
        size="sm"
        style={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          overflow: "hidden",
          border: "2px solid #dee2e6",
          "margin-bottom": "40px",
        }}
      >
        <thead>
          <tr className="text-center bg_d-primary text-white">
            <th>Number of loans Due For Collection</th>
            <th>Collection Efficiency</th>
            <th>On Time Collection Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td>{bMCollection.dueLoansCollectionCount}</td>
            <td>
              {bMCollection.collectionEfficiancyPercentage == null
                ? "0"
                : parseFloat(bMCollection.collectionEfficiancyPercentage).toFixed(2)}
            </td>
            <td>
              {bMCollection.onTimeCollectedAmount == null
                ? "0"
                : parseFloat(bMCollection.onTimeCollectedAmount).toFixed(2)}
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="row h5 justify-content-center">DPD Collection Details</div>
      <Table
        striped
        rounded
        bordered
        hover
        responsive
        size="sm"
        style={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          overflow: "hidden",
          border: "2px solid #dee2e6",
          "margin-bottom": "40px",
        }}
      >
        <thead>
          <tr className="text-center bg_d-primary text-white">
            <th>DPD Bucket</th>
            <th>Total No of Cases</th>
            <th>Total Due</th>
            <th>Total Monthly Collection</th>
            <th>Collection Efficiency</th>
          </tr>
        </thead>
        {bMCollection &&
          bMCollection.dpdGroups &&
          bMCollection.dpdGroups.map(dpdCollection => (
            <tbody>
              <tr className="text-center">
                <td className="font-weight-bold">{dpdCollection.dpdBucketGroup}</td>
                <td>{dpdCollection.totalNumberOfCases}</td>
                <td>
                  <span>&#8377;</span>
                  {dpdCollection.totalDue
                    ? formatIndianCurrency(
                        dpdCollection.totalDue
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>
                  <span>&#8377;</span>
                  {dpdCollection.totalMonthlyCollection
                    ? formatIndianCurrency(
                        dpdCollection.totalMonthlyCollection
                          .toString()
                          .split(".")
                          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                          .join(".")
                      )
                    : 0}
                </td>
                <td>
                  {dpdCollection.collectionEfficiency == null
                    ? "0"
                    : parseFloat(dpdCollection.collectionEfficiency.toFixed(2))}
                </td>
              </tr>
            </tbody>
          ))}
      </Table>
    </div>
  );
};

export default DisbursementBM;
