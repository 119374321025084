import React, { Component } from "react";
import { Input, Select } from "../../Component/Input";
import { cloneDeep } from "lodash";
import BreadCrumbs from "../../Component/BreadCrumbs/index";
import {
  getFarmLeasingProductListing,
  findAllProductSelectionDropDown,
  getFarmServicesDropDownByFranchiseCode,
} from "../../Utility/Services/Leadmanage";
import { public_url, formatIndianCurrency } from "../../Utility/Constant";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Loader } from "../../Component/Loader";
import { NoDataFound } from "../../Component/NoDataFound";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export class Farmlisting extends Component {
  state = {
    farmList: [],
    productSelection: "",
    farmService: "",
    productDropdown: [],
    farmDropdown: [],
  };

  componentDidMount() {
    let { match } = this.props;
    console.log("match", match);
    this.getallData();
    this.FarmLeasingProductListing();
    this.AllProductSelectionDropDown();
    this.FarmServicesDropDownByFranchiseCode();
  }

  getallData = () => {
    let { match } = this.props;
    let { productSelection, farmService } = this.state;
    this.setState({
      productSelection: match.params && match.params.productSelection,
      farmService: match.params && match.params.farmService,
    });
  };

  FarmLeasingProductListing = () => {
    let { match } = this.props;
    let obj = {
      productSelection: match.params && match.params.productSelection,
      serviceName: match.params && match.params.farmService,
    };

    getFarmLeasingProductListing(obj).then((res) => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          farmList: res.data && res.data.data,
        });
      }
    });
  };

  AllProductSelectionDropDown = () => {
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let employeeId = leadData && leadData.leadEmployeeId;
    let obj = {
      employeeId: employeeId,
      searchString: "",
    };
    findAllProductSelectionDropDown(obj).then((res) => {
      console.log("res", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          productDropdown: res.data && res.data.data,
        });
      }
    });
  };

  FarmServicesDropDownByFranchiseCode = () => {
    let leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    let obj = {
      franchiseCode: leadData && leadData.leadEmployeeId,
    };

    getFarmServicesDropDownByFranchiseCode(obj).then((res) => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          farmDropdown: res.data && res.data.data,
        });
      }
    });
  };

  onInputChange = (name, value, error) => {
    let { form } = this.state;
    this.state[name] = value;
    // form[name] = value;
    console.log("frs", this.state[name]);
    // this.setState({
    //   form,
    // });
    this.setState({
      state: this.state,
    });

    // if farmService gets changed
    if (name === "farmService") {
      getFarmLeasingProductListing({
        productSelection: this.state.productSelection,
        serviceName: this.state.farmService,
      }).then((res) => {
        if (res.error) {
          return;
        }
        if (res.data.error) {
          toast.error(res.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 4000,
          });
        } else {
          this.setState({
            farmList: res.data && res.data.data,
          });
        }
      });
    }
  };
  // onFormChange = (name, value, error = undefined) => {
  //   this.state[name] = value;
  //   // if (error !== undefined) {
  //   //   let { errors } = form;
  //   //   errors[name] = error;
  //   // }
  //   this.setState({
  //     state: this.state,
  //   });

  //   // if farmService gets changed
  //   if (name === 'farmService') {
  //     getFarmLeasingProductListing({
  //       productSelection: this.state.productSelection,
  //       serviceName: this.state.farmService,
  //     }).then((res) => {
  //       if (res.error) {
  //         return;
  //       }
  //       if (res.data.error) {
  //         toast.error(res.data.message, {
  //           type: toast.TYPE.ERROR,
  //           autoClose: 4000,
  //         });
  //       } else {
  //         this.setState({
  //           farmList: res.data && res.data.data,
  //         });
  //       }
  //     });
  //   }
  // };

  render() {
    let {
      farmList,
      productSelection,
      farmService,
      productDropdown,
      farmDropdown,
    } = this.state;
    let { match } = this.props;
    console.log("farmList", farmList);
    return (
      <React.Fragment>
        <BreadCrumbs
          name1={"Lead List"}
          name2={"Farm List"}
          url1={`${public_url.Leadmodule}`}
          url2={`${match.url}`}
        />
        <section class="p-3 bg_l-secondary">
          <div className="pl-5 pr-5">
            <div className="row p-4">
              <div className="col-md-6 col-sm-6 col-lg-2 mt-3 d-flex align-items-center">
                <label class="fs-14 mb-0 gTextPrimary fw-500">
                  Product Selection {<i className="text-danger">*</i>}
                </label>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-4 mt-3 mr-auto">
                <div class="select">
                  <Select
                    className="w-100 fs-12 create-lead-form-select"
                    options={productDropdown}
                    value={productSelection}
                    title="productSelection"
                    name="productSelection"
                    onChangeFunc={(name, value, error) => {
                      this.onInputChange(name, value, error);
                    }}
                    //  error={selectedSpokeError}
                    isClearable={false}
                    valueKey="productdescription"
                    labelKey="productdescription"
                    placeholder="Select Product"
                  />
                  {/*<Select
                    className="w-100 fs-12 create-lead-form-select"
                    options={productDropdown}
                    value={productSelection}
                    title="productSelection"
                    name="productSelection"
                    onChangeFunc={(name, value, error) => {
                      this.onFormChange(name, value, error);
                    }}
                    //   onBlur={() => { }}
                    isReq={true}
                    valueKey="productSelection"
                    labelKey="productSelection"
                    placeholder="Select Product"
                  /> */}
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-2 mt-3 d-flex align-items-center">
                <label class="fs-14 mb-0 gTextPrimary fw-500">
                  Farm Service {<i className="text-danger">*</i>}
                </label>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-4 mt-3 mr-auto">
                <div class="select">
                  <Select
                    className="w-100 fs-12 create-lead-form-select"
                    options={farmDropdown}
                    value={farmService}
                    title="farmService"
                    name="farmService"
                    onChangeFunc={(name, value, error) => {
                      this.onInputChange(name, value, error);
                    }}
                    //  error={selectedSpokeError}
                    isClearable={false}
                    valueKey="serviceName"
                    labelKey="serviceName"
                    placeholder="Select farmService"
                  />
                  {/* <Select
                    className="w-100 fs-12 create-lead-form-select"
                    options={farmDropdown}
                    value={farmService}
                    title="farmService"
                    name="farmService"
                    onChangeFunc={(name, value, error) => {
                      this.onFormChange(name, value, error);
                    }}
                    //   onBlur={() => { }}
                    isReq={true}
                    valueKey="serviceName"
                    labelKey="serviceName"
                    placeholder="Select farmService"
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="p-3">
            <div
              class="table-responsive disbursment"
              style={{ backgroundColor: "#fff" }}
            >
              <table class="table table-bordered table-striped table-sm">
                <thead className="text-primary">
                  <tr
                    className="text-center bg_d-primary text-white"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <th scope="col">Serial No</th>
                    <th scope="col">Service</th>
                    <th scope="col">Implement Name</th>
                    <th scope="col">Brand</th>
                    <th scope="col">Rate</th>
                    <th scope="col">Unit</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {farmList &&
                    farmList.map((res) => (
                      <tr>
                        <td>{res.serialNumber ? res.serialNumber : null}</td>
                        <td>{res.service ? res.service : null}</td>
                        <td>{res.implement ? res.implement : null}</td>
                        <td>{res.brand ? res.brand : null}</td>
                        <td>{res.rate ? res.rate : 0}</td>

                        <td>{res.unit ? res.unit : null}</td>

                        <td className="cursor-pointer">
                          <Link
                            to={`${public_url.catalogue_card}/${
                              res && res.serialNumber
                            }/${match.params.leadId}/${
                              match.params.productSelection
                            }/${farmService}/${res && res.franchisee}`}
                          >
                            <i
                              class="fas fa-eye"
                              style={{ color: "#1e1f63" }}
                            ></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {/* <div>
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.perPage}
                        totalItemsCount={count}
                        pageRangeDisplayed={10}
                        onChange={this.Pagination}
                        innerClass="pagination d-flex justify-content-center align-items-center mt-4"
                        itemClass="mx-2 item"
                        itemClassFirst="itemClassFirst"
                        itemClassPrev="itemClassPrev"
                        itemClassNext="itemClassNext"
                        itemClassLast="itemClassLast"
                      />
                    </div> */}
          </div>
          {/* save */}
          <div className="row justify-content-end col-md-12 col-sm-12 mt-2 col-lg-12">
            <div className="row">
              <button
                //   disabled={}
                className={`btn btn-secondary btn-rounded ls-1 cursor-pointer mr-4 fs-16 btn-green`}
                onClick={() =>
                  this.props.history.push(
                    /*  `${public_url.update_lead}/${match.params.leadId}` */
                    `${public_url.Leadmodule}`
                  )
                }
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Farmlisting;
