import React, { useState, useEffect } from "react";
import DisbursementBRO from "./Components/Disbursement/DisbursementBRO";
import DisbursementBM from "./Components/Disbursement/DisbursementBM";
import DisbursementBCO from "./Components/Disbursement/DisbursementBCO";
import DisbursementRBH from "./Components/Disbursement/DisbursementRBH";

import IncentiveBRO from "./Components/IncentiveBRO";
import IncentiveBCO from "./Components/IncentiveBCO";
import IncentiveBM from "./Components/IncentiveBM";
import IncentiveRBH from "./Components/IncentiveRBH";

// import Incentive from "./Components/Incentive";
import NavigationBar from "./Components/NavigationBar";
import "./dashboard.css";

const Dashboard = () => {
  const [isdisbursement, setIsdisbursement] = useState(true);
  const [isincentive, setIsincentive] = useState(false);
  const [role, setRole] = useState("");

  return (
    <section class="p-3 bg_l-secondary">
      <NavigationBar
        isdisbursement={isdisbursement}
        setIsdisbursement={setIsdisbursement}
        setIsincentive={setIsincentive}
      />

      {role === "BRO" ? isdisbursement ? <DisbursementBRO /> : <IncentiveBRO /> : null}
      {role === "BCO" ? isdisbursement ? <DisbursementBCO /> : <IncentiveBCO /> : null}
      {role === "RBH" ? isdisbursement ? <DisbursementRBH /> : <IncentiveRBH /> : null}
      {role === "BM" ? isdisbursement ? <DisbursementBM /> : <IncentiveBM /> : null}

      {/* {role === "BRO" ? isdisbursement ? <DisbursementBRO /> : <Incentive /> : null}
      {role === "RM" ? isdisbursement ? <DisbursementRM /> : <Incentive /> : null} */}
    </section>
  );
};

export default Dashboard;
