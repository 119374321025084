let backendUrl;
let tempCosApi;

let hostname = window.location.hostname;

if (hostname === "lead.sarvagram.com") {
  backendUrl = "https://gateway.sarvagram.com/lead/v1.0";
  tempCosApi = "https://gateway.sarvagram.com/";
} else if (hostname === "uat-lead.sarvagram.net") {
  backendUrl = "https://uat-api.sarvagram.net/lead/v1.0";
  tempCosApi = "https://uat-api.sarvagram.net/";
} else {
  backendUrl = "https://dev-api.sarvagram.net/lead/v1.0";
  tempCosApi = "https://dev-api.sarvagram.net/";
}

export const API_URL = backendUrl;
export const COS_API = tempCosApi;
