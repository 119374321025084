import React, { Component } from 'react';
import BreadCrumbs from '../../Component/BreadCrumbs/index';
import { Select, Input, TextArea, File } from '../../Component/Input';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { public_url, formatIndianCurrency } from '../../Utility/Constant';
import {
  getFarmImplementCatalogue,
  rejectLeadByLeadId,
  findAllRejectionRemarksDropDown,
} from '../../Utility/Services/Leadmanage';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../LMScaselist/style.css';
toast.configure();

const initForm = {
  longDescription: '',
  serialNumber: '',
  shortDescription: '',
  branch: '',
  unit: '',
  frenchiseeShearPersentage: null,
  rate: null,
  service: '',
  variableCost: null,
  franchiseCode: '',
  inUse: '',
  implement: '',
  brand: '',
  franchisee: '',
};

export class Catalogue extends Component {
  state = {
    form: cloneDeep(initForm),
    orderImagesList: [],
    orderVideosList: [],
    show: false,
    rejectionRemark: '',
    otherRemark: '',
    rejectDropdown: [],
  };

  componentDidMount() {
    this.FarmImplementCatalogue();
    this.AllRejectionRemarksDropDown();
  }

  FarmImplementCatalogue = () => {
    let { match } = this.props;
    let obj = {
      serialNumber: match.params.serialNumber,
      franchiseeName: match.params.franchisee,
      serviceName: match.params.farmService,
    };

    getFarmImplementCatalogue(obj).then(res => {
      console.log('FarmImplementCatalogue', res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          form: res.data && res.data.data && res.data.data.orderDetails,
          orderImagesList: res.data && res.data.data && res.data.data.orderImagesList,
          orderVideosList: res.data && res.data.data && res.data.data.orderVideosList,
        });
      }
    });
  };

  AllRejectionRemarksDropDown = () => {
    findAllRejectionRemarksDropDown().then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        this.setState({
          rejectDropdown: res.data.data,
        });
      }
    });
  };

  onInputChange = (name, value, error = undefined) => {
    this.state[name] = value;
    this.setState({
      state: this.state,
    });
  };

  handleReject = () => {
    let { rejectionRemark, otherRemark } = this.state;
    let { match } = this.props;
    let obj = {
      leadId: match.params.leadId,
      rejectionRemark: rejectionRemark,
      otherRemark: otherRemark,
    };
    rejectLeadByLeadId(obj).then(res => {
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        toast.success(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 4000,
        });
        this.props.history.push(`${public_url.Leadmodule}`);
      }
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  // redirectToPlaceOrder function is used to redirect to place order page

  redirectToPlaceOrder = () => {
    this.props.history.push(`${this.props.location.pathname}/place-order`);
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  onCancel = () => {
    let { match } = this.props;
    this.props.history.push(
      `${public_url.farm_list}/${match.params.leadId}/${match.params.productSelection}/${match.params.farmService}`
    );
  };

  render() {
    let { form, orderImagesList, orderVideosList, show, rejectDropdown, rejectionRemark, otherRemark } = this.state;
    let { service, implement, brand, unit, rate, shortDescription, longDescription } = form;
    let { match } = this.props;
    console.log('match1', match);
    let { productSelection } = match.params;
    let leadData = JSON.parse(localStorage.getItem('leadData')); // Get the lead details from local storage
    let RoleData = JSON.parse(localStorage.getItem('Role')); // Get the role of the logged in user from local storage
    return (
      <React.Fragment>
        <BreadCrumbs
          name1={'Lead List'}
          name2={'Catalogue'}
          url1={`${public_url.Leadmodule}`}
          url2={`${this.props.match.url}`}
        />
        <section className="px-2 px-md-5 pt-4 pb-5 dashboard_div bg_l-secondary">
          <div className="d-flex justify-content-start align-items-center">
            <section className="py-4 position-relative bg_l-secondary w-100">
              <div className=" bg-white disbursment">
                <div className="row">
                  {/* 1st */}
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="row p-5">
                      <div className="col-12 pl-4 pr-4 text-center">
                        <h5
                          className="divider gradient pt-1"
                          style={{
                            backgroundColor: '#f89839',
                            color: 'black',
                            height: 54,
                          }}
                        >
                          Farm Implement Catalogue
                        </h5>
                      </div>
                      {/* image */}
                      {orderImagesList &&
                        orderImagesList.map(res => (
                          <div className="col-lg-6 col-sm-12 col-md-12 mt-4" key={res.mediaId}>
                            <div class="card bg-white">
                              <a
                                href={`${window.location.protocol}//${window.location.hostname}/${res.mediaPath}`}
                                target="_blank"
                              >
                                <img
                                  src={`${window.location.protocol}//${window.location.hostname}/${res.mediaPath}`}
                                  class="card-img-top"
                                  alt="new"
                                />
                              </a>
                              {/* <div class="card-body">
                                <p class="card-text">{res.name}</p>
                              </div> */}
                            </div>
                          </div>
                        ))}

                      {/* video */}
                      {orderVideosList &&
                        orderVideosList.map(res => (
                          <div className="col-12 mt-4">
                            <div class="card bg-white">
                              <h5 class="card-header text-center">Product Video</h5>
                              <div class="card-body">
                                <video width="520" height="200" controls class="videoCard">
                                  <source
                                    src={`${window.location.protocol}//${window.location.hostname}/${res.mediaPath}`}
                                    type="video/mp4"
                                  />
                                  <source
                                    src={`${window.location.protocol}//${window.location.hostname}/${res.mediaPath}`}
                                    type="video/ogg"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  {/* 2nd */}
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="container disbursment">
                      <div className="row p-5 ml-5 mr-3">
                        <div className="col-md-6 col-sm-6 col-lg-6 d-flex align-items-center">
                          <label class="fs-16 mb-0 gTextPrimary fw-500">Service</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                          <Input
                            className="form-control text-center fs-14"
                            placeholder="service"
                            name="service"
                            value={service}
                            onChangeFunc={(name, value, error) => {
                              //   value = replaceComma(value);
                              this.onFormChange(name, value, error);
                            }}
                            // maxLength={16}
                            // disabled={}
                            isReq={true}
                            disabled={true}
                            // reqType="alphaNumeric"
                            title="service"
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 d-flex align-items-center">
                          <label class="fs-16 mb-0 gTextPrimary fw-500">Implement</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                          <Input
                            className="form-control text-center fs-14"
                            placeholder="implement"
                            name="implement"
                            value={implement}
                            onChangeFunc={(name, value, error) => {
                              //   value = replaceComma(value);
                              this.onFormChange(name, value, error);
                            }}
                            // maxLength={16}
                            // disabled={}
                            isReq={true}
                            disabled={true}
                            // reqType="alphaNumeric"
                            title="implement"
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 d-flex align-items-center">
                          <label class="fs-16 mb-0 gTextPrimary fw-500">Brand</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                          <Input
                            className="form-control text-center fs-14"
                            placeholder="Brand"
                            name="brand"
                            value={brand}
                            onChangeFunc={(name, value, error) => {
                              //   value = replaceComma(value);
                              this.onFormChange(name, value, error);
                            }}
                            // maxLength={16}
                            // disabled={}
                            isReq={true}
                            disabled={true}
                            // reqType="alphaNumeric"
                            title="brand"
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 d-flex align-items-center">
                          <label class="fs-16 mb-0 gTextPrimary fw-500">Unit</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                          <Input
                            className="form-control text-center fs-14"
                            placeholder="Unit"
                            name="unit"
                            value={unit}
                            onChangeFunc={(name, value, error) => {
                              //   value = replaceComma(value);
                              this.onFormChange(name, value, error);
                            }}
                            // maxLength={16}
                            // disabled={}
                            isReq={true}
                            disabled={true}
                            // reqType="alphaNumeric"
                            title="unit"
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 d-flex align-items-center">
                          <label class="fs-16 mb-0 gTextPrimary fw-500">Price</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                          <Input
                            className="form-control text-center fs-14"
                            placeholder="Price"
                            name="rate"
                            value={rate}
                            onChangeFunc={(name, value, error) => {
                              //   value = replaceComma(value);
                              this.onFormChange(name, value, error);
                            }}
                            // maxLength={16}
                            // disabled={}
                            isReq={true}
                            disabled={true}
                            // reqType="alphaNumeric"
                            title="rate"
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 d-flex align-items-center">
                          <label class="fs-16 mb-0 gTextPrimary fw-500">Short Description</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                          <Input
                            className="form-control text-left fs-14"
                            placeholder="Short Description"
                            name="shortDescription"
                            value={shortDescription}
                            onChangeFunc={(name, value, error) => {
                              //   value = replaceComma(value);
                              this.onFormChange(name, value, error);
                            }}
                            // maxLength={16}
                            // disabled={}
                            isReq={true}
                            disabled={true}
                            // reqType="alphaNumeric"
                            title="shortDescription"
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 d-flex align-items-center">
                          <label class="fs-16 mb-0 gTextPrimary fw-500">Detail Description</label>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                          <TextArea
                            className="form-control text-left fs-14"
                            placeholder="Detail Description"
                            name="longDescription"
                            value={longDescription}
                            onChangeFunc={(name, value, error) => {
                              //   value = replaceComma(value);
                              this.onFormChange(name, value, error);
                            }}
                            // maxLength={16}
                            // disabled={}
                            isReq={true}
                            disabled={true}
                            // reqType="alphaNumeric"
                            title="longDescription"
                          />
                        </div>
                        {/* save */}
                        <div className="row justify-content-end col-md-12 col-sm-12 mt-4 col-lg-12">
                          <div className="row">
                            <button
                              //   disabled={}
                              className={`btn btn-secondary btn-rounded ls-1 cursor-pointer mr-3  fs-16 btn-green`}
                              onClick={this.onCancel}
                            >
                              Back
                            </button>
                            <button
                              disabled={
                                RoleData == 'FRO' ||
                                RoleData == 'CFM' ||
                                (RoleData == 'Call Centre' && productSelection == 'Farm Implement Leasing')
                              }
                              className={`btn btn-secondary btn-rounded ls-1 cursor-pointer mr-3 fs-16 btn-green`}
                              onClick={this.redirectToPlaceOrder}
                            >
                              Place Order
                            </button>
                            <button
                              disabled={
                                RoleData == 'FRO' ||
                                RoleData == 'CFM' ||
                                (RoleData == 'Call Centre' && productSelection == 'Farm Implement Leasing')
                              }
                              className={`btn btn-secondary btn-rounded ls-1 cursor-pointer  fs-16 btn-green`}
                              onClick={this.handleShow}
                            >
                              Reject Lead
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* reject */}
                <Modal show={show} onHide={this.handleClose} animation={false}>
                  <Modal.Header closeButton>
                    <Modal.Title>Reject Lead</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-lg-4 mt-3 d-flex align-items-center">
                        <label class="fs-14 mb-0 gTextPrimary fw-500">Rejection Reason</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 mt-3 mr-auto">
                        <div class="select">
                          <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={rejectDropdown}
                            value={rejectionRemark}
                            title="rejectionRemark"
                            name="rejectionRemark"
                            onChangeFunc={(name, value, error) => {
                              this.onInputChange(name, value, error);
                            }}
                            isClearable={true}
                            //  error={selectedSpokeError}
                            isReq={true}
                            valueKey="rejectionRemark"
                            labelKey="rejectionRemark"
                            placeholder="Select Rejection Reason"
                          />
                          {/* <Select
                            className="w-100 fs-12 create-lead-form-select"
                            options={rejectDropdown}
                            value={rejectionRemark}
                            title="rejectionRemark"
                            name="rejectionRemark"
                            onChangeFunc={(name, value, error) => {
                              this.onInputChange(name, value, error);
                            }}
                            //   onBlur={() => { }}
                            isReq={true}
                            valueKey="rejectionRemark"
                            labelKey="rejectionRemark"
                            placeholder="Select Rejection Remark"
                          /> */}
                        </div>
                      </div>
                      <div className="col-lg-2"></div>
                      <div className="col-md-6 col-sm-6 col-lg-4 d-flex align-items-center">
                        <label class="fs-14 mb-0  gTextPrimary fw-500">Rejection Remarks</label>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 mt-4 mr-auto">
                        <Input
                          className="form-control text-center fs-16"
                          placeholder="Rejection Remarks"
                          name="otherRemark"
                          maxLength={20}
                          value={otherRemark}
                          onChangeFunc={(name, value, error) => {
                            //   value = replaceComma(value);
                            this.onInputChange(name, value, error);
                          }}
                          maxLength={255}
                          // disabled={}
                          isReq={true}
                          // reqType="alphaNumeric"
                          title="otherRemark"
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={this.handleReject}
                      disabled={!this.state.otherRemark || !this.state.rejectionRemark}
                    >
                      Reject Lead
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </section>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Catalogue;
