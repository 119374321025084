import React, { useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import { API_URL } from "../../../Utility/Config";
import moment from "moment";

const DisbursementBRO = props => {
  const [months, setMonths] = useState([]);
  const [dropdowntitle, setDropdowntitle] = useState("");
  const [disbursements, setDisburstments] = useState({});
  const [disbursementLoanDetails, setDisbursementLoanDetails] = useState([]);
  const [goldLoan, setGoldLoan] = useState({});
  const [dpdData, setDpdData] = useState([]);

  var check = moment(new Date(), "YYYY/MM/DD");

  var monthdata = check.format("M");
  var day = check.format("D");
  var year = check.format("YY");
  var dateObj = new Date();
  var currentMonth = dateObj.getUTCMonth() + 1;
  var str = dateObj.toString();
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var d = new Date();
  var monthName = month[d.getMonth()];
  const [monthItem, setMonthItem] = useState(month[monthdata - 1].substring(0, 3) + "-" + year);

  useEffect(() => {
    const leadData = localStorage.getItem("leadData");
    const token = JSON.parse(leadData).leadAccessToken;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    fetch(`${API_URL}/months`, requestOptions)
      .then(res => res.json())
      .then(res => {
        setMonths(res);
        console.log(res);
        setDropdowntitle(res[0].months);
        const employeeId = JSON.parse(leadData).leadEmployeeId;

        const params = new URLSearchParams({
          employeeId,
          month: res[0].months,
        });
        let fullmonth = month.filter(item => {
          return item.includes(monthItem.split("-")[0]);
        });
        fetch(`${API_URL}/sales/disburstments/report?employeeId=${employeeId}&month=${fullmonth[0]}`, requestOptions)
          .then(res => res.json())
          .then(res => {
            setDisburstments(res);
          })
          .catch(err => console.log(err));

        fetch(`${API_URL}/sales/disburstments/report?employeeId=${employeeId}&product=gold loan`, requestOptions)
          .then(res => res.json())
          .then(res => {
            setGoldLoan(res);
          })
          .catch(err => console.log(err));
      });
  }, []);

  useEffect(() => {
    const leadData = localStorage.getItem("leadData");
    const token = JSON.parse(leadData).leadAccessToken;
    const employeeId = JSON.parse(leadData).leadEmployeeId;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    let fullmonth = month.filter(item => {
      return item.includes(monthItem.split("-")[0]);
    });
    fetch(`${API_URL}/sales/disburstments/report?employeeId=${employeeId}&month=${fullmonth[0]}`, requestOptions)
      .then(res => res.json())
      .then(res => {
        setDisburstments(res);
      })
      .catch(err => console.log(err));

    fetch(`${API_URL}/sales/disbursements/loans?employeeId=${employeeId}&month=${fullmonth[0]}`, requestOptions)
      .then(res => res.json())
      .then(res => {
        setDisbursementLoanDetails(res);
      })
      .catch(err => console.log(err));

    fetch(`${API_URL}/collections/report?employeeId=${employeeId}&month=${fullmonth[0]}`, requestOptions)
      .then(res => res.json())
      .then(res => {
        setDpdData(res);
      })
      .catch(err => console.log(err));
  }, [monthItem]);

  const formatIndianCurrency = val => {
    console.log("val------", val);
    let isNegative = val != "" && val != undefined && val != null ? val.toString().includes("-") : false;
    if (isNegative) {
      val = val.replace(/-/gi, "");
    }
    let temp = val;
    temp = temp.toString();
    let afterPoint = "";
    if (temp.indexOf(".") > 0) afterPoint = temp.substring(temp.indexOf("."), temp.length);
    if (temp !== "-") {
      temp = isNaN(parseInt(temp)) ? "" : parseInt(temp);
    }
    temp = temp.toString();
    let lastThree = temp.substring(temp.length - 3);
    let otherNumbers = temp.substring(0, temp.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
    if (isNegative) {
      res = "-" + res;
    }
    return res;
  };

  //   // const monthData = async (month) => {
  //   //   const empId = localStorage.getItem(leadData).leadEmployeeId;
  //   //   const response = await fetch(`${dashboard_url}?employeeId=${empId}?month=${month}`);
  //   // }
  return (
    <div className="container dis-container">
      <div className="row mt-5 mb-4">
        <Dropdown className="col-4 mr-4">
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
            {monthItem}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {months.map(item => (
              <Dropdown.Item
                onClick={() => {
                  setMonthItem(item.month);
                }}
                key={item.id}
              >
                {item.month}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <>
          <h3 className="ml-5">Disbursement Details</h3>

          {/* <h6 className="ml-auto">BRO Vintage: </h6> */}
        </>
      </div>
      {/* {disbursements.map(loans => ( */}
      <div>
        <div className="row h5 justify-content-center mt-5">Secured & Unsecured Details</div>
        <Table
          striped
          rounded
          bordered
          hover
          responsive
          size="sm"
          style={{
            backgroundColor: "#fff",
            borderRadius: "12px",
            overflow: "hidden",
            border: "2px solid #dee2e6",
            "margin-bottom": "40px",
          }}
        >
          <thead>
            <tr className="text-center bg_d-primary text-white">
              <th>Loan Type</th>
              <th>Disbursement Amount</th>
              <th>Disbursement Percentage</th>
              <th>No. of Files</th>
            </tr>
          </thead>
          {disbursements &&
            disbursements.disbursementDetails &&
            disbursements.disbursementDetails.map(loan => (
              <tbody>
                <tr className="text-center">
                  <td className="font-weight-bold">Secured</td>
                  <td>
                    <span> &#8377;</span>
                    {loan.totalSecuredDisbursementAmount
                      ? formatIndianCurrency(
                          loan.totalSecuredDisbursementAmount
                            .toString()
                            .split(".")
                            .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                            .join(".")
                        )
                      : 0}
                  </td>
                  <td>
                    {loan.securedDisbPercentage == null ? "0" : parseFloat(loan.securedDisbPercentage).toFixed(2)}
                  </td>
                  <td>{loan.securedNoOfFiles}</td>
                </tr>
                <tr className="text-center">
                  <td className="font-weight-bold">Un-secured</td>
                  <td>
                    <span> &#8377;</span>
                    {loan.totalUnsecuredDisbursementAmount
                      ? formatIndianCurrency(
                          loan.totalUnsecuredDisbursementAmount
                            .toString()
                            .split(".")
                            .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                            .join(".")
                        )
                      : 0}
                  </td>
                  <td>
                    {loan.unsecuredDisbPercentage == null ? "0" : parseFloat(loan.unsecuredDisbPercentage).toFixed(2)}
                  </td>
                  <td>{loan.unsecuredNoOfFiles}</td>
                </tr>
                <tr className="text-center font-weight-bold">
                  <td>Total</td>
                  <td>
                    <span> &#8377;</span>
                    {loan.totalDisbursementAmount
                      ? formatIndianCurrency(
                          loan.totalDisbursementAmount
                            .toString()
                            .split(".")
                            .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                            .join(".")
                        )
                      : 0}
                  </td>
                  <td>
                    {loan.securedDisbPercentage + loan.unsecuredDisbPercentage == null
                      ? "0"
                      : parseFloat(loan.securedDisbPercentage + loan.unsecuredDisbPercentage).toFixed(2)}
                  </td>
                  <td>{loan.securedNoOfFiles + loan.unsecuredNoOfFiles}</td>
                </tr>
              </tbody>
            ))}
        </Table>

        <div>
          <div className="row h5 justify-content-center mt-5">Product Wise Disbursement</div>
          <Table
            striped
            rounded
            bordered
            hover
            responsive
            size="sm"
            style={{
              backgroundColor: "#fff",
              borderRadius: "12px",
              overflow: "hidden",
              border: "2px solid #dee2e6",
              "margin-bottom": "40px",
            }}
          >
            <thead>
              <tr className="text-center bg_d-primary text-white">
                <th>Loan Type</th>
                <th>Secured</th>
                <th>Secured No. of Files</th>
                <th>Unsecured</th>
                <th>Unsecured No. of Files</th>
                <th>Disbursement %</th>
                <th>Total</th>
              </tr>
            </thead>
            {disbursements &&
              disbursements.disbursementDetails &&
              disbursements.disbursementDetails.map(loan => (
                <tbody>
                  <tr className="text-center">
                    <td className="font-weight-bold">Personal Loan</td>
                    <td>
                      <span> &#8377;</span>
                      {loan.plSecuredDisbursementAmount
                        ? formatIndianCurrency(
                            loan.plSecuredDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                    <td>{loan.plSecurednoOfFiles}</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.plUnsecuredDisbursementAmount
                        ? formatIndianCurrency(
                            loan.plUnsecuredDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                    <td>{loan.plUnsecurednoOfFiles}</td>
                    <td>{loan.plDisbPercentage == null ? "0" : parseFloat(loan.plDisbPercentage).toFixed(2)}</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.totalPlDisbursementAmount
                        ? formatIndianCurrency(
                            loan.totalPlDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}{" "}
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td className="font-weight-bold">Bussiness Loan</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.blSecuredDisbursementAmount
                        ? formatIndianCurrency(
                            loan.blSecuredDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                    <td>{loan.blSecurednoOfFiles}</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.blUnsecuredDisbursementAmount
                        ? formatIndianCurrency(
                            loan.blUnsecuredDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                    <td>{loan.blUnsecurednoOfFiles}</td>
                    <td>{loan.blDisbPercentage == null ? "0" : parseFloat(loan.blDisbPercentage).toFixed(2)}</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.totalBlDisbursementAmount
                        ? formatIndianCurrency(
                            loan.totalBlDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td className="font-weight-bold">Farm Loan</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.flSecuredDisbursementAmount
                        ? formatIndianCurrency(
                            loan.flSecuredDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                    <td>{loan.flSecurednoOfFiles}</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.flUnsecuredDisbursementAmount
                        ? formatIndianCurrency(
                            loan.flUnsecuredDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                    <td>{loan.flUnsecurednoOfFiles}</td>
                    <td>{loan.flDisbPercentage == null ? "0" : parseFloat(loan.flDisbPercentage).toFixed(2)}</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.totalFlDisbursementAmount
                        ? formatIndianCurrency(
                            loan.totalFlDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td className="font-weight-bold">Gold Loan</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.glSecuredDisbursementAmount
                        ? formatIndianCurrency(
                            loan.glSecuredDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                    <td>{loan.glSecurednoOfFiles}</td>
                    <td>NA</td>
                    <td>NA</td>
                    <td>{loan.glDisbPercentage == null ? "0" : parseFloat(loan.glDisbPercentage).toFixed(2)}</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.totalGlDisbursementAmount
                        ? formatIndianCurrency(
                            loan.totalGlDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td className="font-weight-bold">Housing Loan</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.hlSecuredDisbursementAmount
                        ? formatIndianCurrency(
                            loan.hlSecuredDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                    <td>{loan.hlSecurednoOfFiles}</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.hlUnsecuredDisbursementAmount
                        ? formatIndianCurrency(
                            loan.hlUnsecuredDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                    <td>{loan.hlUnsecurednoOfFiles}</td>
                    <td>{loan.hlDisbPercentage == null ? "0" : parseFloat(loan.hlDisbPercentage).toFixed(2)}</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.totalHlDisbursementAmount
                        ? formatIndianCurrency(
                            loan.totalHlDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td className="font-weight-bold">Consumer Durable</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.cdSecuredDisbursementAmount
                        ? formatIndianCurrency(
                            loan.cdSecuredDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                    <td>{loan.cdSecurednoOfFiles}</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.cdUnsecuredDisbursementAmount
                        ? formatIndianCurrency(
                            loan.cdUnsecuredDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                    <td>{loan.cdUnsecurednoOfFiles}</td>
                    <td>{loan.cdDisbPercentage == null ? "0" : parseFloat(loan.cdDisbPercentage).toFixed(2)}</td>
                    <td>
                      <span>&#8377;</span>
                      {loan.totalCDDisbursementAmount
                        ? formatIndianCurrency(
                            loan.totalCDDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td className="font-weight-bold">Total</td>
                    <td className="font-weight-bold">
                      <span>&#8377;</span>
                      {loan.totalSecuredDisbursementAmount
                        ? formatIndianCurrency(
                            loan.totalSecuredDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                    <td className="font-weight-bold">{loan.securedNoOfFiles}</td>
                    <td className="font-weight-bold">
                      <span>&#8377;</span>
                      {loan.totalUnsecuredDisbursementAmount
                        ? formatIndianCurrency(
                            loan.totalUnsecuredDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                    <td className="font-weight-bold">{loan.unsecuredNoOfFiles}</td>
                    <td className="font-weight-bold">
                      {loan.plDisbPercentage +
                        loan.flDisbPercentage +
                        loan.blDisbPercentage +
                        loan.glDisbPercentage +
                        loan.hlDisbPercentage +
                        loan.cdDisbPercentage ==
                      null
                        ? "0"
                        : parseFloat(
                            loan.plDisbPercentage +
                              loan.flDisbPercentage +
                              loan.blDisbPercentage +
                              loan.glDisbPercentage +
                              loan.hlDisbPercentage +
                              loan.cdDisbPercentage
                          ).toFixed(2)}
                    </td>
                    <td className="font-weight-bold">
                      <span>&#8377;</span>
                      {loan.totalDisbursementAmount
                        ? formatIndianCurrency(
                            loan.totalDisbursementAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                  </tr>
                </tbody>
              ))}
          </Table>
        </div>

        <div>
          <div className="row h5 justify-content-center">Disbursed Loan Details</div>
          <Table
            striped
            rounded
            bordered
            hover
            responsive
            size="sm"
            style={{
              backgroundColor: "#fff",
              borderRadius: "12px",
              overflow: "hidden",
              border: "2px solid #dee2e6",
              "margin-bottom": "40px",
            }}
          >
            <thead>
              <tr className="text-center bg_d-primary text-white">
                <th>LAN Number</th>
                <th>Loan Type</th>
                <th>Loan Amount</th>
                <th>Disbursement Date</th>
              </tr>
            </thead>
            {disbursementLoanDetails &&
              disbursementLoanDetails.disbursementLoanDetailsList &&
              disbursementLoanDetails.disbursementLoanDetailsList.map(loanData => (
                <tbody>
                  <tr className="text-center">
                    <td className="font-weight-bold">{loanData.lan}</td>
                    <td>{loanData.productType}</td>
                    <td>
                      <span>&#8377;</span>
                      {loanData.loanAmount
                        ? formatIndianCurrency(
                            loanData.loanAmount
                              .toString()
                              .split(".")
                              .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                              .join(".")
                          )
                        : 0}
                    </td>
                    <td>{moment(loanData.disbursedOn).format("DD-MM-YYYY")}</td>
                  </tr>
                </tbody>
              ))}
          </Table>
        </div>

        <div className="row h5 justify-content-center">Gold Loan Disbursement</div>
        <Table
          striped
          rounded
          bordered
          hover
          responsive
          size="sm"
          style={{
            backgroundColor: "#fff",
            borderRadius: "12px",
            overflow: "hidden",
            border: "2px solid #dee2e6",
            "margin-bottom": "40px",
          }}
        >
          <thead>
            <tr className="text-center bg_d-primary text-white">
              <th>Month</th>
              <th>Disbursement Amount</th>
            </tr>
          </thead>
          <tbody>
            {goldLoan &&
              goldLoan.disbursementDetails &&
              goldLoan.disbursementDetails.map(gold => (
                <tr className="text-center">
                  <td className="font-weight-bold">{gold.month}</td>
                  <td>
                    <span>&#8377;</span>
                    {gold.glSecuredDisbursementAmount
                      ? formatIndianCurrency(
                          gold.glSecuredDisbursementAmount
                            .toString()
                            .split(".")
                            .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                            .join(".")
                        )
                      : 0}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="row h5 justify-content-center">Quarter Till Date Disbursement</div>
        <Table
          striped
          rounded
          bordered
          hover
          responsive
          size="sm"
          style={{
            backgroundColor: "#fff",
            borderRadius: "12px",
            overflow: "hidden",
            border: "2px solid #dee2e6",
            "margin-bottom": "40px",
          }}
        >
          <thead>
            <tr className="text-center bg_d-primary text-white">
              <th>QTD Disbursement</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td>
                <span>&#8377;</span>
                {disbursements.qtdDisbursement
                  ? formatIndianCurrency(
                      disbursements.qtdDisbursement
                        .toString()
                        .split(".")
                        .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                        .join(".")
                    )
                  : 0}
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="row h5 justify-content-center">Collection Detail </div>
        <Table
          striped
          rounded
          bordered
          hover
          responsive
          size="sm"
          style={{
            backgroundColor: "#fff",
            borderRadius: "12px",
            overflow: "hidden",
            border: "2px solid #dee2e6",
            "margin-bottom": "40px",
          }}
        >
          <thead>
            <tr className="text-center bg_d-primary text-white">
              <th>Collection Details</th>
              <th>Percentage</th>
            </tr>
          </thead>

          <tbody>
            <tr className="text-center">
              <td className="font-weight-bold">Collection Efficiency Details</td>
              <td>
                {dpdData.collectionEfficiancyPercentage == null
                  ? "0"
                  : parseFloat(dpdData.collectionEfficiancyPercentage).toFixed(2)}
              </td>
            </tr>

            <tr className="text-center">
              <td className="font-weight-bold">On Time Collection Percentage</td>
              <td>
                {dpdData.onTimeCollectedAmount == null ? "0" : parseFloat(dpdData.onTimeCollectedAmount).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="row h5 justify-content-center">DPD Collection Details</div>
        <Table
          striped
          rounded
          bordered
          hover
          responsive
          size="sm"
          style={{
            backgroundColor: "#fff",
            borderRadius: "12px",
            overflow: "hidden",
            border: "2px solid #dee2e6",
            "margin-bottom": "40px",
          }}
        >
          <thead>
            <tr className="text-center bg_d-primary text-white">
              <th>DPD Duration</th>
              <th>Demand</th>
              <th>Collection Percentage</th>
            </tr>
          </thead>
          {dpdData &&
            dpdData.dpdGroups &&
            dpdData.dpdGroups.map(dpd => (
              <tbody>
                <tr className="text-center">
                  <td className="font-weight-bold">{dpd.dpdBucketGroup}</td>
                  <td>
                    <span>&#8377;</span>
                    {dpd.totalDue
                      ? formatIndianCurrency(
                          dpd.totalDue
                            .toString()
                            .split(".")
                            .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
                            .join(".")
                        )
                      : 0}
                  </td>
                  <td>{dpd.collectionEfficiency == null ? "0" : parseFloat(dpd.collectionEfficiency).toFixed(2)}</td>
                </tr>
              </tbody>
            ))}
        </Table>
      </div>
      {/* ))} */}
    </div>
  );
};

export default DisbursementBRO;
