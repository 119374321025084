import React from "react";
import { loginUrl } from "../Utility/Constant";

export const Landing = props => {
  console.log("this.props", props);
  let leadData = {};
  var Parameter = props.location.hash.split("/");
  leadData.leadAccessToken = Parameter[2];
  leadData.leadEmployeeId = Parameter[3];
  leadData.leadId = Parameter[4];
  leadData.accessIdlead = Parameter[5];
  leadData.employeeName = "ABC";
  console.log("leadData", leadData);
  const role = Parameter[6];
  if (localStorage.getItem("leadData")) {
    // console.log("inside,,,")
    props.history.push("/Leadmodule");
  } else if (
    leadData.leadAccessToken != "null" &&
    leadData.leadAccessToken != undefined &&
    leadData.leadId != "null" &&
    leadData.leadId != undefined &&
    leadData.leadEmployeeId != null &&
    leadData.leadEmployeeId != undefined
  ) {
    localStorage.setItem("leadData", JSON.stringify(leadData));
    localStorage.setItem("Role", role);
    props.history.push("/Leadmodule");
  } else {
    // console.log("inside,,,else")
    localStorage.removeItem("leadData");
    //window.location.href = `${window.location.protocol}//${window.location.hostname}:5000/log-in`;
    window.location.href = `${loginUrl}`;
  }
  return (
    <section className="w-100 px-2 px-md-5 pt-4 pb-5 dashboard_div bg_l-secondary">
      <div className="col-sm-12 text-center">
        <div className="loader1">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      {/* <div className="col-lg-12" style={{ height: 250 }}>
            <h3 className="font-weight-bold text-primary ml-3 mb-3 text-center">
                <i className="fa fa-spinner fa-spin mr-2" />
            Loading...
          </h3>
        </div> */}
    </section>
  );
};

export default Landing;
