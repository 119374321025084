import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./Incentive.css";
import ReactToPdf from "react-to-pdf";
import LOGOVertical from "../../../src/Assets/LOGOVertical.png";

function IncentiveBM() {
  const data = {
    nameOfEmployee: "Kunal Naskar",
    designationOfEmployee: "BM",
    employeeId: "220001",
    dateOfIncentiveSlip: "26/04/2022",
    monthOfIncentive: "April",
  };

  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [4, 2],
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Incentive Slip";
    // const headers = [["NAME", "PROFESSION"]];

    // const value = data.incentive.map(elt => [
    //   elt.nameOfEmployee,
    //   elt.designationOfEmployee,
    //   elt.employeeId,
    //   elt.dateOfIncentiveSlip,
    // ]);

    // let content = {
    //   startY: 50,
    //   // head: headers,
    //   body: value,
    // };

    doc.text(title, marginLeft, 30);
    doc.autoTable({ html: "#pdf-format", theme: "grid" });
    doc.save("Incentive.pdf");
  };

  return (
    // <div>
    //   <div>Incentive</div>
    //   <tbody>
    //     {data.incentive.map((item, i) => (
    //       <tr key={i}>
    //         <tr>
    //           <td>Name Of Employee: {item.nameOfEmployee}</td>
    //         </tr>
    //         <tr>
    //           <td>Designation Of Employee: {item.designationOfEmployee}</td>
    //         </tr>
    //         <tr>
    //           <td>Employee ID: {item.employeeId}</td>
    //         </tr>
    //         <tr>
    //           <td>Date Of Incentive Slip{item.dateOfIncentiveSlip}</td>
    //         </tr>
    //       </tr>
    //     ))}
    //   </tbody>

    //   <button onClick={exportPDF}>Download Incentive Slip</button>
    // </div>

    <div>
      <div className="App" ref={ref}>
        <table id="pdf-format">
          <tr>
            <th rowspan={4}>
              <img src={LOGOVertical} style={{ height: "153px", width: "165px" }} />
            </th>
            <th>Name Of the Employee</th>
            <td>{data.nameOfEmployee}</td>
          </tr>
          <tr>
            <th>Designation Of the Employee</th>
            <td>{data.designationOfEmployee}</td>
          </tr>
          <tr>
            <th>Employee ID</th>
            <td>{data.employeeId}</td>
          </tr>
          <tr>
            <th>Date of Incentive Slip</th>
            <td>{data.dateOfIncentiveSlip}</td>
          </tr>
          <tr>
            <td colSpan={3}>&nbsp;</td>
          </tr>
          <tr>
            <th>Incentive</th>
            <th>Value</th>
            <th>Amount (in Rs)</th>
          </tr>
          <tr>
            <td>Consumer Durables - Mobiles</td>
            <td>Actual_Disb_CD_Mobiles</td>
            <td>Incentive_Amount</td>
          </tr>
          <tr>
            <td>Consumer Durables - Non-Mobiles</td>
            <td>Actual_Disb_CD_Non-Mobiles</td>
            <td>Incentive_Amount</td>
          </tr>
          <tr>
            <td>Unsecured loans</td>
            <td>Actual_Disb_Unsecured</td>
            <td>Incentive_Amount</td>
          </tr>
          <tr>
            <td>Secured loans</td>
            <td>Actual_Disb_Secured</td>
            <td>Incentive_Amount</td>
          </tr>
          <tr>
            <td>Jewel Loans</td>
            <td>Actual_Disb_Jewel</td>
            <td>Incentive_Amount</td>
          </tr>
          <tr>
            <td>Total Disbursement Incentive</td>
            <td> </td>
            <td>Incentive_Amount</td>
          </tr>
          <tr>
            <td colSpan={3}>&nbsp;</td>
          </tr>
          <tr>
            <th>Quarterly Bonus</th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <td>Months_of_quarter</td>
            <td>Actual_Disb_for_Quarter</td>
            <td>Incentive_Amount</td>
          </tr>
          <tr>
            <td colSpan={3}>&nbsp;</td>
          </tr>
          <tr>
            <th>Special/ Bonus Incentive</th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <td>Disbursal Bonus</td>
            <td>Actual_total_Disb</td>
            <td>Incentive Amount</td>
          </tr>
          <tr>
            <td>Special JL Incentive</td>
            <td></td>
            <td>Incentive Amount</td>
          </tr>
          <tr>
            <td>CD Activation Incentive</td>
            <td></td>
            <td>Incentive Amount</td>
          </tr>
          <tr>
            <td>Exceptional Approval</td>
            <td></td>
            <td>Incentive Amount</td>
          </tr>
          <tr>
            <td>Total Special/ Bonus Incentive</td>
            <td></td>
            <td>Incentive Amount</td>
          </tr>
          <tr>
            <td colSpan={3}>&nbsp;</td>
          </tr>
          <tr>
            <th>Collection</th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <td>Collection Incentive</td>
            <td>EMI_Collected</td>
            <td>Incentive_Amount</td>
          </tr>
          <tr>
            <td>Additional Incentive for DPD</td>
            <td></td>
            <td>Incentive_Amount</td>
          </tr>
          <tr>
            <td>On time collection Incentive</td>
            <td>on time collection %</td>
            <td>Incentive_Amount</td>
          </tr>
          <tr>
            <td>Total Collection Incentive</td>
            <td></td>
            <td>Incentive_Amount</td>
          </tr>
          <tr>
            <th>Total</th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th>Disbursement + Qty Bonus + Special/Bonus Incentive + Collection</th>
            <th></th>
            <th>Incentive_Amount</th>
          </tr>
        </table>
      </div>
      <div>
        <ReactToPdf targetRef={ref} filename={data.employeeId + "," + data.monthOfIncentive + ".pdf"}>
          {({ toPdf }) => (
            <button onClick={toPdf} className="btn btn-primary incentive-btn">
              Generate pdf
            </button>
          )}
        </ReactToPdf>
      </div>
    </div>
  );
}

export default IncentiveBM;
