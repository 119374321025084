import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Routes from "./Routes/Route";
import { Header, Footer } from "./Component/Pages";
// import { postGetUserDetail } from "./Utility/Services/Login";
import { syncLogin } from "./Redux/Action/Login";
import { connect } from "react-redux";
import { public_url } from "./Utility/Constant";
import { Redirect } from "react-router-dom";

class App extends React.Component {
  componentDidMount() {
    // this.GetUserDetail();
  }
  /* GetUserDetail() {
    let moduleName = ""
    if (localStorage.getItem("moduleId") && localStorage.getItem("moduleId") == 1) {
      moduleName = "Credit module"
    }
    if (localStorage.getItem("moduleId") && localStorage.getItem("moduleId") == 2) {
      moduleName = "Disbursment module"
    }
    if (localStorage.getItem("moduleId") && localStorage.getItem("moduleId") == 3) {
      moduleName = "Deviation module"
    }
    if (localStorage.getItem("employeeId")) {
      postGetUserDetail(localStorage.getItem("employeeId"), moduleName).then(res => {
        if (res.error) return;
        if (res.data && res.data.data && res.data.data.user) {
          this.props.syncLogin({ data: res.data.data.user });
        }
      });
    } else {
      if (
        window.location.pathname != public_url.login
      ) {

      }

    }
  } */
  render() {
    return (
      <React.Fragment>
        <div id="wrapper">
          <BrowserRouter>
            <Header />
            <Switch>
              <Routes />
            </Switch>
          </BrowserRouter>
          {/* <Footer /> */}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(state => state, {})(App);
