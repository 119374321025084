export const public_url = {
  login: "/log-in",
  landing_page: "/",
  lead: "/lead",
  Leadmodule: "/Leadmodule",
  Cdproduct: "/Cdproduct-list",
  Cdcatalogue: "/Cdcatalogue",
  BulkUpload: "/BulkUpload",
  Exception: "/Exception",
  add_lead: "/add-lead",
  approve_lead: "/approve-lead",
  update_lead: "/update-lead",
  update_lead_cc: "/update-lead-cc",
  farm_list: "/farm-list",
  catalogue_card: "/catalogue-card",
  dashboard: "/dashboard",
  chatbotReport: "/chatbot-report",
};

const hostname = window.location.hostname;
let tempLoginUrl;

if (hostname === "lead.sarvagram.com") tempLoginUrl = "https://auth.sarvagram.com";
else if (hostname === "uat-lead.sarvagram.net") tempLoginUrl = "https://uat-login.sarvagram.net";
else tempLoginUrl = "https://dev-login.sarvagram.net";

export const loginUrl = tempLoginUrl;
//Get the indian currency format

export const formatIndianCurrency = val => {
  console.log("val------", val);
  let isNegative = val != "" && val != undefined && val != null ? val.toString().includes("-") : false;
  if (isNegative) {
    val = val.replace(/-/gi, "");
  }
  let temp = val;
  temp = temp.toString();
  let afterPoint = "";
  if (temp.indexOf(".") > 0) afterPoint = temp.substring(temp.indexOf("."), temp.length);
  if (temp !== "-") {
    temp = isNaN(parseInt(temp)) ? "" : parseInt(temp);
  }
  temp = temp.toString();
  let lastThree = temp.substring(temp.length - 3);
  let otherNumbers = temp.substring(0, temp.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
  if (isNegative) {
    res = "-" + res;
  }
  return res;
};

// Replace the comma from input
export const replaceComma = value => {
  return value.replace(/,/g, "");
};

export const checkIfNotDecimal = value => {
  if (value.indexOf(".") === -1) {
    return true;
  } else {
    return false;
  }
};
