import React, { Component } from "react";
import moment from "moment";
import BreadCrumbs from "../../Component/BreadCrumbs/index";
import { public_url } from "../../Utility/Constant";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Select, Input } from "../../Component/Input";
import { getFarmImplementCatalogue } from "../../Utility/Services/Leadmanage";
import {
  findallunitsdropdown,
  selectKycDocumentDropdown,
  uploadKycDocument,
  deleteKycDocumentById,
  getratefromservicemaster,
  saveOrUpdateOrderDetails,
} from "../../Utility/Services/Leadmanage";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { getAccessId } from "../../Utility/Helper";

const initForm = {
  estimated: "",
  fromDate: "",
  toDate: "",
  fromTime: "",
  toTime: "",
  duration: "",
  units: "",
  rate: "",
  selectKycDoc: "",

  documentId: "",
  errors: {
    estimated: null,
    fromDate: null,
    toDate: null,
    fromTime: null,
    toTime: null,
    duration: null,
    units: null,
    rate: null,
    selectKycDoc: null,
    file: null,
    documentId: null,
  },
};

export class PlaceOrder extends Component {
  constructor() {
    super();
    this.state = {
      UnitDropDown: [],
      KycDocumentDropdown: [],
      form: cloneDeep(initForm),
      kycDocFile: "",
      orderDetails: null,
      loading: false,
    };
    this.handleClickKYCDocUpload = this.handleClickKYCDocUpload.bind(this);
  }

  componentDidMount() {
    this.FarmImplementCatalogue();
    // prefetch form values
    this.getUnit();
    this.getKYCDocList();
    let { form } = this.state;
    this.setState({ form });
  }

  onInputChange = (name, value, error) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form });
    console.log(this.state.form);
    name === "units" && this.getRate(); // call when units gets changed
  };

  // handleUnitChange = (name, value, error) => {
  //     let { form } = this.state;
  //     form[name] = value;
  //     this.setState({ form });
  //     console.log(this.state.form);
  //     name === 'units' && this.getRate(); // call when units gets changed
  // };
  getRate = () => {
    let { form, orderDetails } = this.state;
    let objBody = {
      serviceName: orderDetails.service,
      implementRequired: orderDetails.implement,
      units: form.units,
    };
    getratefromservicemaster(objBody).then((res) => {
      if (res.error) {
        form.rate = 0;
        this.setState({ form });
        return;
      }
      if (res.data.error) {
        // toast.error(res.data.message, {
        //     type: toast.TYPE.ERROR,
        //     autoClose: 2000,
        // });
        form.rate = 0;
        this.setState({ form });
        return;
      } else {
        console.log("res.data", res.data);
        form.rate = res && res.data && res.data.data && res.data.data.rate;
        this.setState({ form });
      }
    });
  };
  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    form[name] = e.target.value;

    let rate = form && form.rate;
    let estimated = form && form.estimated;

    form.invoiceValue = rate * estimated;
    Math.round(form.invoiceValue);

    if (form.units === "Hour") {
      let rate = form && form.rate;
      let Hour =
        form &&
        form.duration
          .toString()
          .split(".")
          .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
          .join(".");

      form.invoiceValue = rate * Hour;
      Math.round(form.invoiceValue);
    }

    this.setState({ form });
  };
  onInputChange = (name, value, error) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form });
  };
  onCancel = () => {
    let { match } = this.props;
    this.props.history.push(
      `${public_url.farm_list}/${match.params.leadId}/${match.params.productSelection}/${match.params.farmService}`
    );
  };
  onFormChange = (name, value, error = undefined) => {
    let { form } = this.state;

    form[name] = value;

    let date1 = moment(form && form.fromTime, "hh:mm");
    let date2 = moment(form && form.toTime, "hh:mm");
    // to calculate right duration difference        form.duration = moment.duration(date2.diff(date1));
    let now = `${form?.fromDate}` + " " + `${form?.fromTime}`;
    let then = `${form?.toDate}` + " " + `${form?.toTime}`;
    var ms = moment(then, "YYYY-MM-DD HH:mm").diff(
      moment(now, "YYYY-MM-DD HH:mm")
    );
    var d = moment.duration(ms);
    form.duration = Math.floor(d.asHours()) + moment.utc(ms).format(".mm");

    // function msToTime(duration) {
    //   var milliseconds = parseInt((duration % 1000) / 100),
    //     seconds = Math.floor((duration / 1000) % 60),
    //     minutes = Math.floor((duration / (1000 * 60)) % 60),
    //     hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    //   hours = hours < 10 ? "0" + hours : hours;
    //   minutes = minutes < 10 ? "0" + minutes : minutes;
    //   seconds = seconds < 10 ? "0" + seconds : seconds;

    //   return hours + "." + minutes;
    // }
    // console.log(msToTime(moment(date2.diff(date1))))
    // form.duration = msToTime(moment(date2.diff(date1))).toString();
    // form.duration =
    //     form &&
    //     form.duration
    //         .asHours()
    //         .toString()
    //         .split(".")
    //         .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
    //         .join(".");

    if (form.fromTime === form.toTime && form.fromDate == form.toDate) {
      form.duration = "0";
    }

    this.setState({ form });
  };

  handleDeleteKycDoc = () => {
    let { kycDocFile } = this.state;

    deleteKycDocumentById({ id: kycDocFile.id }).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        kycDocFile = null;
        this.setState({ kycDocFile });
      }
    });
  };
  handleClickKYCDocUpload(e) {
    this.refs.kycDocUploaderRef.click();
    this.refs.kycDocUploaderRef.onchange = (e) => {
      let { kycDocFile, form } = this.state;
      // console.log(e.target.files[0].type.indexOf('pdf') === -1 && e.target.files[0].type.indexOf('image') === -1)
      if (
        e.target.files[0]?.type?.indexOf("pdf") === -1 &&
        e.target.files[0]?.type?.indexOf("image") === -1
      ) {
        this.setState({ kycDocFile: null });
        toast.error("This file type is not supported", {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else if (e?.target?.files[0]?.size > 10485760) {
        // check file should be less than 10 mb
        this.setState({ kycDocFile: null });
        toast.error("This file size should be less that 10MB", {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        return;
      } else {
        kycDocFile = e.target.files[0];

        console.log("a", e.target.files[0]);
        const filename = e.target.files[0].name;
        const content = e.target.files[0];
        let fileType = `${filename}`;
        console.log("c", content, filename, fileType);

        fileType = fileType.split(".").pop();
        console.log("abc", fileType);
        // let { orderId, autoUpdate } = this.props;

        let _this = this;
        let blob = new Blob([content], {
          type: fileType === "pdf" ? "PDF" : "IMAGE",
        });

        let formData = new FormData();
        formData.append("file", blob, filename);
        formData.append(
          "kycDocs",
          JSON.stringify([
            {
              type: "KYC_DOC",
              subType: fileType === "pdf" ? "PDF" : "IMAGE",
              documentPath: filename,
              leadId: this.props.match.params.leadId,
            },
          ])
        );
        console.log("formData--->", formData);
        uploadKycDocument(formData).then((res) => {
          console.log("resssssss", res);
          if (res && res.error) {
            return;
          }
          if (res.data.error) {
            // toast.error(res.data.message, {
            //     type: toast.TYPE.ERROR,
            //     autoClose: 4000,
            // });
            e.target.value = null;
            return;
          } else {
            console.log("res.data", res.data);
            _this.setState({
              kycDocFile: res.data.data[0],
            });
            // e.target.value = null;
            // toast.success(res.data.message, {
            //     type: toast.TYPE.SUCCESS,
            //     autoClose: 4000,
            // });
          }
        });
      }
    };
  }

  onSubmitForm = () => {
    // console.log('[onSubmit] ', this.state);
    const { form, kycDocFile, orderDetails, loading } = this.state;
    let { match } = this.props;
    const leadData = JSON.parse(localStorage.getItem("leadData")); // Get the lead details from local storage
    // to check both kyc type & file should be filled
    if (kycDocFile && !form.selectKycDoc) {
      toast.error("Please select KYC Doc", {
        type: toast.TYPE.ERROR,
        autoClose: 4000,
      });
      return;
    }
    if (!kycDocFile && form.selectKycDoc) {
      toast.error("Please upload KYC Doc", {
        type: toast.TYPE.ERROR,
        autoClose: 4000,
      });
      return;
    }

    let formData = {
      fromDate: form.fromDate,
      toDate: form.toDate,
      fromTime: `${form.fromTime}:00`,
      toTime: `${form.toTime}:00`,
      duration: +form.duration,
      estimatedArea: +form.estimated,
      units: form.units,
      rate: form.rate,
      selectKycDoc: form.selectKycDoc,
      kycDocumentUploadPath: kycDocFile?.documentPath,
      documentId: form.documentId,
      leadId: this.props.match.params.leadId,
      employeeId: leadData.leadEmployeeId,
      serialNumber: orderDetails.serialNumber,
      franchisee: match.params.franchisee,
      serviceName: match.params.farmService,
    };
    console.log(formData);
    this.setState({ loading: true });
    saveOrUpdateOrderDetails(formData).then((res) => {
      if (res && res.error) {
        return;
      } else if (res && res.data && res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        return;
      } else {
        toast.success(res.data.message, {
          type: toast.TYPE.SUCCESS,
          autoClose: 4000,
        });
        this.setState({ loading: false });
        this.props.history.push(`${public_url.Leadmodule}`);
      }
    });
  };
  // Get dropdown list
  getKYCDocList = () => {
    selectKycDocumentDropdown().then((res) => {
      console.log("res", res);
      if (res.error) {
        return;
      } else if (res.data && res.data.error) {
        // toast.error(res.data.message, {
        //     type: toast.TYPE.ERROR,
        //     autoClose: 2000,
        // });
        return;
      } else {
        if (res && res.data && res.data.data) {
          this.setState({
            KycDocumentDropdown: res && res.data && res.data.data,
          });
        }
      }
    });
  };
  getUnit = () => {
    findallunitsdropdown().then((res) => {
      console.log("res", res);
      if (res.error) {
        return;
      } else if (res.data && res.data.error) {
        // toast.error(res.data.message, {
        //     type: toast.TYPE.ERROR,
        //     autoClose: 2000,
        // });
        return;
      } else {
        if (res && res.data && res.data.data) {
          this.setState({ UnitDropDown: res && res.data && res.data.data });
        }
      }
    });
  };

  FarmImplementCatalogue = () => {
    let { match } = this.props;
    let { form } = this.state;
    let obj = {
      serialNumber: match.params.serialNumber,
      franchiseeName: match.params.franchisee,
      serviceName: match.params.farmService,
    };

    getFarmImplementCatalogue(obj).then((res) => {
      console.log("FarmImplementCatalogue", res);
      if (res.error) {
        return;
      }
      if (res.data.error) {
        toast.error(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 4000,
        });
      } else {
        // toast.success(res.data.message, {
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 4000,
        // });
        let { rate, unit } =
          res.data && res.data.data && res.data.data.orderDetails;
        form.rate = rate;
        form.units = unit;
        this.setState({
          form,
          orderDetails: res.data && res.data.data && res.data.data.orderDetails,
        });
      }
    });
  };

  render() {
    const { form, kycDocFile, loading } = this.state;
    let msg = ""; //For display the error message
    let date1 = new Date(form.fromDate);
    let date2 = new Date(form.toDate);
    let disablesave = false;

    if (date1.getTime() > date2.getTime()) {
      msg = "Please enter valid date";
    }

    let msg1 = ""; //For display the error message
    let now = moment(form && form.fromTime, "hh:mm");
    let d = moment(form && form.toTime, "hh:mm");
    if (now >= d && form?.fromDate == form?.toDate) {
      msg1 = "Please enter valid time";
      form.duration = "";
    }
    if(now >= d && form?.fromDate == form?.toDate) {
      disablesave = true;
    } else {
      disablesave = false;
    } 

    let isRequiredFeildFilled = false;

    Object.keys({
      estimated: "",
      fromDate: "",
      toDate: "",
      fromTime: "",
      toTime: "",
      duration: "",
      units: "",
      rate: "",
    }).map((name) => {
      console.log("form", form[name]);
      if (
        form[name] === "" ||
        form[name] === null ||
        form[name] === undefined
      ) {
        isRequiredFeildFilled = true;
        return true;
      }
    });

    if (msg === "" || msg1 === "") {
      disablesave = false;
    } else {
      disablesave = true;
    }

    if (form.duration == "NaNInvalid date") {
      form.duration = "";
    }

    return (
      <React.Fragment>
        <BreadCrumbs
          name1={"Lead List"}
          name2={"Add Order"}
          url1={`${public_url.Leadmodule}`}
          url2={``}
        />
        <section className="px-2 px-md-5 pt-4 pb-5 dashboard_div bg_l-secondary">
          <div className="d-flex justify-content-start align-items-center">
            <section className="py-4 position-relative bg_l-secondary w-100">
              <div className=" bg-white disbursment">
                <div className="row">
                  {/* 1st */}
                  <div className="col-12">
                    <div className="row p-5">
                      <div className="col-12 pl-4 pr-4 text-center">
                        <h5
                          className="divider gradient pt-1"
                          style={{
                            backgroundColor: "#f89839",
                            color: "black",
                            height: 40,
                          }}
                        >
                          Add Order
                        </h5>
                      </div>
                      <section
                        classsName="container"
                        style={{ width: "100%", margin: "1.5rem" }}
                      >
                        <Row className="mt-2">
                          <Col sm={2}>
                            <label class="fs-14 mb-0 gTextPrimary fw-500">
                              From Date{<i className="text-danger">*</i>}
                            </label>
                          </Col>
                          <Col sm={4}>
                            <Input
                              className="form-control  fs-16 date-icon"
                              placeholder="From Date"
                              name="fromDate"
                              value={moment(form && form.fromDate).format(
                                "YYYY-MM-DD"
                              )}
                              onChangeFunc={(name, value, error) => {
                                this.onFormChange(name, value, error);
                              }}
                              type="date"
                              min={moment(new Date()).subtract(6, 'M').format("YYYY-MM-DD")}
                              //min="1900-01-01"
                              max="2999-12-31"
                              // maxLength={16}
                              // disabled={}
                              isReq={true}
                              // reqType="number"
                              title="fromDate"
                            />
                          </Col>
                          <Col sm={2}>
                            <label class="fs-14 mb-0 gTextPrimary fw-500">
                              To Date{<i className="text-danger">*</i>}
                            </label>
                          </Col>
                          <Col sm={4}>
                            <Input
                              className="form-control  fs-16 date-icon"
                              placeholder="To Date"
                              name="toDate"
                              value={moment(form && form.toDate).format(
                                "YYYY-MM-DD"
                              )}
                              onChangeFunc={(name, value, error) => {
                                this.onFormChange(name, value, error);
                              }}
                              type="date"
                              min={moment(form && form.fromDate).format(
                                "YYYY-MM-DD"
                              )}
                              max = {moment(form.fromDate).add(1,'Days').format("YYYY-MM-DD")}
                              //max="2999-12-31"
                              // maxLength={16}
                              // disabled={}
                              isReq={true}
                              // reqType="number"
                              title="toDate"
                            />
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {msg}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={2}>
                            <label class="fs-14 mb-0 gTextPrimary fw-500">
                              From Time{<i className="text-danger">*</i>}
                            </label>
                          </Col>
                          <Col sm={4}>
                            <Input
                              className="form-control  fs-16"
                              placeholder="From time"
                              name="fromTime"
                              value={form && form.fromTime}
                              onChangeFunc={(name, value, error) => {
                                this.onFormChange(name, value, error);
                              }}
                              type="time"
                              // min={moment(new Date()).format("YYYY-MM-DD")}
                              // maxLength={16}
                              // disabled={}
                              isReq={true}
                              // reqType="number"
                              title="fromTime"
                            />
                          </Col>
                          <Col sm={2}>
                            <label class="fs-14 mb-0 gTextPrimary fw-500">
                              To Time{<i className="text-danger">*</i>}
                            </label>
                          </Col>
                          <Col sm={4}>
                            <Input
                              className="form-control  fs-16"
                              placeholder="To time"
                              name="toTime"
                              value={form && form.toTime}
                              onChangeFunc={(name, value, error) => {
                                this.onFormChange(name, value, error);
                              }}
                              type="time"
                              // min={moment(new Date()).format("YYYY-MM-DD")}
                              // maxLength={16}
                              // disabled={}
                              isReq={true}
                              // reqType="number"
                              title="toTime"
                            />
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {msg1}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={2}>
                            <label class="fs-14 mb-0 gTextPrimary fw-500">
                              Duration{<i className="text-danger">*</i>}
                            </label>
                          </Col>
                          <Col sm={4}>
                            <Input
                              className="form-control  fs-16"
                              type="text"
                              onChangeFunc={(name, value, error) => {
                                this.onInputChange(name, value, error);
                              }}
                              value={
                                form && form.duration
                                  ? `${form.duration
                                    ? form.duration
                                      .toString()
                                      .split(".")
                                      .map((el, i) =>
                                        i
                                          ? el
                                            .split("")
                                            .slice(0, 2)
                                            .join("")
                                          : el
                                      )
                                      .join(".")
                                    : ""
                                  } Hours`
                                  : ""
                              }
                              name="duration"
                              placeholder="Duration"
                              disabled={true}
                            />
                          </Col>
                          <Col sm={2}>
                            <label class="fs-14 mb-0 gTextPrimary fw-500">
                              Estimated Area/Trolly/Quintal
                              {<i className="text-danger">*</i>}
                            </label>
                          </Col>
                          <Col sm={4}>
                            <input
                              className="form-control  fs-16 checkDot"
                              type="text"
                              // pattern="\d*"
                              maxLength="9"
                              name="estimated"
                              value={form && form.estimated}
                              onChange={(e) => {
                                if (
                                  e.target.value == "" ||
                                  (Math.ceil(e.target.value) <= 100000 &&
                                    parseInt(e.target.value) >= 0)
                                ) {
                                  e.target.value = e.target.value
                                    .toString()
                                    .split(".")
                                    .map((el, i) =>
                                      i ? el.split("").slice(0, 2).join("") : el
                                    )
                                    .join(".");
                                  this.handleInputChange(e, "estimated");
                                }
                              }}
                              placeholder="Estimated Area"
                              disabled={false}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={2}>
                            <label class="fs-14 mb-0 gTextPrimary fw-500">
                              Unit{<i className="text-danger">*</i>}
                            </label>
                          </Col>
                          <Col sm={4}>
                            <Select
                              className="w-100 fs-12 create-lead-form-select"
                              options={this.state.UnitDropDown}
                              value={form.units}
                              title="Select Unit"
                              name="units"
                              onChangeFunc={(name, value, error) => {
                                this.onInputChange(name, value, error);
                              }}
                              isClearable={true}
                              //  error={selectedSpokeError}
                              isReq={true}
                              valueKey="unitName"
                              labelKey="unitName"
                              placeholder="Select Unit"
                              disabled={true}
                            />
                            {/* <Select
                                                            className="w-100 fs-12 create-lead-form-select"
                                                            options={this.state.UnitDropDown}
                                                            value={form.units}
                                                            title="Select Unit"
                                                            name="units"
                                                            onChangeFunc={(name, value, error) => {
                                                                this.handleUnitChange(name, value, error);
                                                            }}
                                                            isClearable={false}
                                                            onBlur={() => { }}
                                                            isReq={true}
                                                            valueKey="unitName"
                                                            labelKey="unitName"
                                                            getOptionLabel={(option) => option.unitName}
                                                            getOptionValue={(option) => option.unitName}
                                                            placeholder="Select Unit"
                                                        /> */}
                          </Col>

                          <Col sm={2}>
                            <label class="fs-14 mb-0 gTextPrimary fw-500">
                              Rate{<i className="text-danger">*</i>}
                            </label>
                          </Col>
                          <Col sm={4}>
                            <Input
                              className="form-control  fs-16"
                              type="text"
                              value={form.rate}
                              placeholder="Rate"
                              disabled={true}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={4}>
                            <Select
                              className="w-100 fs-12 create-lead-form-select"
                              options={this.state.KycDocumentDropdown}
                              value={form.selectKycDoc}
                              title="Select KYC doc"
                              name="selectKycDoc"
                              onChangeFunc={(name, value, error) => {
                                this.onInputChange(name, value, error);
                              }}
                              isClearable={true}
                              //  error={selectedSpokeError}
                              isReq={true}
                              valueKey="docName"
                              labelKey="docName"
                              placeholder="Select KYC doc"
                            />
                            {/* <Select
                                                            className="w-100 fs-12 create-lead-form-select"
                                                            options={this.state.KycDocumentDropdown}
                                                            value={form.selectKycDoc}
                                                            title="Select KYC doc"
                                                            name="selectKycDoc"
                                                            onChangeFunc={(name, value, error) => {
                                                                this.handleUnitChange(name, value, error);
                                                            }}
                                                            isClearable={false}
                                                            onBlur={() => { }}
                                                            isReq={true}
                                                            valueKey="docName"
                                                            labelKey="docName"
                                                            getOptionLabel={(option) => option.docName}
                                                            getOptionValue={(option) => option.docName}
                                                            placeholder="Select KYC doc"
                                                        /> */}
                          </Col>
                          <Col sm={2}>
                            <Button
                              variant="primary"
                              className="mt-1"
                              name="kycDocFile"
                              onClick={this.handleClickKYCDocUpload}
                              disabled={
                                this.state.kycDocFile || getAccessId() === 1
                              }
                            >
                              Upload
                            </Button>
                            <input
                              type="file"
                              name="kycDocFile"
                              id="file"
                              ref="kycDocUploaderRef"
                              accept=".pdf"
                              style={{ display: "none" }}
                            />
                            {this.state.kycDocFile ? (
                              <span>
                                {this.state.kycDocFile.fileName}
                                &nbsp;
                                <i
                                  style={{ cursor: "pointer" }}
                                  className="fa fa-times ml-2"
                                  onClick={this.handleDeleteKycDoc}
                                ></i>
                              </span>
                            ) : null}
                          </Col>
                          <Col sm={2}>
                            <label class="fs-14 mb-0 gTextPrimary fw-500">
                              Document ID
                            </label>
                          </Col>
                          <Col sm={4}>
                            <Input
                              className="form-control  fs-16"
                              type="text"
                              maxLength={20}
                              onChangeFunc={(name, value, error) => {
                                this.onInputChange(name, value, error);
                              }}
                              value={form && form.documentId}
                              name="documentId"
                              placeholder="Document ID"
                              disabled={false}
                            />
                          </Col>
                        </Row>

                        <div className="row justify-content-end col-md-12 col-sm-12 mt-4 col-lg-12">
                          <div className="row">
                            <button
                              className={`btn btn-secondary btn-rounded ls-1 cursor-pointer mr-3  fs-16 btn-green`}
                              onClick={this.onCancel}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={
                                isRequiredFeildFilled ||
                                disablesave ||
                                loading ||
                                getAccessId() === 1
                              }
                              className={`btn btn-secondary btn-rounded ls-1 cursor-pointer mr-3  fs-16 btn-green`}
                              onClick={this.onSubmitForm}
                            >
                              {loading ? (
                                <React.Fragment>
                                  <i class="fa fa-spinner fa-spin"></i>
                                  Saving...
                                </React.Fragment>
                              ) : (
                                "Save"
                              )}
                            </button>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        <div></div>
      </React.Fragment>
    );
  }
}

export default PlaceOrder;
