import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { public_url } from "../../Utility/Constant";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import axios from "axios";

const API_URL = "https://cos.sarvagram.com/engati/v1.0/chats/download";

const ChatBotReport = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState("");

  const handleDateChange = (e, name) => {
    setError("");
    const date = moment(e.target.value).format("DD-MM-YYYY");
    if (name === "start") {
      setStartDate(date);
    } else if (name === "end") {
      setEndDate(date);
    }
  };

  const validateDates = () => {
    if (!startDate) {
      setError("Please provide a start date!");
      return true;
    }
    if (!endDate) {
      setError("Please provide a end date!");
      return true;
    }

    let start = startDate.split("-");
    let end = endDate.split("-");

    end = moment([end[2], end[1], end[0]]);
    start = moment([start[2], start[1], start[0]]);

    let totalDays = end.diff(start, "days");

    if (totalDays < 0) {
      setError("End date must be after start date!");
      return true;
    }

    if (totalDays > 30) {
      setError("Report can only be downloaded for max 30 days!");
      return true;
    }

    return false;
  };

  const handleSubmit = () => {
    const isError = validateDates();
    if (isError) return;

    axios
      .get(`${API_URL}/?startDate=${startDate}&endDate=${endDate}`, {
        responseType: "blob",
        headers: {
          Accept: "application/octet-stream",
        },
      })
      .then(res => {
        const { data } = res;
        const fileName = `chatbot report ${startDate} to ${endDate}.xlsx`;
        const blob = new Blob([data], { type: "application/octet-stream" });
        let dom = document.createElement("a");
        let url = window.URL.createObjectURL(blob);
        dom.href = url;
        dom.download = decodeURI(fileName);
        dom.style.display = "none";
        document.body.appendChild(dom);
        dom.click();
        dom.parentNode.removeChild(dom);
        window.URL.revokeObjectURL(url);
      })
      .catch(err => console.log(err));
  };

  return (
    <div>
      <div className="backToDashboard py-2">
        <div className="d-flex justify-content-between container-fluid">
          <Link to={public_url.Leadmodule}>Home</Link>
        </div>
      </div>
      <Row className="d-flex justify-content-center p-4 h3">Download Chatbot Report</Row>
      <Row className="d-flex justify-content-center mt-4">
        <Row className="d-flex justify-content-center w-50">
          <Col className="d-flex flex-column align-items-center">
            <div className="mb-2">Start Date</div>
            <input
              type="date"
              min={"2023-05-15"}
              onKeyDown={e => e.preventDefault()}
              className="border p-2 rounded w-75"
              onChange={e => handleDateChange(e, "start")}
            />
          </Col>
          <Col className="d-flex flex-column align-items-center">
            <div className="mb-2">End Date</div>
            <input
              type="date"
              min={"2023-05-15"}
              onKeyDown={e => e.preventDefault()}
              className="border p-2 rounded w-75"
              onChange={e => handleDateChange(e, "end")}
            />
          </Col>
        </Row>
      </Row>
      <Row className="d-flex justify-content-center mt-5">
        <button className="btn btn-secondary btn-rounded ls-1 cursor-pointer fs-16 btn-green" onClick={handleSubmit}>
          Download Report
        </button>
      </Row>
      <Row className="d-flex justify-content-center mt-2 text-danger">{error}</Row>
    </div>
  );
};

export default ChatBotReport;
