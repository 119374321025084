import React, { useState } from "react";
import { bulkUploadForCos, downloadFileFromUtils } from "../../Utility/Services/Bulkupload";

import Col from "react-bootstrap/esm/Col";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/esm/Row";
import { public_url } from "../../Utility/Constant";
import { toast } from "react-toastify";

toast.configure();

export default function BulkUpload() {
  const [loading, setloading] = useState(false);
  const [showErrorExcel, setShowErrorExcel] = useState(false);
  const [errorFileUrl, setErrorFileUrl] = useState("");

  const handleClickBulkUpload = e => {
    setShowErrorExcel(false);
    setErrorFileUrl("");
    setloading(true);

    let Bulkfile = e.target.files[0].name;
    let content = e.target.files[0];
    let leadData = JSON.parse(localStorage.getItem("leadData"));
    let empId = leadData && leadData.leadEmployeeId;
    let blob = new Blob([content], { type: "xlsx" });
    const formData = new FormData();

    formData.append("file", blob, Bulkfile);
    formData.append("fileName", "Bulk_Upload");
    formData.append("uploadedBy", empId);
    bulkUploadForCos(formData)
      .then(res => {
        setloading(false);

        if (res?.data?.failRecordCount > 0) {
          toast.error(
            "Uploaded file has some errors, please refer to Error column from the below excel file to verify"
          );
          setErrorFileUrl(res?.data?.fileUrl);
          setShowErrorExcel(true);
        } else {
          toast.success("File uploaded successfully!");
        }
      })
      .catch(err => {
        setloading(false);
        toast.error("Error uploading file");
      });
  };

  const downloadErrorFile = () => {
    downloadFileFromUtils(errorFileUrl);
  };

  return (
    <div>
      <div className="backToDashboard py-2">
        <div className="d-flex justify-content-between container-fluid">
          <Link to={public_url.Leadmodule}>Home</Link>
          <Link to={public_url.Exception}>Exception</Link>
        </div>
      </div>
      <Row className="p-5 bg_l-secondary">
        <Col sm={3}></Col>
        <Col sm={6}>
          <div style={{ backgroundColor: "#fff", marginTop: "50px" }}>
            <h4 className="text-center bg_d-primary text-white p-2">Lead Bulk Upload</h4>
            <table class="table table-bordered table-striped table-sm">
              <thead className="text-primary">
                <tr className="text-center subHead">
                  <th className="subHead text-white" colSpan={1}>
                    Name
                  </th>
                  <th className="subHead text-white" colSpan={1}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                <tr>
                  <td className="p-3">Upload Bulk Excel</td>
                  <td className="p-3">
                    {loading ? (
                      <React.Fragment>
                        <i class="fa fa-spinner fa-spin"></i>
                        Uploading...
                      </React.Fragment>
                    ) : (
                      <label class="fas fa-upload" style={{ color: "#1e1f63", cursor: "pointer", width: "100%" }}>
                        <input
                          type="file"
                          accept=".xlsx,.xls"
                          onChange={handleClickBulkUpload}
                          style={{ visibility: "hidden", width: "0", height: "0" }}
                        />
                      </label>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
        <Col sm={3}></Col>
        {showErrorExcel && (
          <Row className="w-100">
            <Col sm={12} className="d-flex justify-content-center">
              <button className="btn btn-rounded ls-1 cursor-pointer fs-12 btn-primary" onClick={downloadErrorFile}>
                Download File With Error
              </button>
            </Col>
          </Row>
        )}
      </Row>
    </div>
  );
}
