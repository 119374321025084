import React from "react";
import { Route, Redirect } from "react-router-dom";
import { loginUrl, public_url } from "../Utility/Constant";
import { withRouter } from "react-router-dom";
import Login from "../Pages/Login";
import { connect } from "react-redux";
import Landing from "../Pages/Landing";
// import Leadmodule from "../Leadmodule/index";
import Cdproduct from "../Leadmodule/CustList/Cdlisting";
import CatalogueCard from "../Leadmodule/CustList/CatalogueCard";
import Leadmodule from "../Leadmodule/LMScaselist/index";
import BulkUpload from "../Leadmodule/BulkUpload/BulkUpload";
import Exception from "../Leadmodule/BulkUpload/Exception";
import Addlead from "../Leadmodule/Leadmanage/Addlead";
import Updatelead from "../Leadmodule/Leadmanage/Updatelead";
import Farmlisting from "../Leadmodule/Leadmanage/Farmlisting";
import Catalogue from "../Leadmodule/Leadmanage/Catalogue";
import PlaceOrder from "../Leadmodule/Leadmanage/PlaceOrder";
import UpdateLeadForCC from "../Leadmodule/Leadmanage/UpdateLeadForCC";
import Dashboard from "../Dashboard/Dashboard";
import Approvelead from "../Leadmodule/Leadmanage/Approvelead";
import ChatBotReport from "../Leadmodule/ChatBotReport/ChatBotReport";
class Routes extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        {/* <Route exact path={public_url.landing_page} component={Login} />
        <Route exact path={public_url.login} component={Login} /> */}
        <Route exact path={public_url.landing_page} component={Landing} />
        <Route
          exact
          path={`${public_url.lead}/:leadAccessToken/:leadEmployeeId/:leadId/:accessIdlead/:role`}
          component={Landing}
        />
        <Route exact path={"/lead-logout"} component={LeadLogout} />

        {/* Leadmodule */}
        <PrivateRoute exact path={public_url.Leadmodule} component={Leadmodule} />
        <PrivateRoute exact path={public_url.dashboard} component={Dashboard} />

        {/* <Route exact path={public_url.Cdproduct} component={Cdproduct} /> */}
        {/* <Route exact path={`${public_url.Cdcatalogue}`} component={CatalogueCard} /> */}
        <PrivateRoute exact path={public_url.BulkUpload} component={BulkUpload} />
        <PrivateRoute exact path={public_url.Exception} component={Exception} />
        {/* add_lead */}
        <PrivateRoute exact path={public_url.add_lead} component={Addlead} />
        {/* Updatelead */}
        <Route exact path={`${public_url.update_lead}/:leadId`} component={Updatelead} />
        {/* Approvelead */}
        <Route exact path={`${public_url.approve_lead}/:leadId`} component={Approvelead} />
        {/* AddleadforBM */}
        <Route exact path={`${public_url.add_lead}/:leadId`} component={Addlead} />
        <Route exact path={`${public_url.update_lead_cc}/:leadId`} component={UpdateLeadForCC} />
        {/* Farmlisting */}
        <PrivateRoute
          exact
          path={`${public_url.farm_list}/:leadId/:productSelection/:farmService`}
          component={Farmlisting}
        />
        {/* Catalogue */}
        <PrivateRoute
          exact
          path={`${public_url.catalogue_card}/:serialNumber/:leadId/:productSelection/:farmService/:franchisee`}
          component={Catalogue}
        />
        {/* Place Order */}
        <PrivateRoute
          exact
          path={`${public_url.catalogue_card}/:serialNumber/:leadId/:productSelection/:farmService/:franchisee/place-order`}
          component={PlaceOrder}
        />

        {/* CDlisting */}
        <PrivateRoute
          exact
          path={`${public_url.Cdproduct}/:leadId/:productSelection/:productType`}
          component={Cdproduct}
        />

        {/* cdcatalouge */}
        <PrivateRoute
          exact
          path={`${public_url.Cdcatalogue}/:skuCode/:leadId/:productSelection/:productType/:productId`}
          component={CatalogueCard}
        />

        <PrivateRoute exact path={`${public_url.chatbotReport}`} component={ChatBotReport} />
      </React.Fragment>
    );
  }
}
// const CreditModuleRoutes = () => {
// 	return (
// 		<>
// 			{/* <Route exact path={public_url.landing_page} component={Login} />
// 			<Route exact path={public_url.login} component={Login} /> */}
// 		</>
// 	);
// };
export default connect(state => state, {})(withRouter(Routes));

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        console.log("component", { component: Component, ...rest }, props);
        return localStorage.getItem("leadData") ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: `/lead-logout` }} />
        );
      }}
    />
  );
};

export const LeadLogout = () => {
  localStorage.clear();
  //window.location.href = `${window.location.protocol}//${window.location.hostname}:5000${public_url.login}`;
  window.location.href = `${loginUrl}`;
  return <div></div>;
};
